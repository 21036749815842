/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from "react";
import { Redirect, useHistory } from "react-router-dom";
import { getCookie } from "../helpers/cookieHelper";
import { authApi } from "../services/AuthService";
import Cookies from "js-cookie";

export default function mfaverifynewuser() {
  const history = useHistory();

  const [qrCode, setQrCode] = useState("");
  const [code, setCode] = useState("");
  const [codeError, setCodeError] = useState("");
  const [isLoader, setIsLoader] = useState(false);
  const [error, setError] = useState("");
  const [redirect, setRedirect] = useState(false);
  const [redirectValue, setRedirectValue] = useState("");
  const [troubleScanCode, setTroubleScanCode] = useState(false);

  useEffect(() => {
    if (getCookie("token")) {
      setRedirect(true);
      setRedirectValue("/");
    }
    getQrCode();
  });

  const getQrCode = async () => {
    await setQrCode(Cookies.get("QrCode"));
  };

  const handleCode = async (e) => {
    if (!isNaN(e.target.value)) {
      await setCode(e.target.value);
      await setCodeError("");
    } else {
      await setCode("");
      await setCodeError("Please enter numeric value !");
    }
  };

  const handleTroubleScanningCode = async (e) => {
    setTroubleScanCode(!troubleScanCode);
  };

  const handleEnter = async (e) => {
    if (e.key === "Enter") {
      handleSubmit();
    }
  };

  const handleSubmit = async () => {
    await setIsLoader(true);
    if (code === "") {
      await setIsLoader(false);
      await setCodeError("Code is required !");
    } else {
      const data = {
        email: Cookies.get("EMAIL"),
        session: Cookies.get("MFA_FOR_NEW_USER"),
        code: code,
      };
      const verifyMFA = await authApi.verifymfafornewuser(data);
      if (verifyMFA.status === 200) {
        if (verifyMFA.data.Status === "SUCCESS") {
          await Cookies.remove("MFA_FOR_NEW_USER");
          await Cookies.remove("CODE");
          await Cookies.remove("EMAIL");
          await Cookies.remove("QrCode");
          await setIsLoader(false);
          history.replace({
            pathname: "/signin",
            state: {
              message:
                "You have successfully registered for an account, you may login now!",
            },
          });
        }
        if (verifyMFA.data.status === 400 && verifyMFA.data.success === false) {
          await setIsLoader(false);
          if (
            verifyMFA.data.errors[0].msg ===
            "Invalid session for the user, session is expired."
          ) {
            await Cookies.remove("MFA_FOR_NEW_USER");
            await Cookies.remove("CODE");
            await Cookies.remove("EMAIL");
            await Cookies.remove("QrCode");
            history.replace({
              pathname: "/signin",
              state: {
                message: "Session is expired, Please signin again!",
              },
            });
          }
          if (verifyMFA.data.errors[0].msg === "Code mismatch") {
            setError("Invalid 2FA code!");
          }
        }
      }
    }
  };

  return (
    <>
      {isLoader ? (
        <div className="coin-loader-wrap" style={{ zIndex: "999999" }}>
          <div className="coin-loader">
            <span className="coin-loader-img">
              <img src={process.env.REACT_APP_LOADERS} alt="loader" />
            </span>
          </div>
        </div>
      ) : (
        ""
      )}
      {redirect && <Redirect to={redirectValue} />}
      <div className="login-page">
        <div className="login">
          <h2 className="login-title">
            {troubleScanCode ? (
              <span onClick={handleTroubleScanningCode}>
                <i className="fa fa-arrow-left"></i>
              </span>
            ) : (
              ""
            )}
            MFA Verify
          </h2>
          {error !== "" ? <p className="errorMsg">{error}</p> : ""}
          {troubleScanCode ? (
            <>
              <div className="qr-code">
                <p>
                  Manually enter the following code into your preferred
                  authenticator app and then enter the provided one-time code
                  below.
                </p>
              </div>
              <div className="qr-code">
                <p>{Cookies.get("CODE")}</p>
              </div>
            </>
          ) : (
            <>
              <div className="qr-code">
                <img src={qrCode} alt="QrCode" />
              </div>
              <div className="qr-code">
                <p
                  className="trouble-scan-code"
                  onClick={handleTroubleScanningCode}
                >
                  Trouble Scanning Code?
                </p>
              </div>
            </>
          )}
          <div className="input-email">
            <input
              className="input-1"
              type="text"
              name="code"
              value={code}
              placeholder="Enter 2FA code"
              onChange={handleCode}
              maxLength="6"
              onKeyPress={handleEnter}
            />
            {codeError !== "" ? <p className="errorMsg">{codeError}</p> : ""}
          </div>
          <button type="button" className="logSubmit" onClick={handleSubmit}>
            <i className="fa fa-door-open " /> Verify
          </button>
        </div>

        <div className="background" />
      </div>
    </>
  );
}
