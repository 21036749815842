import Slider from "react-slick";
import React, { useState } from "react";
import { Button } from "react-bootstrap";

function MarketCarousel(props) {
  const [isAutoplay, setIsAutoplay] = useState(true);
  const [carouselKey, setCarouselKey] = useState(0);
  const { ticker } = props;

  // Only reset the carousel when necessary to avoid unnecessary re-renders
  const handleBestClick = () => {
    setIsAutoplay(false);
    props.getBest();
    // Uncomment below only if necessary to reset the slider after fetching
    // setCarouselKey((prevKey) => prevKey + 1);
  };

  const handleWorstClick = () => {
    setIsAutoplay(false);
    props.getWorst();
    // Uncomment below only if necessary to reset the slider after fetching
    // setCarouselKey((prevKey) => prevKey + 1);
  };

  const settings = {
    key: carouselKey, // Used for resetting the carousel, but avoid updating too often
    infinite: true,
    speed: 700,
    autoplaySpeed: 2000,
    slidesToShow: 8,
    autoplay: isAutoplay, // Control autoplay via state
    slidesToScroll: 1,
    lazyLoad: true,
    responsive: [
      {
        breakpoint: 1800,
        settings: {
          slidesToShow: 7,
          slidesToScroll: 1,
        },
      },
      { 
        breakpoint: 1600,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 400,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  // Inline styles for image and alignment
  const imageStyle = {
    marginRight: "10px",
    width: "30px",
    height: "30px",
    objectFit: "contain",
  };

  const mediaBodyStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "start",
  };

  return (
    <div className="market-carousel">
      <Button className="btn m-2 btn-success" onClick={handleBestClick}>
        Best
      </Button>
      <Button className="btn btn-danger" onClick={handleWorstClick}>
        Worst
      </Button>
      <Slider {...settings}>
        {ticker.map(function (tick) {
          var price = tick.close;
          var origin = tick.open;
          var change = price - origin;
          var percentage = (change * 100) / origin;
          return (
            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6" key={tick.instrument}>
              <div className="card market-carousel-item p-0">
                <div className="card-body">
                  <div className="mt-2 mb-2">
                    <div className="row">
                      <div className="col-6" style={mediaBodyStyle}>
                        <img
                          src={`${
                            process.env.REACT_APP_COIN_ICON_URL +
                            tick.instrument
                              .slice(0, -4)
                              .replace(/_+/g, "")
                              .toUpperCase()
                          }.png`}
                          alt=""
                          style={imageStyle}
                        />
                        <div>
                          {tick.instrument.split("_")[0].toUpperCase()}
                        </div>
                      </div>
                      <div className="col-6">
                        <p
                          className="mb-0"
                          style={{ float: "right", fontSize: "14px" }}
                        >
                          24h
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="mt-2 mb-1">
                    <div className="row mb-0">
                      <div className="col-8">
                        <p
                          className="fw-bold mb-0"
                          style={{ fontSize: "14px", fontWeight: "500" }}
                        >
                          ${price}
                        </p>
                      </div>
                      <div className="col-4">
                        <p
                          className={
                            change < 0 ? "text-danger" : "text-success"
                          }
                          style={{ fontSize: "14px", float: "right" }}
                        >
                          {percentage.toFixed(2)}%
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </Slider>
    </div>
  );
}

export default React.memo(MarketCarousel); // Use memo to avoid unnecessary re-renders
