/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useCallback, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { Button, Form, Row, Col, Tab, Tabs } from "react-bootstrap";
import saas1 from "../../src/assets/images/editor large.gif";
import saas2 from "../../src/assets/images/editor-placeholder.jpg";
import saas3 from "../assets/images/editor.gif";
import Header from "../components/home/Header";
// import Testimonial from '../components/home/Testimonial'
import Bottom from "../components/home/Bottom";
import Footer from "../components/home/Footer";
import SwapBox from "./swapbox";
import TradingChart from "../components/TradingChart";
import TradingChartDark from "../components/TradingChartDark";
import { ThemeConsumer } from "../context/ThemeContext";
// import Particles from "react-particles-js";
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";
import Markets from "../components/home/Markets";
import { getCookie } from "../helpers/cookieHelper";
import { Autoplay } from "swiper";

import { Swiper, SwiperSlide } from "swiper/react";
import { getInstruments, getTicker } from "../actions/market";
function Home(props) {
  const isPwa = window.matchMedia("(display-mode: standalone)");

  const particleparam = {
    fullScreen: {
      enable: false,
    },
    particles: {
      number: {
        value: 101,
      },
      color: {
        value: "#5d5a72",
      },
      size: {
        value: 3,
      },
      collisions: {
        enable: true,
      },
      opacity: {
        value: 0.5,
        random: true,
      },
      links: {
        enable: true,
        distance: 150,
        color: "#c8c8c8",
        opacity: 0.4,
        width: 1,
      },
      move: {
        enable: true,
        speed: 1,
        outModes: {
          default: "bounce",
          bottom: "bounce",
          left: "bounce",
          right: "bounce",
          top: "bounce",
        },
      },
    },
    interactivity: {
      events: {
        onhover: {
          enable: true,
          mode: "repulse",
        },
      },
    },
  };

  React.useEffect(() => {
    var TxtType = function (el, toRotate, period) {
      this.toRotate = toRotate;
      this.el = el;
      this.loopNum = 0;
      this.period = parseInt(period, 10) || 2000;
      this.txt = "";
      this.tick();
      this.isDeleting = false;
    };

    TxtType.prototype.tick = function () {
      var i = this.loopNum % this.toRotate.length;
      var fullTxt = this.toRotate[i];

      if (this.isDeleting) {
        this.txt = fullTxt.substring(0, this.txt.length - 1);
      } else {
        this.txt = fullTxt.substring(0, this.txt.length + 1);
      }

      this.el.innerHTML = '<span class="wrap">' + this.txt + "</span>";

      var that = this;
      var delta = 40;

      if (this.isDeleting) {
        delta /= 2;
      }

      if (!this.isDeleting && this.txt === fullTxt) {
        delta = this.period;
        this.isDeleting = true;
      } else if (this.isDeleting && this.txt === "") {
        this.isDeleting = false;
        this.loopNum++;
        delta = 500;
      }

      setTimeout(function () {
        that.tick();
      }, delta);
    };

    window.onload = function () {
      var elements = document.getElementsByClassName("typewrite");
      for (var i = 0; i < elements.length; i++) {
        var toRotate = elements[i].getAttribute("data-type");
        var period = elements[i].getAttribute("data-period");
        if (toRotate) {
          new TxtType(elements[i], JSON.parse(toRotate), period);
        }
      }
      // INJECT CSS
      var css = document.createElement("style");
      css.type = "text/css";
      css.innerHTML = ".typewrite > .wrap { border-right: 0.08em solid #000}";
      document.body.appendChild(css);
    };
  }, []);

  const particlesInit = useCallback(async (engine) => {
    await loadFull(engine);
  }, []);

  const particlesLoaded = useCallback(async (container) => {
    // await console.log('container');
  }, []);

  const [email, setEmail] = useState("");
  const [instruments, setInstruments] = useState([]);
  const [tokens, setTokens] = useState(["all", "btc", "usdt"]);
  const [tradeList, setTradeList] = useState([]);
  const [starList, setStarList] = useState([]);
  const [isLoader, setIsLoader] = useState(true);
  const [tickers, setTickers] = useState([]);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [activeTab, setActiveTab] = useState("top-gainers");
  const history = useHistory();

  useEffect(() => {
    const fetchData = async () => {
      console.log("remove aos");
      await getInstruments()
        .then((res) => {
          setInstruments(res);
        })
        .catch(() => { });
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (instruments?.length && !isDataLoaded) {
      loadData();
      setIsDataLoaded(true);
    }
  }, [instruments]);

  async function loadData() {
    let trades = [];
    let tickers = await getTicker();
    setTickers(tickers);
    for (let tIndex = 0; tIndex < tokens.length; tIndex++) {
      const token = tokens[tIndex];
      let trade = [];
      let usdt_price = [];
      let filtered_instruments = instruments.filter((instrument) =>
        instrument.includes("_" + token)
      );
      for (let iIndex = 0; iIndex < filtered_instruments.length; iIndex++) {
        const instrument = filtered_instruments[iIndex];
        tickers.forEach((t) => {
          if (instrument === t.instrument) {
            t.price = t.close;
            t.origin = t.open;
            t.change = t.price - t.origin;
            t.percentage = (t.change * 100) / t.origin;
            trade.push(t);
            if (instrument?.includes("usdt")) {
              usdt_price.push(t);
            }
            if (instrument === "btc_usdt") {
              console.log("price=" + t.price);
            }
          }
        });
      }
      trades.push(trade);
    }
    setIsLoader(false);
    for (const trade of trades) {
      trades[0].push(...trade);
    }
    setTradeList(trades);
  }

  useEffect(() => {
    const timer = setInterval(() => {
      if (!isLoader) {
        loadData();
      }
    }, 20000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    // Pass the email to the signup page via state
    history.push({
      pathname: "/signup",
      state: { email: email },
    });
  };
  const swiperConfig = {
    slidesPerView: 1,
    loop: true,
    spaceBetween: 0,
    autoplay: true,
    breakpoints: {
      576: { slidesPerView: 1.2 },
      768: { slidesPerView: 1 },
    },
    roundLengths: true,
  };
  return isPwa?.matches ? (
    <>
      <Header />
      <div>
        <Swiper modules={[Autoplay]} {...swiperConfig}>
          {([saas1, saas2, saas3] || []).map((img, index) => {
            return (
              <SwiperSlide key={index.toString()}>
                <img
                  src={img}
                  alt=""
                  style={{ width: "100%", height: "170px" }}
                  className="img-fluid"
                />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
      <div className="crypto-coin-tab-section">
        <Tabs
          defaultActiveKey="top-gainers"
          className="nav nav-underline"
          activeKey={activeTab}
          onSelect={(k) => setActiveTab(k)}
          style={{
            margin: "0px",
            padding: "0px",
            justifyContent: "center",
            marginTop: "10px",
          }}
        >
          <Tab
            eventKey="top-gainers"
            title={
              <div style={{ display: "flex", justifyContent: "flex" }}>
                <p>Top Gainers&nbsp;&nbsp;</p>
                <i className=" ion ion-md-arrow-round-up tab-label-icon-up" />
              </div>
            }
            onClick={() => {
              setActiveTab("top-gainers");
            }}
          >
            <table className="table star-active">
              <thead className="table-head-pwa">
                <tr>
                  <th
                    style={{
                      width: "130px",
                      padding: "18px",
                    }}
                  >
                    Pairs
                  </th>
                  <th
                    style={{
                      width: "100px",
                      padding: "18px",
                    }}
                  >
                    Price
                  </th>
                  <th
                    style={{
                      width: "100px",
                      padding: "18px",
                    }}
                  >
                    24h Change
                  </th>
                </tr>
              </thead>
              <tbody>
                {tickers
                  .filter((val) => Number(val.percentage) >= 0)
                  .sort(
                    (a, b) =>
                      Number(b.percentage).toFixed(2) -
                      Number(a.percentage).toFixed(2)
                  )
                  .slice(0, 6)
                  .map((coin) => {
                    const primaryCoin = coin.instrument
                      .split("_")[0]
                      .toUpperCase();
                    const secondaryCoin = coin.instrument
                      .split("_")[1]
                      .toUpperCase();

                    const handleAddStarlist = () => {
                      const includeInstar = starList.includes(coin.instrument);

                      if (!includeInstar) {
                        setStarList([...starList, coin.instrument]);
                      } else {
                        const valIndOFstarList = starList.findIndex(
                          (val) => val === coin.instrument
                        );

                        starList.splice(valIndOFstarList, 1);
                        setStarList([...starList]);
                      }
                    };

                    return (
                      <tr>
                        <td>
                          <button
                            style={{
                              backgroundColor: "transparent",
                              border: "none",
                              padding: "5px",
                            }}
                            onClick={() => {
                              handleAddStarlist();
                            }}
                          >
                            <i
                              className="icon ion-md-star"
                              style={{
                                fontSize: "15px",
                                color: !starList.includes(coin.instrument)
                                  ? "white"
                                  : "blue",
                              }}
                            ></i>
                          </button>
                          <span style={{ fontWeight: "700" }}>
                            {primaryCoin}
                          </span>
                          <span style={{ color: "#6e6e6e" }}>
                            /{secondaryCoin}
                          </span>
                        </td>
                        <td>
                          <p
                            className={
                              Number(coin.percentage).toFixed(2) >= 0
                                ? "green"
                                : "red"
                            }
                            style={{ fontWeight: "600" }}
                          >
                            {Number(coin.high)}
                          </p>
                          <p>${coin.price}</p>
                        </td>
                        <td>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              width: "5rem",
                              height: "2.5rem",
                              borderRadius: "0.3rem",
                              color: "white",
                              backgroundColor:
                                Number(coin.percentage).toFixed(2) >= 0
                                  ? "green"
                                  : "red",
                            }}
                          >
                            {Number(coin.percentage).toFixed(2)}%
                          </div>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </Tab>
          <Tab
            eventKey="top-losers"
            title={
              <div style={{ display: "flex", justifyContent: "flex" }}>
                <p>Top Losers&nbsp;&nbsp;</p>
                <i className=" ion ion-md-arrow-round-down tab-label-icon-down" />
              </div>
            }
            onClick={() => {
              setActiveTab("top-losers");
            }}
          >
            <table className="table star-active">
              <thead className="table-head-pwa">
                <tr>
                  <th
                    style={{
                      width: "130px",
                      padding: "18px",
                    }}
                  >
                    Pairs
                  </th>
                  <th
                    style={{
                      width: "100px",
                      padding: "18px",
                    }}
                  >
                    Price
                  </th>
                  <th
                    style={{
                      width: "100px",
                      padding: "18px",
                    }}
                  >
                    24h Change
                  </th>
                </tr>
              </thead>
              <tbody>
                {tickers
                  .filter((val) => Number(val.percentage).toFixed(2) < 0)
                  .sort(
                    (a, b) =>
                      Number(a.percentage).toFixed(2) -
                      Number(b.percentage).toFixed(2)
                  )
                  .slice(0, 6)
                  .map((coin) => {
                    const primaryCoin = coin.instrument
                      .split("_")[0]
                      .toUpperCase();
                    const secondaryCoin = coin.instrument
                      .split("_")[1]
                      .toUpperCase();

                    const handleAddStarlist = () => {
                      const includeInstar = starList.includes(coin.instrument);

                      if (!includeInstar) {
                        setStarList([...starList, coin.instrument]);
                      } else {
                        const valIndOFstarList = starList.findIndex(
                          (val) => val === coin.instrument
                        );

                        starList.splice(valIndOFstarList, 1);
                        setStarList([...starList]);
                      }
                    };

                    return (
                      <tr>
                        <td>
                          <button
                            style={{
                              backgroundColor: "transparent",
                              border: "none",
                              padding: "5px",
                            }}
                            onClick={() => {
                              handleAddStarlist();
                            }}
                          >
                            <i
                              className="icon ion-md-star"
                              style={{
                                fontSize: "15px",
                                color: !starList.includes(coin.instrument)
                                  ? "white"
                                  : "blue",
                              }}
                            ></i>
                          </button>
                          <span style={{ fontWeight: "700" }}>
                            {primaryCoin}
                          </span>
                          <span style={{ color: "#6e6e6e" }}>
                            /{secondaryCoin}
                          </span>
                        </td>
                        <td>
                          <p
                            className={
                              Number(coin.percentage).toFixed(2) >= 0
                                ? "green"
                                : "red"
                            }
                            style={{ fontWeight: "600" }}
                          >
                            {Number(coin.high)}
                          </p>
                          <p>${coin.price}</p>
                        </td>
                        <td>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              width: "5rem",
                              height: "2.5rem",
                              borderRadius: "0.3rem",
                              color: "white",
                              backgroundColor:
                                Number(coin.percentage).toFixed(2) >= 0
                                  ? "green"
                                  : "red",
                            }}
                          >
                            {Number(coin.percentage).toFixed(2)}%
                          </div>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </Tab>
          <Tab
            eventKey="favourites"
            title={
              <div style={{ display: "flex", justifyContent: "flex" }}>
                <p>Favourites&nbsp;&nbsp;</p>
                <i
                  className=" ion ion-md-star tab-label-icon-star"
                  style={{ color: "#83bbff" }}
                />
              </div>
            }
            onClick={() => {
              setActiveTab("favourites");
            }}
          >
            <table className="table star-active">
              <thead className="table-head-pwa">
                <tr>
                  <th
                    style={{
                      width: "130px",
                      padding: "18px",
                    }}
                  >
                    Pairs
                  </th>
                  <th
                    style={{
                      width: "100px",
                      padding: "18px",
                    }}
                  >
                    Price
                  </th>
                  <th
                    style={{
                      width: "100px",
                      padding: "18px",
                    }}
                  >
                    24h Change
                  </th>
                </tr>
              </thead>
              <tbody>
                {starList.map((star) => {
                  const coin = tickers.filter(
                    (val) => val.instrument === star
                  )[0];
                  const primaryCoin = coin.instrument
                    .split("_")[0]
                    .toUpperCase();
                  const secondaryCoin = coin.instrument
                    .split("_")[1]
                    .toUpperCase();

                  const includeInstar = starList?.includes(coin?.instrument);

                  const handleAddStarlist = () => {
                    if (!includeInstar) {
                      setStarList([...starList, coin.instrument]);
                    } else {
                      const valIndOFstarList = starList.findIndex(
                        (val) => val === coin.instrument
                      );
                      starList.splice(valIndOFstarList, 1);
                      setStarList([...starList]);
                    }
                  };

                  return (
                    <tr>
                      <td>
                        <button
                          style={{
                            backgroundColor: "transparent",
                            border: "none",
                            padding: "5px",
                          }}
                          onClick={() => {
                            handleAddStarlist();
                          }}
                        >
                          {includeInstar ? (
                            <i
                              className="icon ion-md-star"
                              style={{ fontSize: "15px" }}
                            ></i>
                          ) : (
                            <i className="icon ion-md-star"></i>
                          )}
                        </button>
                        <span style={{ fontWeight: "700" }}>{primaryCoin}</span>
                        <span style={{ color: "#6e6e6e" }}>
                          /{secondaryCoin}
                        </span>
                      </td>
                      <td>
                        <p
                          className={
                            Number(coin.percentage).toFixed(2) >= 0
                              ? "green"
                              : "red"
                          }
                          style={{ fontWeight: "600" }}
                        >
                          {Number(coin.high)}
                        </p>
                        <p>${coin.price}</p>
                      </td>
                      <td>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "5rem",
                            height: "2.5rem",
                            borderRadius: "0.3rem",
                            color: "white",
                            backgroundColor:
                              Number(coin.percentage).toFixed(2) >= 0
                                ? "green"
                                : "red",
                          }}
                        >
                          {Number(coin.percentage).toFixed(2)}%
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </Tab>
        </Tabs>
      </div>
    </>
  ) : (
    <>
      <Header />
      <div className="tradio">
        <div id="main-wrapper">
          <div className="particlearea">
            {/* <Particles className="particlebackground" params={particleparam} /> */}
            <Particles
              // id="tsparticles"
              className="particlebackground"
              init={particlesInit}
              loaded={particlesLoaded}
              options={particleparam}
            />

            <div className="swap">
              <div className="container">
                <div className="row justify-content-between align-items-center">
                  <div className="col-xl-6 col-lg-6 col-12">
                    <SwapBox />
                  </div>
                  {/* </motion.div> */}
                  <div className="col-xl-5 col-lg-6 col-12">
                    <div className="intro-content">
                      <h1>
                        <strong className="text-primary">
                          Instant Crypto Swaps
                        </strong>
                        <br />
                        No Registration
                        <br />
                      </h1>
                      Stay in control of assets on our non-custodial swap
                      platform!
                    </div>
                    <div className="mt-4 buttongroup">
                      <Link
                        to={{
                          pathname:
                            "https://apps.apple.com/app/cryptiswap/id1558998980#?platform=iphone",
                        }}
                        className="btn btn-primary my-1 waves-effect"
                        target="_blank"
                      >
                        <img src="img/apple.svg" alt="" />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="intro">
              <div className="container">
                <div className="row justify-content-between align-items-center">
                  <div className="col-xl-5 col-lg-5 col-12">
                    <div className="intro-content">
                      <h1>
                        <strong className="text-primary">
                          Crypto Exchange
                        </strong>
                        <br />
                        Optional Registration
                      </h1>
                      <p>
                        Trade crypto like a pro
                        <br />
                        <a
                          className="typewrite"
                          data-period="300"
                          data-type='[ "Bitcoin", "Ethereum", "Solana", "BNB", "Tether", "+ Much More!" ]'
                        >
                          <span class="wrap"></span>
                        </a>
                      </p>
                      <Form onSubmit={handleSubmit}>
                        <Row style={{ paddingBottom: "50px" }}>
                          <Col>
                            {getCookie("signin") ? (
                              ""
                            ) : (
                              <>
                                <Form.Control
                                  className="form-control input-email-home"
                                  type="email"
                                  placeholder="Enter email"
                                  value={email}
                                  onChange={handleEmailChange}
                                />
                                <Button
                                  variant="primary"
                                  type="submit"
                                  style={{
                                    padding: "7.5px 20px",
                                    marginBottom: "3px",
                                    marginLeft: "6px",
                                  }}
                                >
                                  Sign Up
                                </Button>
                              </>
                            )}
                          </Col>
                        </Row>
                      </Form>
                    </div>
                  </div>
                  <div className="col-xl-7 col-lg-7 col-12">
                    <ThemeConsumer>
                      {({ data }) => {
                        console.log(data);

                        return data.theme !== "light" ? (
                          <TradingChart />
                        ) : (
                          <TradingChartDark />
                        );
                      }}
                    </ThemeConsumer>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Markets />

          <div className="portfolio section-padding" id="portfolio">
            <div className="container">
              <div className="row py-lg-5">
                <div className="col-xl-7">
                  <div className="section-title">
                    <h2>
                      <span
                        className="heading-link"
                        onClick={() =>
                          (window.location = `${process.env.REACT_APP_TRADE_PLATFORM}/auth/register`)
                        }
                      >
                        Register to trade in just 2 minutes!
                      </span>
                    </h2>
                    <p>
                      Whether you wish to stay in control of your funds on our
                      non-custodial swap platform or prefer the convenience of
                      our trading platform, you have the freedom to choose. Your
                      Keys, Your Crypto!
                    </p>
                  </div>
                </div>
              </div>
              <div className="row justify-content-between">
                <div className="col-xl-7 col-lg-6">
                  <div className="portfolio_img">
                    <img
                      src="img/portfolio.png"
                      alt=""
                      className="img-fluid light"
                    />
                    <img
                      src="img/darkPortfolio.png"
                      alt=""
                      className="img-fluid dark"
                    />
                  </div>
                </div>
                <div className="col-xl-5 col-lg-6">
                  <div className="portfolio_list">
                    <div className="row">
                      <div className="media">
                        <div className="media-body">
                          <h4>
                            <svg
                              width="32px"
                              height="32px"
                              viewBox="0 0 64 64"
                              xmlns="http://www.w3.org/2000/svg"
                              style={{ marginRight: "10px" }}
                            >
                              <g fill="none" fill-rule="evenodd">
                                <path
                                  fill="#4796E7"
                                  d="M22.2961061,22.6064668 L18,21.4553287 L20.0705524,13.7279221 L24.3666584,14.8790602 C25.2987684,13.1099274 26.532331,11.4830474 28.0445592,10.0834629 L25.8215427,6.23308548 L32.749746,2.23308548 L34.9727624,6.08346287 C36.9409522,5.47362711 38.966653,5.21877055 40.9648219,5.29610607 L42.11596,1 L49.8433667,3.07055236 L48.6922285,7.36665843 C50.4613613,8.29876837 52.0882413,9.532331 53.4878258,11.0445592 L57.3382032,8.82154274 L61.3382032,15.749746 L57.4878258,17.9727624 C58.0976616,19.9409522 58.3525182,21.966653 58.2751826,23.9648219 L62.5712887,25.11596 L60.5007364,32.8433667 L56.2046303,31.6922285 C55.2725203,33.4613613 54.0389577,35.0882413 52.5267295,36.4878258 L54.749746,40.3382032 L47.8215427,44.3382032 L45.5985263,40.4878258 C43.6303365,41.0976616 41.6046358,41.3525182 39.6064668,41.2751826 L38.4553287,45.5712887 L30.7279221,43.5007364 L31.8790602,39.2046303 C30.1099274,38.2725203 28.4830474,37.0389577 27.0834629,35.5267295 L23.2330855,37.749746 L19.2330855,30.8215427 L23.0834629,28.5985263 C22.4736271,26.6303365 22.2187706,24.6046358 22.2961061,22.6064668 Z M43.2856444,28.4817968 C46.1554001,26.8249425 47.138651,23.1554001 45.4817968,20.2856444 C43.8249425,17.4158886 40.1554001,16.4326377 37.2856444,18.0894919 C34.4158886,19.7463462 33.4326377,23.4158886 35.0894919,26.2856444 C36.7463462,29.1554001 40.4158886,30.138651 43.2856444,28.4817968 Z"
                                />
                                <path
                                  fill="#595959"
                                  d="M6.262792,48.9140329 L3.37117307,48.139226 L4.76481846,42.9380706 L7.65643738,43.7128775 C8.28382104,42.5221113 9.11410618,41.4270925 10.1319553,40.4850615 L8.63568951,37.8934532 L13.2989179,35.2011371 L14.7951836,37.7927454 C16.1199309,37.3822777 17.4833876,37.2107391 18.8283132,37.262792 L19.6031202,34.3711731 L24.8042756,35.7648185 L24.0294686,38.6564374 C25.2202348,39.283821 26.3152537,40.1141062 27.2572846,41.1319553 L29.8488929,39.6356895 L32.5412091,44.2989179 L29.9496008,45.7951836 C30.3600685,47.1199309 30.5316071,48.4833876 30.4795541,49.8283132 L33.3711731,50.6031202 L31.9775277,55.8042756 L29.0859088,55.0294686 C28.4585251,56.2202348 27.62824,57.3152537 26.6103909,58.2572846 L28.1066566,60.8488929 L23.4434283,63.5412091 L21.9471625,60.9496008 C20.6224152,61.3600685 19.2589585,61.5316071 17.9140329,61.4795541 L17.139226,64.3711731 L11.9380706,62.9775277 L12.7128775,60.0859088 C11.5221113,59.4585251 10.4270925,58.62824 9.48506149,57.6103909 L6.89345319,59.1066566 L4.20113705,54.4434283 L6.79274535,52.9471625 C6.38227769,51.6224152 6.21073908,50.2589585 6.262792,48.9140329 Z M20.3904102,52.8685943 C22.3219826,51.7534005 22.9837882,49.2835084 21.8685943,47.351936 C20.7534005,45.4203635 18.2835084,44.758558 16.351936,45.8737518 C14.4203635,46.9889457 13.758558,49.4588378 14.8737518,51.3904102 C15.9889457,53.3219826 18.4588378,53.9837882 20.3904102,52.8685943 Z"
                                  transform="rotate(-15 18.371 49.371)"
                                />
                              </g>
                            </svg>
                            User Choice
                          </h4>
                          <p>
                            Register for our trading platform, or hold onto
                            custody with our instant swaps, we won't force you
                            to choose.
                          </p>
                        </div>
                      </div>
                      <div className="media">
                        <div className="media-body">
                          <h4>
                            <svg
                              width="32px"
                              height="32px"
                              viewBox="0 0 64 64"
                              xmlns="http://www.w3.org/2000/svg"
                              style={{ marginRight: "10px" }}
                            >
                              <g fill="none" fill-rule="evenodd">
                                <path
                                  fill="#5B68C0"
                                  d="M61,22.3114038 C61,43.1373865 48.7824319,57.5302596 32.5,61.6815487 C16.2175681,57.5302596 4,43.1373865 4,22.3114038 C4,17.9864771 4.52690374,13.8096091 5.50769464,9.86864574 C8.34082182,11.8425602 11.7852436,13 15.5,13 C23.727788,13 27.9689741,1.03261319 32.5,1.03261319 C37.3130465,1.03261319 41.272212,13 49.5,13 C53.2147564,13 56.6591782,11.8425602 59.4923047,9.86864138 C60.4730963,13.8096091 61,17.9864771 61,22.3114038 Z"
                                />
                                <path
                                  fill="#FFDD95"
                                  d="M27,37.3164468 C23.9897907,34.470456 22,29.8615543 22,26 C22,19.9248678 26.9248678,15 33,15 C39.0751322,15 44,19.9248678 44,26 C44,29.8615543 42.0102093,34.470456 39,37.3164468 L39,44 C39,44 34.9325778,45.4486507 33,45.4486507 C31.0674222,45.4486507 27,44 27,44 L27,37.3164468 Z"
                                />
                                <polygon
                                  fill="#FFF"
                                  points="25 43 32.998 44.878 25 49"
                                />
                                <polyline
                                  fill="#FFF"
                                  points="41.081 43 33 44.851 41.081 49"
                                />
                                <circle cx="24" cy="31" r="3" fill="#FFDD95" />
                                <circle cx="42" cy="31" r="3" fill="#FFDD95" />
                              </g>
                            </svg>
                            Security
                          </h4>
                          <p>
                            High risk actions like sign-ins and withdrawals
                            require authentication. Our encryption methods are
                            stronger than those used in most banks.
                          </p>
                        </div>
                      </div>
                      <div className="media">
                        <div className="media-body">
                          <h4>
                            <svg
                              width="32px"
                              height="32px"
                              viewBox="0 0 64 64"
                              xmlns="http://www.w3.org/2000/svg"
                              style={{ marginRight: "10px" }}
                            >
                              <g fill="none" fill-rule="evenodd">
                                <path
                                  fill="#80D25B"
                                  d="M4,44.0023695 C4,41.7919219 5.78716697,39.7942228 7.96428975,39.5435455 L21,38.0425936 L34.0357102,39.5435455 C36.225127,39.7956384 38,41.7918218 38,44.0023695 L38,51 L4,51 L4,44.0023695 Z"
                                />
                                <path
                                  fill="#FFDD95"
                                  d="M15,33.3164468 C11.9897907,30.470456 10,25.8615543 10,22 C10,15.9248678 14.9248678,11 21,11 C27.0751322,11 32,15.9248678 32,22 C32,25.8615543 30.0102093,30.470456 27,33.3164468 L27,40 C27,40 22.9325778,41.4486507 21,41.4486507 C19.0674222,41.4486507 15,40 15,40 L15,33.3164468 Z"
                                />
                                <circle cx="12" cy="27" r="3" fill="#FFDD95" />
                                <circle cx="30" cy="27" r="3" fill="#FFDD95" />
                                <path
                                  fill="#4796E7"
                                  d="M26,44.0023695 C26,41.7919219 27.787167,39.7942228 29.9642898,39.5435455 L43,38.0425936 L56.0357102,39.5435455 C58.225127,39.7956384 60,41.7918218 60,44.0023695 L60,51 L26,51 L26,44.0023695 Z"
                                />
                                <path
                                  fill="#FFDD95"
                                  d="M37,33.3164468 C33.9897907,30.470456 32,25.8615543 32,22 C32,15.9248678 36.9248678,11 43,11 C49.0751322,11 54,15.9248678 54,22 C54,25.8615543 52.0102093,30.470456 49,33.3164468 L49,40 C49,40 44.9325778,41.4486507 43,41.4486507 C41.0674222,41.4486507 37,40 37,40 L37,33.3164468 Z"
                                />
                                <circle cx="34" cy="27" r="3" fill="#FFDD95" />
                                <circle cx="52" cy="27" r="3" fill="#FFDD95" />
                                <path
                                  fill="#F5413E"
                                  d="M15,50.0023695 C15,47.7919219 16.787167,45.7942228 18.9642898,45.5435455 L32,44.0425936 L45.0357102,45.5435455 C47.225127,45.7956384 49,47.7918218 49,50.0023695 L49,57 L15,57 L15,50.0023695 Z"
                                />
                                <path
                                  fill="#FFAF40"
                                  d="M26,39.3164468 C22.9897907,36.470456 21,31.8615543 21,28 C21,21.9248678 25.9248678,17 32,17 C38.0751322,17 43,21.9248678 43,28 C43,31.8615543 41.0102093,36.470456 38,39.3164468 L38,46 C38,46 33.9325778,47.4486507 32,47.4486507 C30.0674222,47.4486507 26,46 26,46 L26,39.3164468 Z"
                                />
                                <circle cx="23" cy="33" r="3" fill="#FFAF40" />
                                <circle cx="41" cy="33" r="3" fill="#FFAF40" />
                              </g>
                            </svg>
                            No Holds
                          </h4>
                          <p>
                            Unlike other exchanges, we'll never hold your funds
                            against your will. If a transaction doesn't go as
                            planned, instant refunds are initiated.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <h5 className="media">Available on:</h5>
                  <div className="row">
                    <div
                      className="col-xl-3 col-lg-3 col-md-6"
                      style={{ marginBottom: "15px" }}
                    >
                      <a
                        href="https://play.google.com/store/apps/details?id=com.cryptiswap"
                        className="portfolio_item"
                        style={{ width: "100%" }}
                      >
                        <div className="available-item">
                          <span>
                            <i className="lab la-google-play"></i>
                          </span>
                          <h6>Google Play</h6>
                        </div>
                      </a>
                    </div>
                    <div
                      className="col-xl-3 col-lg-3 col-md-6"
                      style={{ marginBottom: "15px" }}
                    >
                      <a
                        href="https://apps.apple.com/app/cryptiswap/id1558998980#?platform=iphone"
                        className="portfolio_item"
                        style={{ width: "100%" }}
                      >
                        <div className="available-item">
                          <span>
                            <i className="la la-apple"></i>
                          </span>
                          <h6>App Store</h6>
                        </div>
                      </a>
                    </div>
                    <div
                      className="col-xl-3 col-lg-3 col-md-6"
                      style={{ marginBottom: "15px" }}
                    >
                      <a
                        href="https://swaptrade-global.github.io/"
                        className="portfolio_item"
                        style={{ width: "100%" }}
                      >
                        <div className="available-item">
                          <span>
                            <i className="la la-plug"></i>
                          </span>
                          <h6>API</h6>
                        </div>
                      </a>
                    </div>
                    <div
                      className="col-xl-3 col-lg-3 col-md-6"
                      style={{ marginBottom: "15px" }}
                    >
                      <a
                        href="https://www.swaptrade.io/swap/BTC/ETH/0.01"
                        className="portfolio_item"
                        style={{ width: "100%" }}
                      >
                        <div className="available-item">
                          <span>
                            <i className="la la-globe"></i>
                          </span>
                          <h6>Web</h6>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Bottom />
          <Footer />
        </div>
      </div>
    </>
  );
}

export default Home;
