/* eslint-disable linebreak-style */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable class-methods-use-this */
/* eslint-disable camelcase */
/* eslint-disable func-names */
/* eslint-disable react/no-unused-state */
import React from "react";
import { Nav } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { swapApi } from "../../services/SwapService";
import confetti from "canvas-confetti";

function withParams(Comp) {
  return function (props) {
    return <Comp {...props} params={useParams()} />;
  };
}
class Receipt extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      orderId: "",
      sendAmount: "",
      sendAsset: "",
      receiveAmount: "",
      receiveAsset: "",
      receiveAddress: "",
      transId: "",
      isCopied: false, //tooltip
      status: "",
      isPwa: window.matchMedia("(display-mode: standalone)"),
    };
  }

  componentDidMount() {
    this.getOrder(this.props.params.order_id);

    var duration = 10 * 1000;
    var animationEnd = Date.now() + duration;
    var defaults = { startVelocity: 30, spread: 360, ticks: 60, zIndex: 0 };

    function randomInRange(min, max) {
      return Math.random() * (max - min) + min;
    }

    var interval = setInterval(function () {
      var timeLeft = animationEnd - Date.now();

      if (timeLeft <= 0) {
        return clearInterval(interval);
      }

      var particleCount = 100 * (timeLeft / duration);
      // since particles fall down, start a bit higher than random
      confetti(
        Object.assign({}, defaults, {
          particleCount,
          origin: { x: randomInRange(0.1, 0.3), y: Math.random() - 0.2 },
        })
      );
      confetti(
        Object.assign({}, defaults, {
          particleCount,
          origin: { x: randomInRange(0.7, 0.9), y: Math.random() - 0.2 },
        })
      );
    }, 250);
  }

  getOrder = async (order_id) => {
    const data = {
      order_id,
    };
    const orderDetail = await swapApi.getOrderDetails(data);
    if (orderDetail.status === 200 && orderDetail.data.status === 200) {
      this.setState({ orderId: orderDetail.data.data.order_id });
      this.setState({ sendAmount: orderDetail.data.data.amount_send });
      this.setState({ sendAsset: orderDetail.data.data.coin_send });
      this.setState({ receiveAmount: orderDetail.data.data.amount_receive });
      this.setState({ receiveAsset: orderDetail.data.data.coin_receive });
      this.setState({ receiveAddress: orderDetail.data.data.recipient });
      this.setState({ status: orderDetail.data.data.status });
      this.setState({ transId: orderDetail.data.data.transId }); // Set transaction ID
    }
  };

  handleCopy = () => {
    const textToCopy = `Transaction ID: ${this.state.transId}`;
    navigator.clipboard.writeText(textToCopy);
    this.setState({ isCopied: true });
    setTimeout(() => this.setState({ isCopied: false }), 2000); // Hide after 2 seconds
  };

  render() {
    const isDarkMode = document.body.classList.contains("dark"); // Check for dark class
    const iconColor = isDarkMode ? "#0069D1" : "#0180FF"; // Set color based on mode

    return this.state.isPwa.matches ? (
      <>
        <div
          className="col-sm-2 col-md-2 side_menu2 swap-header-contr swap-header-contr-pwa"
          style={{ position: "fixed", top: "0px", zIndex: "71000000" }}
        >
          <div
            style={{ display: "flex" }}
            onClick={() => {
              window.location.href = "/swap";
            }}
          >
            <i className=" ion ion-ios-arrow-back" />
          </div>
          <ol className="text-center tree-structure2">
            <li>
              <span className="num active">1</span>
            </li>
            <li>
              <span className="num active">2</span>
            </li>
            <li style={{ paddingRight: "0px" }}>
              <span className="num active">3</span>
            </li>
          </ol>
        </div>
        <section>
          <div className="container-fluid">
            <div className="row" style={{ margin: "2%", marginTop: "7%" }}>
              <div className="col-sm-10 col-md-10">
                {this.state.status === "9" ? (
                  <div className="sendBoxArea">
                    <div className="orderId plain-text">
                      OrderId:
                      {this.state.orderId}
                    </div>
                    <div className="sendBox plain-text">
                      <h5>This transaction has expired!</h5>
                    </div>
                  </div>
                ) : (
                  <>
                    {this.state.status === "10" ? (
                      <div className="sendBoxArea">
                        <div className="orderId plain-text">
                          OrderId:
                          {this.state.orderId}
                        </div>
                        <div className="sendBox plain-text">
                          <h5>
                            The amount you sent was too small, please send more
                            than the minimum amount.
                          </h5>
                        </div>
                      </div>
                    ) : (
                      <div className="sendBoxArea">
                        <div className="orderId plain-text">
                          OrderId:
                          {this.state.orderId}
                        </div>
                        <div className="sendBox">
                          <h5 className="plain-text">You sent</h5>
                          <p className="plain-text">
                            {`${this.state.sendAmount} ${this.state.sendAsset}`}
                          </p>
                        </div>
                        <div className="sendBox">
                          <h5 className="plain-text">You received:</h5>
                          <p className="plain-text">
                            {`${this.state.receiveAmount} ${this.state.receiveAsset}`}
                          </p>
                        </div>
                        <div className="sendBox">
                          <h5 className="plain-text">
                            To the following address:
                          </h5>
                          <p
                            className="plain-text"
                            style={{ wordWrap: "break-word" }}
                          >
                            {this.state.receiveAddress}
                          </p>
                        </div>
                        {/* <div className="sendBox">
                      <h5 className='plain-text'>Final Exchange rate :</h5>
                      <p className='plain-text'>
                        {`${this.state.sendAmount} ${this.state.sendAsset}`}
                        =
                        {`${this.state.receiveAmount} ${this.state.receiveAsset}`}
                      </p>
                    </div> */}
                        <div className="sendBox"></div>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </section>
      </>
    ) : (
      <section>
        <div className="container-fluid">
          <div className="row" style={{ marginTop: "2%" }}>
            <div
              className="col-sm-10 col-md-1 menu_option"
              style={{ marginBottom: "2%" }}
            >
              <Nav
                variant="pills"
                className="swap-nav"
                style={{ boxShadow: "none" }}
              >
                <Nav.Item style={{ marginTop: "20px", marginBottom: "20px" }}>
                  <Nav.Link>Choose</Nav.Link>
                </Nav.Item>
                <Nav.Item style={{ marginTop: "20px", marginBottom: "20px" }}>
                  <Nav.Link>Send</Nav.Link>
                </Nav.Item>
                <Nav.Item style={{ marginTop: "20px", marginBottom: "20px" }}>
                  <Nav.Link>Receive</Nav.Link>
                </Nav.Item>
              </Nav>
            </div>
            <div className="col-md-1 side_menu" style={{ marginTop: "2%" }}>
              <ol className="text-center tree-structure">
                <li>
                  <span className="num">1</span>
                  <ol></ol>
                </li>
                <li>
                  <span className="num">2</span>
                  <ol></ol>
                </li>
                <li>
                  <span
                    className="num"
                    style={{ background: "#428bca", color: "#fff" }}
                  >
                    3
                  </span>
                  <ol></ol>
                </li>
              </ol>
            </div>
            <div className="col-sm-2 col-md-2 side_menu2">
              <ol className="text-center tree-structure2">
                <li>
                  <span className="num">1</span>
                </li>
                <li>
                  <span className="num">2</span>
                </li>
                <li>
                  <span
                    className="num"
                    style={{ background: "#428bca", color: "#fff" }}
                  >
                    3
                  </span>
                </li>
              </ol>
              <ol className="text-center tree-structure">
                <li>
                  <span className="num">1</span>
                  <ol></ol>
                </li>
                <li>
                  <span className="num">2</span>
                  <ol></ol>
                </li>
                <li>
                  <span
                    className="num"
                    style={{ background: "#428bca", color: "#fff" }}
                  >
                    3
                  </span>
                  <ol></ol>
                </li>
              </ol>
            </div>
            <div className="col-sm-10 col-md-10">
              {this.state.status === "9" ? (
                <div className="sendBoxArea">
                  <div className="orderId plain-text">
                    OrderId:
                    {this.state.orderId}
                  </div>
                  <div className="sendBox plain-text">
                    <h5>This transaction has expired!</h5>
                  </div>
                </div>
              ) : (
                <>
                  {this.state.status === "10" ? (
                    <div className="sendBoxArea">
                      <div className="orderId plain-text">
                        OrderId:
                        {this.state.orderId}
                      </div>
                      <div className="sendBox plain-text">
                        <h5>
                          The amount you sent was too small, please send more
                          than the minimum amount.
                        </h5>
                      </div>
                    </div>
                  ) : (
                    <div className="sendBoxArea">
                      <div className="orderId plain-text">
                        OrderId:
                        {this.state.orderId}
                      </div>
                      <div className="sendBox">
                        <h5 className="plain-text">You sent</h5>
                        <p className="plain-text">
                          {`${this.state.sendAmount} ${this.state.sendAsset}`}
                        </p>
                      </div>
                      <div className="sendBox">
                        <h5 className="plain-text">You received:</h5>
                        <p className="plain-text">
                          {`${this.state.receiveAmount} ${this.state.receiveAsset}`}
                        </p>
                      </div>
                      <div className="sendBox">
                        <h5 className="plain-text">
                          To the following address:
                        </h5>
                        <p
                          className="plain-text"
                          style={{ wordWrap: "break-word" }}
                        >
                          {this.state.receiveAddress}
                        </p>
                      </div>

                      <div className="sendBox">
                        {" "}
                        {/* Added Transaction ID Section */}
                        <h5 className="plain-text">Transaction ID:</h5>
                        <p
                          className="plain-text"
                          style={{ wordWrap: "break-word" }}
                        >
                          {this.state.transId}
                          <button
                            onClick={this.handleCopy}
                            className="btn btn-primary "
                            title={
                              this.state.isCopied
                                ? "Copied!"
                                : "Copy to clipboard"
                            } // Tooltip message
                            style={{
                              backgroundColor: "transparent", // Transparent background
                              border: "none", // No border
                              marginLeft: "10px",
                              padding: "0",
                              cursor: "pointer",
                            }}
                          >
                            <i
                              className={`bi ${
                                this.state.isCopied
                                  ? "bi bi-clipboard2-check-fill"
                                  : "bi bi-copy"
                              }`}
                              style={{
                                fontSize: "1.2rem",
                                color: iconColor,
                              }}
                            ></i>
                            {/* Conditional icon based on copied status */}
                          </button>
                        </p>
                      </div>

                      {/* <div className="sendBox">
                      <h5 className='plain-text'>Final Exchange rate :</h5>
                      <p className='plain-text'>
                        {`${this.state.sendAmount} ${this.state.sendAsset}`}
                        =
                        {`${this.state.receiveAmount} ${this.state.receiveAsset}`}
                      </p>
                    </div> */}
                      <div className="sendBox"></div>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default withParams(Receipt);
