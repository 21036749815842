import Layout from "../components/Layout";
import React from "react";
import { Switch, Route, useLocation } from "react-router-dom";
import Exchange from "./exchange";
import Dashboard from "./dashboard";
import Markets from "./markets";
import Wallet from "./wallet";
import Login from "./signin";
import MfaVerifyNewUser from "./mfaverifynewuser";
import VerifyMFA from "./verifyMFA";
import Reset from "./reset";
import OtpVerify from "./otp-verify";
import OtpNumber from "./otp-number";
import Lock from "./lock";
import TermsAndConditions from "./terms-and-conditions";
import NewsDetails from "./news-details";
import Signup from "./signup";
import Notfound from "./notfound";
import Home from "./home";
// import Balance from "./balance";
// import Swap from "./swap";
import Swap from "../components/swap/Swap";
import Send from "../components/swap/Deposit";
import Receipt from "../components/swap/Receipt";
import Profile from "./profile";
import Settings from "./settings";
import Authentication from "./authentication";
import Referral from "./referral";
import Verification from "./verification";
import API from "./api";
import Privacy from "./privacy";
// import FAQ from "./faq";
import LayoutLanding from "../components/landing/Layout";
import HelpDesk from "../components/landing/HelpDesk";
import HelpFAQ from "../components/landing/HelpFAQ";
import Contact from "../components/landing/Contact";
import Company from "../components/landing/Company";
import Software from "../components/landing/Software";
import Fees from "../components/landing/Company/Fees";
import ForgotPassword from "./ForgotPassword";
import RedirectHome from "../components/landing/RedirectHome";
import RedirectSignup from "../components/landing/Login";
import Register from "../components/landing/Register";
import PendingOrder from "./admin/pendingOrder";

export default function Index() {
  const location = useLocation();
  const currentPath = location.pathname.slice(1);
  const [searchValue, setSearchValue] = React.useState("");
  const paths = [
    "help",
    "faq",
    "contact",
    "about",
    "security",
    "fees",
    "choose",
    "swap",
    "swap/choose",
    "contact-2",
    "decentralized-exchange",
    "DASHUSDT",
    "pro-exchange",
    "login",
    "register",
    "support",
  ];
  const handleSearch = (value) => {
    setSearchValue(value);
    // You can perform any further action with the search value here
  };
  return (
    <>
      {paths.includes(currentPath) ? (
        <LayoutLanding>
          <Switch>
            <Route path="/help">
              <HelpDesk />
            </Route>
            <Route path="/contact">
              <Contact />
            </Route>
            <Route path="/about">
              <Company />
            </Route>
            <Route path="/security">
              <Software />
            </Route>
            <Route path="/fees">
              <Fees />
            </Route>
            <Route path="/faq">
              <HelpFAQ />
            </Route>
            <Route path="/choose">
              <RedirectHome />
            </Route>
            <Route path="/swap">
              <Swap />
            </Route>
            <Route path="/swap/choose">
              <RedirectHome />
            </Route>
            <Route path="/contact-2">
              <RedirectHome />
            </Route>
            <Route path="/decentralized-exchange">
              <RedirectHome />
            </Route>
            <Route path="/DASHUSDT">
              <RedirectHome />
            </Route>
            <Route path="/pro-exchange">
              <RedirectHome />
            </Route>
            <Route path="/support">
              <HelpDesk />
            </Route>
            <Route path="/login">
              <RedirectSignup />
            </Route>
            <Route path="/admin/login">
              <RedirectSignup />
            </Route>
            <Route path="/register">
              <Register />
            </Route>
          </Switch>
        </LayoutLanding>
      ) : (
        <Layout onSearch={handleSearch}>
          <Switch>
            <Route exact path="/">
              <Home search={searchValue} />
            </Route>
            <Route path="/dashboard">
              <Exchange search={searchValue} />
            </Route>
            <Route path="/partner-dashboard">
              <Dashboard />
            </Route>
            <Route path="/markets">
              <Markets />
            </Route>
            <Route path="/wallet">
              <Wallet />
            </Route>
            <Route path="/swap/:coin_send/:coin_receive/:amount_send">
              <Swap />
            </Route>
            <Route path="/send/:order_id">
              <Send />
            </Route>
            <Route path="/receipt/:order_id">
              <Receipt />
            </Route>
            <Route path="/profile">
              <Profile />
            </Route>
            <Route path="/settings">
              <Settings />
            </Route>
            <Route path="/authentication">
              <Authentication />
            </Route>
            <Route path="/referral">
              <Referral />
            </Route>
            <Route path="/verification">
              <Verification />
            </Route>
            <Route path="/api">
              <API />
            </Route>
            <Route path="/mfa-verify-new-user">
              <MfaVerifyNewUser />
            </Route>
            <Route path="/admin/mfa-verify-new-user">
              <MfaVerifyNewUser />
            </Route>
            <Route path="/signin">
              <Login />
            </Route>
            <Route path="/admin/signin">
              <Login />
            </Route>
            <Route path="/forgot-password">
              <ForgotPassword />
            </Route>
            <Route path="/verify2fa">
              <VerifyMFA />
            </Route>
            <Route path="/admin/verify2fa">
              <VerifyMFA />
            </Route>
            <Route path="/signup">
              <Signup />
            </Route>
            <Route path="/reset">
              <Reset />
            </Route>
            <Route path="/admin/reset">
              <Reset />
            </Route>
            <Route path="/otp-verify">
              <OtpVerify />
            </Route>
            <Route path="/otp-number">
              <OtpNumber />
            </Route>
            <Route path="/lock">
              <Lock />
            </Route>
            <Route path="/privacy-policy">
              <Privacy />
            </Route>
            <Route path="/terms-and-conditions">
              <TermsAndConditions />
            </Route>
            <Route path="/news-details">
              <NewsDetails />
            </Route>
            <Route path="/admin/pending-order">
              <PendingOrder />
            </Route>
            <Route path="*">
              <Notfound />
            </Route>
          </Switch>
        </Layout>
      )}
    </>
  );
}
