/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from "react";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import { authApi } from "../services/AuthService";
import Cookies from "js-cookie";

export default function mfaverifynewuser() {
  const history = useHistory();
  const location = useLocation();
  const [code, setCode] = useState("");
  const [codeError, setCodeError] = useState("");
  const [error, setError] = useState("");
  const [isLoader, setIsLoader] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const isAdmin = location.pathname.includes("/admin");
  const isPwa = window.matchMedia("(display-mode: standalone)");

  useEffect(() => {
    if (localStorage.getItem("token")) {
      setRedirect(true);
    }
  }, []);

  const handleCode = async (e) => {
    if (!isNaN(e.target.value)) {
      await setCode(e.target.value);
      await setCodeError("");
    } else {
      await setCode("");
      await setCodeError("Please enter numeric value !");
    }
  };

  const handleEnter = async (e) => {
    if (e.key === "Enter") {
      handleSubmit();
    }
  };

  const handleSubmit = async () => {
    await setIsLoader(true);
    if (code === "") {
      await setIsLoader(false);
      await setCodeError("Code is required !");
    } else {
      const data = {
        email: Cookies.get("email"),
        code: code,
        session: Cookies.get("data"),
      };
      const mfaverify = await authApi.getmfaverification(data);
      if (mfaverify.status === 200) {
        if (mfaverify.data.status === 400 && mfaverify.data.success === false) {
          if (
            mfaverify.data.errors[0].msg ===
            "Invalid session for the user, session is expired."
          ) {
            await Cookies.remove("data");
            await Cookies.remove("email");
            history.replace({
              pathname: "/signin",
              state: {
                message: "Session is expired, Please signin again!",
              },
            });
          }
          if (mfaverify.data.errors[0].msg === "Incorrect 2FA code") {
            await setIsLoader(false);
            await setError(mfaverify.data.errors[0].msg);
            await setCode("");
          }
        }
        if (mfaverify.data.AuthenticationResult) {
          // localStorage.setItem('token',mfaverify.data.AuthenticationResult.AccessToken);
          // localStorage.setItem('reftoken',mfaverify.data.AuthenticationResult.RefreshToken);
          // localStorage.setItem('cookie',mfaverify.data.cookie);
          // const host = window.location.host.split(".");
          // const domain = "." + host.slice(host.length - 2).join(".");
          // document.cookie = `token=${mfaverify.data.AuthenticationResult.AccessToken};secure; samesite=strict;domain=${domain};expires=Thu, 18 Dec 2033 12:00:00 UTC`;
          // document.cookie = `${mfaverify.data.cookie.replace(
          //   "; samesite=strict; httponly",
          // )};domain=${domain};expires=Thu, 18 Dec 2033 12:00:00 UTC`;
          // document.cookie = `reftoken=${mfaverify.data.AuthenticationResult.RefreshToken};secure; samesite=strict;domain=${domain};expires=Thu, 18 Dec 2033 12:00:00 UTC`;
          // // localStorage.setItem('cookie',mfaverify.data.cookie);
          Cookies.remove("data");
          Cookies.remove("email");
          await setIsLoader(false);
          await setRedirect(true);
        }
        if (mfaverify.data.status === 400 && mfaverify.data.name === "Error") {
          setIsLoader(false);
          setError("Please enter a new 2FA code");
          setCode("");
        }
        console.log(document.cookie.split(";"));
      } else {
        setIsLoader(false);
        setError("Invalid 2FA code");
        setCode("");
      }
    }
  };
  return (
    <>
      {isLoader ? (
        <div className="coin-loader-wrap" style={{ zIndex: "999999" }}>
          <div className="coin-loader">
            <span className="coin-loader-img">
              <img src={process.env.REACT_APP_LOADERS} alt="loader" />
            </span>
          </div>
        </div>
      ) : (
        ""
      )}
      {isAdmin && redirect ? (
        <Redirect to="/admin/pending-order" />
      ) : redirect ? (
        <Redirect to="/" />
      ) : null}
      {isPwa.matches ? (
        <section className="verificationWrap-pwa">
          <div className="verificationBox">
            <h3>Two-Factor Authentication (2FA)</h3>
            <h5>Enter the 6-digit code from your 2FA App</h5>
            {error !== "" ? <p className="errorMsg">{error}</p> : ""}
            <div className="vfcBox">
              <input
                type="text"
                name="code"
                id="code"
                value={code}
                className="vfcBoxInput"
                placeholder="Code from your 2FA App"
                onChange={handleCode}
                maxLength="6"
                onKeyPress={handleEnter}
              />
              {codeError !== "" ? <p className="errorMsg">{codeError}</p> : ""}
              <button className="vfcBtn" onClick={handleSubmit}>
                SUBMIT
              </button>
            </div>
          </div>
        </section>
      ) : (
        <section className="verificationWrap">
          <div className="verificationBox">
            <h3>Two-Factor Authentication (2FA)</h3>
            <h5>Enter the 6-digit code from your 2FA App</h5>
            {error !== "" ? <p className="errorMsg">{error}</p> : ""}
            <div className="vfcBox">
              <input
                type="text"
                name="code"
                id="code"
                value={code}
                className="vfcBoxInput"
                placeholder="Code from your 2FA App"
                onChange={handleCode}
                maxLength="6"
                onKeyPress={handleEnter}
              />
              {codeError !== "" ? <p className="errorMsg">{codeError}</p> : ""}
              <button className="vfcBtn" onClick={handleSubmit}>
                SUBMIT
              </button>
            </div>
          </div>
        </section>
      )}
    </>
  );
}
