export const PRIVACY = [
  {
    id: 0,
    title: "Swaptrade Privacy Policy",
    contents: [
      {
        id: 0,
        details:
          "Fintex LLC (Saint Vincent and the Grenadines), UserChoice, Inc. (Delaware, USA), and CS TRADING SERVICES PTY LTD. (NSW, Australia) (collectively referred to as “swaptrade,” “we,” “us” or “our”) operate the Swaptrade Cryptocurrency Exchange accessible via our site located at https://www.swaptrade.io and our mobile application Swaptrade. This Privacy Notice describes how Swaptrade gathers and processes your personal data through the Swaptrade websites and applications that reference this Privacy Notice.",
      },
      {
        id: 1,
        details:
          "Depending on your location, the appropriate company will be used as your local operating entity. For users located in the USA, UserChoice, Inc. will be their local operating entity. For users located in Australia, CS TRADING SERVICES PTY LTD. will be their local operating entity. For users located anywhere else, Fintex LLC will be their local operating entity.",
      },
      {
        id: 2,
        details:
          "This Privacy Policy applies to personal data processed by us in our business, including on our websites, mobile applications, and other online or offline offerings (collectively, the “Services”). This Policy applies to our operating divisions, subsidiaries, affiliates, and branches.",
      },
    ],
  },
  {
    id: 1,
    title: "1. PERSONAL INFORMATION WE MAY REQUEST",
    contents: [
      {
        id: 0,
        details:
          "We have tiers of information we request depending on whether you are a customer, user, applicant or visitor, and the requirements of applicable law.",
      },
      {
        id: 1,
        details:
          "To create an account with us and use our Services, we may gather the following information from you:",
        lists: [
          "Email address;",
          "First and Last name;",
          "Gender;",
          "Date of birth;",
          "Home address;",
          "Phone number;",
          "Nationality;",
          "Government Identification Number (e.g. tax identification, driver’s license number and passport number)",
          "Device ID;",
          "Transaction information (e.g. information about the transactions you make using our Services, such as the name of the recipient and the trading amount);",
          "Login, e-mail address, password and location of your device or computer;",
          "Swaptrade Services metrics (e.g., the occurrences of technical errors, your interactions with service features and content, and your settings preferences);",
          "Information about your behavior: we may process information about you on your behavior and your activity for marketing and advertising purposes;",
          "A video recording for identification purposes; ",
          "Any other information necessary to verify your identity to comply with our regulatory obligations under financial or anti-money laundering laws.",
        ],
      },
    ],
  },
  {
    id: 2,
    title: "2. WHY WE REQUEST THIS INFORMATION",
    contents: [
      {
        id: 0,
        htmlContent: true,
        details:
          "<i>Transaction services:</i> We use your personal information to process your orders, and to correspond with you about orders and services.",
      },
      {
        id: 1,
        htmlContent: true,
        details:
          "<i>Communication:</i> We use your personal information to correspond with you when you request information about our Services, request customer or technical support, apply for a job or otherwise correspond with us.",
      },
      {
        id: 2,
        htmlContent: true,
        details:
          "<i>Surveys:</i> We may contact you to participate in surveys. If you decide to participate, you may be asked to provide certain information which may include personal information.",
      },
      {
        id: 3,
        htmlContent: true,
        details:
          "<i>Know Your Customer:</i> We may gather and process identity information and Personal Data to comply with our Know Your Customer (“KYC”) obligations under applicable laws and regulations, and Anti-Money Laundering laws and regulations.",
      },
      {
        id: 4,
        htmlContent: true,
        details:
          "<i>Marketing:</i> We may use your personal information to send you marketing communications by email or other agreed forms (including social media campaigns), to ensure you are always kept up-to-date with our latest products and services. If we send you marketing communications we will do so based on your consent and registered marketing preferences.",
      },
      {
        id: 5,
        htmlContent: true,
        details:
          "<i>Service Improvement:</i> We may gather data in order to provide, troubleshoot, and improve Swaptrade Services. We use your personal information to provide functionality, analyse performance, fix errors, and improve the usability and effectiveness of Swaptrade Services.",
      },
      {
        id: 6,
        htmlContent: true,
        details:
          "<i>Fraud prevention and credit risks:</i> We process personal information to prevent and detect fraud and abuse in order to protect the security of our users, Swaptrade Services and others. We may also use scoring methods to assess and manage credit risks.",
      },
      {
        id: 7,
        htmlContent: true,
        details:
          "<i>Recommendations and personalisation:</i> We use your personal information to recommend features and services that might be of interest to you, identify your preferences, and personalise your experience with Swaptrade Services. We will generally ask for your consent to process your personal information for a specific purpose.",
      },
    ],
  },
  {
    id: 3,
    title: "3. AUTOMATICALLY COLLECTED PERSONAL DATA",
    contents: [
      {
        id: 0,
        details:
          "We may gather certain personal data automatically when you use the Services. This data may include your Internet protocol (IP) address, user settings, MAC address, cookie identifiers, mobile carrier, mobile advertising and other unique identifiers, details about your browser, operating system or device, location information, Internet service provider, pages that you visit before, during and after using the Services, information about the links you click, and other information about how you use the Services. Information we gather may be associated with accounts and other devices.",
      },
      {
        id: 1,
        details:
          "In addition, we may automatically gather data regarding your use of our Services (e.g. the types of content you interact with and the frequency and duration of your activities). We may combine your data with information that other uses provide when they utilize our Services, including information about you when they tag you.",
      },
      {
        id: 2,
        details:
          "We utilize cookies and similar tools to enhance your user experience, provide our services, and understand how customers use our services so we can make improvements. The cookie banner on your browser will tell you how to accept or refuse cookies.",
      },
    ],
  },
  {
    id: 4,
    title: "4. PROTECTION OF YOUR PERSONAL DATA",
    contents: [
      {
        id: 0,
        details:
          "We take steps to ensure that your information is treated securely and in accordance with this Privacy Policy. We work to protect the security of your personal information during transmission by using encryption protocols and software.",
      },
      {
        id: 1,
        details:
          "We strive to maintain the highest level of security to protect your information. While no system can be guaranteed to be 100% secure, we implement robust measures, including encryption at rest and in transit, regular audits of our code and overall architecture, and industry best practices to safeguard your personal information. Our commitment to your privacy and security means that we continuously strive to enhance our protection mechanisms and minimize any risks.",
      },
    ],
  },
  {
    id: 5,
    title: "5. THIRD PARTY INVOLVEMENT",
    contents: [
      {
        id: 0,
        details:
          "We may use third-party tools to monitor and analyze the use of our Services, and to automate certain processes related to the development and operation of our Services. Swaptrade will endeavour to make such disclosures to third parties on a “need-to-know” basis, unless otherwise instructed by a regulatory authority. Under such circumstances, we will notify the third party regarding the confidential nature of any such information.",
      },
      {
        id: 1,
        details:
          "Examples of third parties we may disclose your information to include:",
        htmlContentList: true,
        lists: [
          "<i>Affiliates:</i> We may share personal information internally within Swaptrade and affiliated companies.",
          "<i>Protection providers:</i> We may access, preserve, and disclose any information we store associated with you to external parties if we, in good faith, believe doing so is required or appropriate to: comply with law enforcement or national security requests and legal process, such as a court order or subpoena; protect your, our or others’ rights, property, or safety; enforce our policies or contracts; gather amounts owed to us; or assist with an investigation or prosecution of suspected or actual illegal activity",
        ],
      },
    ],
  },
  {
    id: 6,
    title: "6. INTERNATIONAL DATA TRANSFERS",
    contents: [
      {
        id: 0,
        details:
          "Swaptrade may transfer your personal information to countries other than the one in which you live. We deploy appropriate safeguards to ensure that your information remains protected, regardless of the country to which it is transferred. This may include the use of approved standard contractual clauses, intragroup agreements, or other mechanisms that provide an adequate level of protection under applicable data protection laws.",
      },
    ],
  },
  {
    id: 7,
    title: "7. YOUR RIGHTS AND CHOICES",
    contents: [
      {
        id: 0,
        details:
          "You have certain rights regarding the personal information we hold about you, subject to local data protection laws. These may include the rights to access, correct, delete, restrict or object to our use of, or receive a portable copy in a usable electronic format of your personal information. You also have the right to withdraw any consent you have previously provided.",
      },
      {
        id: 1,
        details:
          "To exercise these rights, please contact us using the contact information provided below. We will respond to your request as required by applicable law.",
      },
    ],
  },
  {
    id: 8,
    title: "8. RETENTION OF YOUR PERSONAL DATA",
    contents: [
      {
        id: 0,
        details:
          "We will retain your personal information for the period necessary to fulfill the purposes outlined in this Privacy Policy, unless a longer retention period is required or permitted by law. The criteria used to determine our retention periods include the length of time we have an ongoing relationship with you and provide the Services to you, whether there is a legal obligation to which we are subject, or whether retention is advisable in light of our legal position (such as in regard to applicable statutes of limitations, litigation, or regulatory investigations).",
      },
    ],
  },
  {
    id: 9,
    title: "9. CHILDREN’S ACCESS TO swaptrade",
    contents: [
      {
        id: 0,
        details:
          'Our offerings are not intended for individuals under the age of 18, referred to hereafter as "Minors." We do not knowingly collect personal information from Minors. If it comes to our attention that we have inadvertently gathered personal information from a Minor, we will take all legally permissible steps to remove that information from our records. In such cases, we will require the user to close their account and prohibit the use of our products and services. If you are a parent or guardian of a Minor and discover that they have provided personal information to us, please contact us at support@swaptrade.io. You may then request to exercise your applicable rights to access, rectify, delete, and/or object to the collected information.',
      },
    ],
  },
  {
    id: 10,
    title: "10. CHANGES TO THIS PRIVACY POLICY",
    contents: [
      {
        id: 0,
        details:
          "We may update this Privacy Policy from time to time to reflect changes in our practices, technologies, legal requirements, or other factors. When we do, we will update the 'last modified' date at the top of this policy. If we make material changes to the Privacy Policy, we may notify you more prominently, such as by posting a notice on our website or by sending you an email. We encourage you to periodically review this Privacy Policy to stay informed about our collection, processing, and sharing of your personal information.",
      },
    ],
  },
  {
    id: 11,
    title: "11. CONTACT US",
    contents: [
      {
        id: 0,
        details:
          "If you have any questions or concerns about this Privacy Policy or our privacy practices, or if you would like to exercise your rights and choices, please contact the appropriate company based on your location:",
      },
      {
        id: 1,
        htmlContent: true,
        details: "\n<b>For users located in the USA:</b>",
      },
      {
        id: 2,
        htmlContent: true,
        details: "<i>UserChoice, Inc.</i>",
      },
      {
        id: 3,
        htmlContent: true,
        details: "<i>1259 El Camino Real, Unit #1200</i>",
      },
      {
        id: 4,
        htmlContent: true,
        details: "<i>Menlo Park, CA 94025</i>",
      },
      {
        id: 5,
        htmlContent: true,
        details: "<i>support@swaptrade.io</i>",
      },
      {
        id: 6,
        htmlContent: true,
        details: "\n<b>For users located in Australia:</b>",
      },
      {
        id: 7,
        htmlContent: true,
        details: "<i>CS TRADING SERVICES PTY LTD.</i>",
      },
      {
        id: 8,
        htmlContent: true,
        details: "<i>64 York St</i>",
      },
      {
        id: 9,
        htmlContent: true,
        details: "<i>Sydney NSW 2000</i>",
      },
      {
        id: 10,
        htmlContent: true,
        details: "<i>support@swaptrade.io</i>",
      },
      {
        id: 11,
        htmlContent: true,
        details: "\n<b>For users located in other countries:</b>",
      },
      {
        id: 12,
        htmlContent: true,
        details: "<i>Fintex LLC</i>",
      },
      {
        id: 13,
        htmlContent: true,
        details: "<i>Suite 305, Griffith Corporate Centre</i>",
      },
      {
        id: 14,
        htmlContent: true,
        details: "<i>Beachmont, Kingstown, St. Vincent and the Grenadines</i>",
      },
      {
        id: 15,
        htmlContent: true,
        details: "<i>support@swaptrade.io</i>",
      },
    ],
  },
  {
    id: 12,
    title: "12. ACKNOWLEDGEMENT",
    contents: [
      {
        id: 0,
        details:
          "By using our Services, you acknowledge that you have read, understood, and agreed to be bound by this Privacy Policy.",
      },
    ],
  },
];
