/* eslint-disable react/style-prop-object */
/* eslint-disable linebreak-style */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/no-unused-state */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable global-require */
/* eslint-disable import/no-unresolved */
import React from "react";
import { Nav, InputGroup, Dropdown, DropdownButton } from "react-bootstrap";
import { useParams, Redirect } from "react-router-dom";
import { swapApi } from "../../services/SwapService";
import placeaHolderIcon from "../../assets/images/editor-placeholder.jpg";
function withParams(Comp) {
  // eslint-disable-next-line func-names
  return function (props) {
    // eslint-disable-next-line react/jsx-filename-extension
    return <Comp {...props} params={useParams()} />;
  };
}
class Swap extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      assets: [],
      yousend: "",
      isSwap: false,
      isShowYouSendAsset: false,
      showSubAssetYouSend: false,
      youSendSubAssets: [],
      youSendSubCoin: "",
      youSendSubCoinName: "",
      youSendCoinImage: "",
      youget: "",
      isShowYouGetAsset: false,
      showSubAssetYouGet: false,
      youGetSubAssets: [],
      youGetSubCoin: "",
      youGetSubCoinName: "",
      youGetSubCoinTrezorCode: "",
      youGetCoinImage: "",
      yousendamount: 0.01,
      yougetamount: "",
      realPrice: "",
      realPriceView: "",
      realPriceFrom: "",
      realPriceTo: "",
      showConfirm: false,

      walletAddress: "",
      walletAddressError: false,
      walletAddressErrorMsg: "",

      refundAddress: "",
      refundAddressError: false,
      refundAddressErrorMsg: "fgdss",

      iAgree: false,
      redirect: false,
      orderId: "",
      min: "",
      max: "",
      minerror: false,
      maxerror: false,
      minerrormsg: "",
      maxerrormsg: "",
      showConfirmButton: false,
      networkFees: 0,
      isLoader: false,
      errors: false,
      errorOrderMsg: "",
      searchTerm: "",
      activeTab: "dynamic",
      sendSelectedOption: "",
      getSelectedOption: "",

      options: [
        { id: 1, label: "User", icon: "" },
        { id: 2, label: "Settings", icon: "" },
        { id: 3, label: "Logout", icon: "" },
      ],
    };
  }

  const;

  handleTabSwitch = (tab) => {
    this.setState({ activeTab: tab });
  };

  handleSendSelect = (option) => {
    this.setState({ sendSelectedOption: option });
  };
  handleGetSelect = (option) => {
    this.setState({ getSelectedOption: option });
  };

  async componentDidMount() {
    await this.setState({ yousendamount: this.props.params.amount_send });
    await this.getSupportedAssets();
  }

  getSupportedAssets = async () => {
    const supportedAssets = await swapApi.getSupportedAssets();
    if (supportedAssets.status === 200) {
      if (
        this.props?.params?.coin_send &&
        this.props?.params?.coin_send?.includes("&")
      ) {
        const split_send_coin = this.props.params.coin_send.split("&");

        await this.setState({ yousend: split_send_coin[0] });
        await this.setState({
          youSendCoinImage: `${process.env.REACT_APP_COIN_IMAGE_URL}${split_send_coin[0]}.png`,
        });
        this.handleSendSelect(split_send_coin[0]);
        const getsubAssets = await swapApi.getSubAssets(split_send_coin[0]);

        if (
          getsubAssets.status === 200 &&
          getsubAssets.data.status === 200 &&
          getsubAssets.data.success === true
        ) {
          await this.setState({ youSendSubAssets: getsubAssets.data.data });
          await this.setState({ isShowYouSendAsset: true });
          const getnetworkAsset = this.state.youSendSubAssets.find(
            (item) => item.fbCode === split_send_coin[1]
          );
          await this.setState({ youSendSubCoin: getnetworkAsset.fbCode });
          await this.setState({ youSendSubCoinName: getnetworkAsset.name });
        }
      } else {
        await this.setState({ yousend: this.props.params.coin_send });
        await this.setState({
          youSendCoinImage: `${process.env.REACT_APP_COIN_IMAGE_URL}${this.props.params.coin_send}.png`,
        });
        this.handleSendSelect(this.props.params.coin_send);
      }
      await this.setState({ assets: supportedAssets.data.data });
      if (
        this.props?.params?.coin_receive &&
        this.props.params.coin_receive.includes("&")
      ) {
        const split_get_coin = this.props.params.coin_receive.split("&");

        await this.setState({ youget: split_get_coin[0] });
        await this.setState({
          youGetCoinImage: `${process.env.REACT_APP_COIN_IMAGE_URL}${split_get_coin[0]}.png`,
        });

        this.handleGetSelect(split_get_coin[0]);

        const getsubAssets = await swapApi.getSubAssets(split_get_coin[0]);

        if (
          getsubAssets.status === 200 &&
          getsubAssets.data.status === 200 &&
          getsubAssets.data.success === true
        ) {
          await this.setState({ youGetSubAssets: getsubAssets.data.data });
          await this.setState({ isShowYouGetAsset: true });
          const getnetworkAsset = this.state.youGetSubAssets.find(
            (item) => item.fbCode === split_get_coin[1]
          );
          await this.setState({ youGetSubCoin: getnetworkAsset.fbCode });
          await this.setState({ youGetSubCoinName: getnetworkAsset.name });
          await this.setState({
            youGetSubCoinTrezorCode: getnetworkAsset.name,
          });
          await this.setState({ youGetSubCoinName: getnetworkAsset.name });
          await this.setState({
            youGetSubCoinTrezorCode: getnetworkAsset.name,
          });
        }
      } else {
        await this.setState({ youget: this.props.params.coin_receive });
        await this.setState({
          youGetCoinImage: `${process.env.REACT_APP_COIN_IMAGE_URL}${this.props.params.coin_receive}.png`,
        });
        this.handleGetSelect(this.props.params.coin_receive);
      }
      // await this.setState({ youget: this.props.params.coin_receive });
      // await this.setState({ youGetCoinImage: `${process.env.REACT_APP_COIN_IMAGE_URL}${this.props.params.coin_receive}.png` });
      this.getCoinRealPrice();
    }
  };

  handleYouSend = async (e) => {
    const getsubAssets = await swapApi.getSubAssets(e.target.value);
    if (
      getsubAssets.status === 200 &&
      getsubAssets.data.status === 200 &&
      getsubAssets.data.success === true
    ) {
      await this.setState({ yousend: e.target.value });
      await this.setState({
        youSendCoinImage: `${process.env.REACT_APP_COIN_IMAGE_URL}${e.target.value}.png`,
      });
      await this.setState({ youSendSubAssets: getsubAssets.data.data });

      await this.setState({ isShowYouSendAsset: true });
      await this.setState({ youSendSubCoin: getsubAssets.data.data[0].fbCode });
      await this.setState({
        youSendSubCoinName: getsubAssets.data.data[0].name,
      });
    } else {
      await this.setState({ yousend: e.target.value });
      await this.setState({
        youSendCoinImage: `${process.env.REACT_APP_COIN_IMAGE_URL}${e.target.value}.png`,
      });
      await this.setState({ youSendSubAssets: [] });
      await this.setState({ isShowYouSendAsset: false });
      await this.setState({ youSendSubCoin: "" });
      await this.setState({ youSendSubCoinName: "" });
    }
    await this.handleSendSelect(e.target.value);
    this.getCoinRealPrice();
  };

  handleYouGet = async (e) => {
    const getsubAssets = await swapApi.getSubAssets(e.target.value);
    if (
      getsubAssets.status === 200 &&
      getsubAssets.data.status === 200 &&
      getsubAssets.data.success === true
    ) {
      await this.setState({ youget: e.target.value });
      await this.setState({
        youGetCoinImage: `${process.env.REACT_APP_COIN_IMAGE_URL}${e.target.value}.png`,
      });
      await this.setState({ youGetSubAssets: getsubAssets.data.data });
      this.setState({ isShowYouGetAsset: true });
      await this.setState({ youGetSubCoin: getsubAssets.data.data[0].fbCode });
      await this.setState({
        youGetSubCoinName: getsubAssets.data.data[0].name,
      });
      await this.setState({
        youGetSubCoinTrezorCode: getsubAssets.data.data[0].name,
      });
      await this.setState({
        youGetSubCoinName: getsubAssets.data.data[0].name,
      });
      await this.setState({
        youGetSubCoinTrezorCode: getsubAssets.data.data[0].name,
      });
    } else {
      await this.setState({ youget: e.target.value });
      await this.setState({
        youGetCoinImage: `${process.env.REACT_APP_COIN_IMAGE_URL}${e.target.value}.png`,
      });
      await this.setState({ youGetSubAssets: [] });
      await this.setState({ isShowYouGetAsset: false });
      await this.setState({ youGetSubCoin: "" });
      await this.setState({ youGetSubCoinName: "" });
      await this.setState({ youGetSubCoinTrezorCode: "" });
    }
    await this.handleGetSelect(e.target.value);

    this.getCoinRealPrice();
    if (this.state.walletAddress !== "") {
      const data = {
        walletId: this.state.walletAddress,
        asset:
          this.state.youGetSubCoinTrezorCode !== ""
            ? this.state.youGetSubCoinTrezorCode
            : this.state.youget,
      };
      this.checkValidateWallet(data);
    } else {
      this.setState({ walletAddressError: false });
      this.setState({ walletAddressErrorMsg: "" });
    }
  };

  getCoinRealPrice = async () => {
    if (this.state.yousend !== "" && this.state.youget !== "") {
      const data = {
        coinfrom: this.state.yousend,
        cointo: this.state.youget,
      };
      const coinRealPrice = await swapApi.getCoinRealPrice(data);
      if (coinRealPrice.status === 200) {
        this.setState({ realPrice: parseFloat(coinRealPrice.data.data.price) });
        this.setState({
          realPriceView: `1 ${this.state.yousend} ~ ${parseFloat(
            coinRealPrice.data.data.price
          )} ${this.state.youget}`,
        });
        this.setState({ realPriceFrom: 1 });
        this.setState({ realPriceTo: this.state.youget });
        const price =
          Math.floor(
            this.state.yousendamount * this.state.realPrice * 100000000
          ) / 100000000;
        this.setState({ yougetamount: price.toFixed(8) });
        await this.getAssetsLimit();
      }
    }
  };

  getAssetsLimit = async () => {
    if (this.state.yousend !== "" && this.state.youget !== "") {
      this.state.assets.forEach(async (element) => {
        if (element.id === this.state.yousend) {
          await this.setState({
            min: Math.ceil(element.min * 100000) / 100000,
          });
        }
        if (element.id === this.state.youget) {
          await this.setState({
            max: Math.floor(element.max * 100000) / 100000,
          });
        }
      });
      if (
        this.state.yousendamount === "" ||
        this.state.yousendamount < this.state.min
      ) {
        await this.setState({
          minerrormsg: `Minimum send amount: ${this.state.min} ${this.state.yousend}`,
        });
        await this.setState({ minerror: true });
      } else {
        await this.setState({ minerrormsg: "" });
        await this.setState({ minerror: false });
      }

      if (
        this.state.yougetamount !== "" &&
        this.state.yougetamount > this.state.max
      ) {
        await this.setState({
          maxerrormsg: `Maximum receive amount: ${this.state.max} ${this.state.youget}`,
        });
        await this.setState({ maxerror: true });
      } else {
        await this.setState({ maxerrormsg: "" });
        await this.setState({ maxerror: false });
      }
    }
  };

  handlePrice = async (e) => {
    await this.setState({ yousendamount: e.target.value });
    const price =
      Math.floor(this.state.yousendamount * this.state.realPrice * 100000000) /
      100000000;
    this.setState({ yougetamount: price.toFixed(8) });

    if (
      this.state.yousendamount === "" ||
      this.state.yousendamount < this.state.min
    ) {
      await this.setState({ minerror: true });
      await this.setState({
        minerrormsg: `Minimum send amount: ${this.state.min} ${this.state.yousend}`,
      });
      await this.setState({ errors: true });
    } else {
      await this.setState({ minerrormsg: "" });
      await this.setState({ minerror: false });
      await this.setState({ errors: false });
    }

    if (
      this.state.yougetamount !== "" &&
      this.state.yougetamount > this.state.max
    ) {
      await this.setState({
        maxerrormsg: `Maximum receive amount: ${this.state.max} ${this.state.youget}`,
      });
      await this.setState({ maxerror: true });
    } else {
      await this.setState({ maxerrormsg: "" });
      await this.setState({ maxerror: false });
    }
  };

  /* **** Confirm Button  **** */

  placeConfirm = async () => {
    if (!this.state.isSwap) {
      this.setState({ isSwap: true });
    } else {
      if (
        this.state.yousend !== "" &&
        this.state.walletAddress !== "" &&
        this.refundAddress !== "" &&
        this.state.youget !== "" &&
        this.state.minerror === false &&
        this.state.maxerror === false &&
        this.state.walletAddressError === false &&
        this.state.refundAddressError === false
      ) {
        const getNetworkFees = await swapApi.getSupportedAssets();

        if (
          getNetworkFees.data.status === 200 &&
          getNetworkFees.data.success === true
        ) {
          getNetworkFees.data.data.map(async (element) => {
            if (element.id === this.state.youget) {
              await this.setState({ networkFees: element.network_fee });
            }
          });
        }
        this.setState({ showConfirm: true });
      } else {
        // eslint-disable-next-line no-alert
        alert("Please fulfill all criteria !");
      }
    }
  };
  /* **** Check Validate WalletAddress **** */

  checkValidateWallet = async (data, isRefundAddress = false) => {
    // Add refund address to the data object
    const checkValidWallet = await swapApi.checkValidWalletAddress(data);
    if (checkValidWallet.status === 200) {
      if (checkValidWallet.data.status === 404) {
        if (isRefundAddress) {
          this.setState({
            refundAddressError: true,
            refundAddressErrorMsg: "Please enter a valid refund address",
            showConfirmButton: false,
            errors: true,
          });
        } else {
          this.setState({
            walletAddressError: true,
            walletAddressErrorMsg: "Please enter a valid wallet address",
            showConfirmButton: false,
            errors: true,
          });
        }
      } else {
        if (isRefundAddress) {
          this.setState({
            refundAddressError: false,
            refundAddressErrorMsg: "",
          });
        } else {
          this.setState({
            walletAddressError: false,
            walletAddressErrorMsg: "",
          });
        }
        this.setState({ showConfirmButton: true });
        if (this.state.yousendamount === "") {
          this.setState({ errors: true });
        } else {
          this.setState({ errors: false });
        }
      }
    }
  };

  /* **** Handle WalletAddress **** */
  handleWalletAddress = async (e) => {
    await this.setState({ walletAddress: e.target.value });
    if (this.state.walletAddress !== "") {
      const data = {
        walletId: this.state.walletAddress,
        asset:
          this.state.youGetSubCoinTrezorCode !== ""
            ? this.state.youGetSubCoinTrezorCode
            : this.state.youget,
      };
      this.checkValidateWallet(data);
    } else {
      this.setState({
        walletAddressError: false,
        walletAddressErrorMsg: "",
        showConfirmButton: false,
        errors: true,
      });
    }
  };

  /* **** Handle RefundAddress **** */
  handleRefundAddress = async (e) => {
    await this.setState({ refundAddress: e.target.value });
    if (this.state.refundAddress !== "") {
      const data = {
        walletId: this.state.refundAddress, // Correct parameter name for refund address
        asset:
          this.state.youSendSubCoinName !== ""
            ? this.state.youSendSubCoinName
            : this.state.yousend, // Pass "send" asset
      };
      this.checkValidateWallet(data, true); // Pass true for refund address validation
    } else {
      this.setState({
        refundAddressError: false,
        refundAddressErrorMsg: "",
        showConfirmButton: false,
        errors: true,
      });
    }
  };

  backConfirm = async () => {
    this.setState({ showConfirm: false });
  };

  handleIAgree = async () => {
    this.setState((prevState) => ({
      iAgree: !prevState.iAgree,
    }));
  };

  placeOrder = async () => {
    if (this.state.iAgree) {
      await this.setState({ isLoader: true });
      await this.setState({ errorOrderMsg: "" });
      const data = {
        coin_send: this.state.yousend,
        coin_send_network: this.state.youSendSubCoin,
        coin_receive: this.state.youget,
        coin_receive_network: this.state.youGetSubCoin,
        amount_send: this.state.yousendamount,
        // amount_receive: this.state.yougetamount,
        recipient: this.state.walletAddress,
        // networkFees: this.state.networkFees,
        ref: sessionStorage.getItem("ref") ? sessionStorage.getItem("ref") : "",
      };
      const order = await swapApi.getPlaceOrder(data);

      if (order.status === 200) {
        if (order.data.status === 400 && order.data.success === false) {
          await this.setState({ isLoader: false });
          await this.setState({ errorOrderMsg: order.data.errors[0].msg });
        }
        if (order.data.status === 200) {
          this.setState({ orderId: order.data.data.order_id });
          await this.setState({ isLoader: false });
          await this.setState({ redirect: true });
        }
      }
      // if (order.status === 200 && ) {

      // }
    } else {
      // eslint-disable-next-line no-alert
      alert("Please agree to the T&C and privacy policy.");
    }
  };

  handleShowSendAssetDropDown = async () => {
    await this.setState({ showSubAssetYouSend: true });
  };

  handleSubCoinSend = async (e) => {
    const value = JSON.parse(e.target.value);
    await this.setState({ youSendSubCoin: value.fbCode });
    await this.setState({ youSendSubCoinName: value.name });
    await this.setState({ showSubAssetYouSend: false });
  };

  handleShowGetAssetDropDown = async () => {
    await this.setState({ showSubAssetYouGet: true });
  };

  handleSubCoinGet = async (e) => {
    const value = JSON.parse(e.target.value);
    await this.setState({ youGetSubCoin: value.fbCode });
    await this.setState({ youGetSubCoinName: value.name });
    await this.setState({ youGetSubCoinTrezorCode: value.name });
    await this.setState({ showSubAssetYouGet: false });
  };
  handleSwipe = () => { };

  render() {
    const isPwa = window.matchMedia("(display-mode: standalone)");
    return isPwa.matches ? (
      this.state.isLoader ? (
        <>
          {this.state.showConfirm && (
            <div
              className="col-sm-2 col-md-2 side_menu2 swap-header-contr swap-header-contr-pwa"
              style={{ position: "fixed", top: "0px", zIndex: "71000000" }}
            >
              <div
                style={{ display: "flex" }}
                onClick={() => {
                  this.setState({ isSwap: false });
                }}
              >
                <i className=" ion ion-ios-arrow-back" />
              </div>
              <ol className="text-center tree-structure2">
                <li>
                  <span className="num active">1</span>
                </li>
                <li>
                  <span className="num">2</span>
                </li>
                <li style={{ paddingRight: "0px" }}>
                  <span className="num">3</span>
                </li>
              </ol>
            </div>
          )}
          <div className="coin-loader-wrap" style={{ position: "absolute" }}>
            <div className="coin-loader" style={{ zIndex: "999999" }}>
              <span className="coin-loader-img">
                {/* <img src={'https://swaptrade-images.s3.us-east-2.amazonaws.com/editor+large.gif'} /> */}
                <img src={process.env.REACT_APP_LOADERS} />
              </span>
            </div>
          </div>
        </>
      ) : this.state.showConfirm ? (
        <>
          {" "}
          {this.state.redirect && (
            <Redirect to={`/send/${this.state.orderId}`} />
          )}
          <div
            className="col-sm-2 col-md-2 side_menu2 swap-header-contr swap-header-contr-pwa"
            style={{ position: "fixed", top: "0px", zIndex: "71000000" }}
          >
            <div
              style={{ display: "flex" }}
              onClick={() => {
                this.setState({ isSwap: false });
              }}
            >
              <i className=" ion ion-ios-arrow-back" />
            </div>
            <ol className="text-center tree-structure2">
              <li>
                <span className="num active">1</span>
              </li>
              <li>
                <span className="num">2</span>
              </li>
              <li style={{ paddingRight: "0px" }}>
                <span className="num">3</span>
              </li>
            </ol>
          </div>
          <div className="col-sm-10 col-md-10">
            <div className="sendBoxArea-pwa">
              <div className="row">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-around",
                  }}
                >
                  <div
                    className="col-sm-12 col-md-6 sendBox"
                    style={{ marginBottom: "0px" }}
                  >
                    <div className="col-md-12">
                      <p style={{ textAlign: "center" }}>
                        <img src={this.state.youSendCoinImage} />
                      </p>
                    </div>
                    <div className="col-md-12">
                      <p className="plain-text" style={{ textAlign: "center" }}>
                        Send
                      </p>
                    </div>
                    <div className="col-md-12">
                      <p className="plain-text" style={{ textAlign: "center" }}>
                        {this.state.yousendamount} {this.state.yousend}
                      </p>
                    </div>
                  </div>
                  <div
                    className="col-sm-12 col-md-6 sendBox"
                    style={{ marginBottom: "0px" }}
                  >
                    <div className="col-md-12">
                      <p className="plain-text" style={{ textAlign: "center" }}>
                        <img src={this.state.youGetCoinImage} />
                      </p>
                    </div>
                    <div className="col-md-12">
                      <p className="plain-text" style={{ textAlign: "center" }}>
                        Receive
                      </p>
                    </div>
                    <div className="col-md-12">
                      <p className="plain-text" style={{ textAlign: "center" }}>
                        {this.state.yougetamount} {this.state.youget}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 col-md-12 sendBox">
                  <div className="col-md-12">
                    <p
                      className="plain-text"
                      style={{
                        textAlign: "center",
                        color: "orangered",
                        fontSize: "0.9em",
                      }}
                    >
                      This isn't the final amount you'll receive, it's the
                      market rate
                    </p>
                  </div>
                  <div className="col-md-12">
                    <p
                      className="plain-text"
                      style={{
                        textAlign: "center",
                        color: "orangered",
                        fontSize: "0.9em",
                      }}
                    >
                      Fees will be deducted after you create the order
                    </p>
                  </div>
                  <div className="col-md-12">
                    <p className="plain-text" style={{ textAlign: "center" }}>
                      Network Fee: {this.state.networkFees} {this.state.youget}
                    </p>
                  </div>
                  <div className="col-md-12">
                    <p className="plain-text" style={{ textAlign: "center" }}>
                      Your {this.state.youget} Address:
                    </p>
                  </div>
                  <div className="col-md-12">
                    <p
                      className="plain-text"
                      style={{
                        textAlign: "center",
                        wordBreak: "break-all",
                      }}
                    >
                      {this.state.walletAddress}
                    </p>
                  </div>
                  <div className="col-md-12">
                    <p className="plain-text" style={{ textAlign: "center" }}>
                      Estimated Arrival: 10-15 Minutes
                    </p>
                  </div>
                </div>
              </div>
              <div className="sendBox">
                <div className="form-check">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    name="iagree"
                    id="iagree"
                    onClick={this.handleIAgree}
                    value="checkedValue"
                    checked={!!this.state.iAgree}
                  />
                  <label className="form-check-label plain-text" htmlFor="cb-1">
                    I acknowledge that I have read and agree to the{" "}
                    <a
                      href="https://www.swaptrade.io/terms-and-conditions"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ color: "blue" }}
                    >
                      Terms & Conditions
                    </a>{" "}
                    and{" "}
                    <a
                      href="https://www.swaptrade.io/privacy-policy"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ color: "blue" }}
                    >
                      Privacy Policy.
                    </a>
                  </label>
                  {this.state.errorOrderMsg !== "" ? (
                    <label
                      style={{
                        textAlign: "center",
                        color: "red",
                        fontSize: "0.9em",
                      }}
                    >
                      {this.state.errorOrderMsg}
                    </label>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="sendBox sendBoxBtn">
                <ul className="d-flex">
                  <li style={{ width: "50%" }}>
                    <button
                      style={{ width: "100%" }}
                      type="button"
                      onClick={this.placeOrder}
                    >
                      CREATE ORDER
                    </button>
                  </li>
                  <li style={{ width: "50%" }}>
                    <button
                      style={{ width: "100%" }}
                      type="button"
                      onClick={this.backConfirm}
                    >
                      BACK
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </>
      ) : this.state.isSwap ? (
        <>
          <div
            className="col-sm-2 col-md-2 side_menu2 swap-header-contr swap-header-contr-pwa"
            style={{ position: "fixed", top: "0px", zIndex: "71000000" }}
          >
            <div
              style={{ display: "flex" }}
              onClick={() => {
                this.setState({ isSwap: false });
              }}
            >
              <i className=" ion ion-ios-arrow-back" />
            </div>
            <ol className="text-center tree-structure2">
              <li>
                <span className="num active">1</span>
              </li>
              <li>
                <span className="num">2</span>
              </li>
              <li style={{ paddingRight: "0px" }}>
                <span className="num">3</span>
              </li>
            </ol>
          </div>

          <section>
            <div className="pwa-swap-contr">
              <div className="swap-tab-contr">
                <div
                  className={`swap-tab ${this.state.activeTab === "dynamic" && "active"
                    }`}
                  onClick={() => {
                    this.setState({ activeTab: "dynamic" });
                  }}
                >
                  <p className="swap-label">Dynamic Rate</p>
                </div>
                {/* <div
                  className={`swap-tab ${
                    this.state.activeTab === "fixed" && "active"
                  }`}
                  onClick={() => {
                    this.setState({ activeTab: "fixed" });
                  }}
                >
                  <p className="swap-label">Fixed rate</p>
                </div> */}
              </div>{" "}
              <section style={{ overflow: "hidden", marginTop: "1rem" }}>
                {" "}
                <div className="row align-items-center">
                  <div className="col-1">
                    <img
                      src={this.state.youSendCoinImage}
                      alt=""
                      style={{ height: "auto", width: "auto" }}
                    />
                  </div>
                  <div className="col-11">
                    <div className="row align-items-end">
                      <div style={{ padding: "3px", width: "50%" }}>
                        <div className="mb-3">
                          <label htmlFor="" className="form-label">
                            You send
                          </label>
                          <select
                            className="form-select"
                            name="yousend"
                            id="yousend"
                            onChange={this.handleYouSend}
                          >
                            {this.state.assets.map((element) => (
                              <option
                                value={element.id}
                                key={element.number}
                                selected={
                                  this.state.yousend === element.id
                                    ? true
                                    : null
                                }
                                disabled={
                                  this.state.youget === element.id ? true : null
                                }
                              >
                                {element.id} {element.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div style={{ padding: "3px", width: "50%" }}>
                        <div className="mb-3">
                          <label htmlFor="" className="form-label" />
                          <input
                            type="number"
                            className="form-control "
                            name="yousendamount"
                            id="yousendamount"
                            onChange={this.handlePrice}
                            value={this.state.yousendamount}
                            aria-describedby="helpId"
                            placeholder="0"
                          />
                        </div>
                      </div>
                      <div
                        className="col-12 plain-text"
                        style={{
                          color: this.state.minerror ? "red" : "initial",
                        }}
                      >
                        {this.state.minerrormsg}
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    paddingBlock: "10px",
                  }}
                >
                  <i
                    className="ion ion-ios-lock"
                    style={{ marginBlock: "5px" }}
                  />
                  <div
                    className="row align-items-center"
                    style={{ width: "100%", paddingLeft: "1rem" }}
                  >
                    <div className="pl-5 col-lg-10 col-10">
                      <span>
                        <span className="plain-text">
                          {this.state.realPrice === "" ? (
                            ""
                          ) : (
                            <span>
                              <span style={{ fontSize: "12px" }}>
                                {this.state.realPriceView}
                              </span>
                            </span>
                          )}
                        </span>
                      </span>
                    </div>
                  </div>
                  <div
                    style={{ backgroundColor: "white", cursor: "pointer" }}
                    className="swipe-btn"
                    onClick={this.handleSwipe}
                  >
                    <i className="ion ion-md-sync" />
                  </div>
                </div>
                <div className="row align-items-center">
                  <div className="col-1">
                    <img
                      src={this.state.youGetCoinImage}
                      alt=""
                      style={{ height: "auto", width: "auto" }}
                    />
                  </div>
                  <div className="col-11">
                    <div className="row align-items-end">
                      <div style={{ padding: "3px", width: "50%" }}>
                        <div className="mb-3">
                          <label htmlFor="" className="form-label">
                            You get approximately
                          </label>
                          <select
                            className="form-select"
                            name="youget"
                            id="youget"
                            onChange={this.handleYouGet}
                          >
                            {this.state.assets.map((element) => (
                              <option
                                value={element.id}
                                key={element.number}
                                selected={
                                  this.state.youget === element.id ? true : null
                                }
                                disabled={
                                  this.state.yousend === element.id
                                    ? true
                                    : null
                                }
                              >
                                {element.id} {element.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div style={{ padding: "3px", width: "50%" }}>
                        <div className="mb-3">
                          <label htmlFor="" className="form-label" />
                          <input
                            type="number"
                            className="form-control "
                            name="yougetamount"
                            id="yougetamount"
                            aria-describedby="helpId"
                            placeholder="0"
                            value={this.state.yougetamount}
                            readOnly
                          />
                        </div>
                      </div>
                      <div
                        className="col-12 plain-text"
                        style={{
                          color: this.state.maxerror ? "red" : "initial",
                        }}
                      >
                        {this.state.maxerrormsg}
                      </div>
                    </div>
                  </div>
                </div>
                {this.state.isShowYouGetAsset ? (
                  <>
                    {this.state.showSubAssetYouGet ? (
                      <div className="row align-items-center">
                        <div className="col-1" />
                        <div className="col-11">
                          <div className="row align-items-end">
                            <div className="col-9">
                              <div className="mb-3">
                                <label htmlFor="" className="form-label">
                                  Network
                                </label>
                                <select
                                  className="form-select"
                                  name="youSendSubCoin"
                                  id="youSendSubCoin"
                                  onChange={this.handleSubCoinGet}
                                >
                                  {this.state.youGetSubAssets.map((element) => (
                                    <option
                                      key={element.id}
                                      value={JSON.stringify(element)}
                                      selected={
                                        element.fbCode ===
                                        this.state.youGetSubCoin
                                      }
                                    >
                                      {element.name}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="row align-items-center">
                        <div className="col-1" />
                        <div className="col-11">
                          <div className="row align-items-end">
                            <div className="col-9 plain-text">
                              Network: {this.state.youGetSubCoinName}{" "}
                              <span
                                role="presentation"
                                onClick={this.handleShowGetAssetDropDown}
                                style={{
                                  cursor: "pointer",
                                  textDecoration: "underline",
                                }}
                              >
                                Click to change
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                ) : (
                  // <div className="row align-items-center"></div>
                  ""
                )}
                <div className="row">
                  <div className="col-12">
                    <div className="mb-3">
                      <label htmlFor="" className="form-label">
                        Please enter your wallet address
                      </label>
                      <input
                        type="text"
                        className={
                          this.state.walletAddressError
                            ? "form-control input-error"
                            : "form-control "
                        }
                        name="wallet_address"
                        id="wallet_address"
                        aria-describedby="helpId"
                        placeholder=""
                        onChange={this.handleWalletAddress}
                        value={this.state.walletAddress}
                      />
                      {this.state.walletAddressError ? (
                        <small
                          id="helpId"
                          className="form-text text-muted input-error-msg plain-text"
                        >
                          {this.state.walletAddressErrorMsg}
                        </small>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
                {/* Refund Wallet Address PWA */}
                <div className="row">
                  <div className="col-12">
                    <div className="mb-3">
                      <label htmlFor="" className="form-label">
                        Please enter your Refund wallet address
                      </label>
                      <input
                        type="text"
                        className={
                          this.state.refundAddressError
                            ? "form-control input-error"
                            : "form-control "
                        }
                        name="wallet_address"
                        id="wallet_address"
                        aria-describedby="helpId"
                        placeholder=""
                        onChange={this.handleRefundAddress}
                        value={this.state.refundAddress}
                      />
                      {this.state.refundAddressError ? (
                        <small
                          id="helpId"
                          className="form-text text-muted input-error-msg plain-text"
                        >
                          {this.state.refundAddressErrorMsg}
                        </small>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 d-flex justify-content-center">
                    <div className="mb-3  w-50">
                      <button
                        type="button"
                        className="btn btn-info w-100"
                        onClick={this.placeConfirm}
                        disabled={this.state.errors}
                      >
                        Confirm
                      </button>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </section>
        </>
      ) : (
        <section>
          <div className="pwa-swap-contr">
            <div className="swap-tab-contr">
              <div
                className={`swap-tab ${this.state.activeTab === "dynamic" ? "" : ""
                  }`}
                onClick={() => {
                  this.setState({ activeTab: "dynamic" });
                }}
              >
                <p className="swap-label">Dynamic Rate</p>
              </div>
              {/* <div
                className={`swap-tab ${
                  this.state.activeTab === "fixed" ? "active" : ""
                }`}
                onClick={() => {
                  this.setState({ activeTab: "fixed" });
                }}
              >
                <p className="swap-label">Fixed Rate</p>
              </div> */}
            </div>
            <div className="swap-conv-contr">
              <div className="swap-conv-wrapper">
                <p className="swap-conv-label">You send</p>
                <div className="conv-input-wrapper">
                  <input
                    className="swap-conv-prim-input"
                    onChange={this.handlePrice}
                    value={this.state.yousendamount}
                  />
                  <DropdownButton
                    as={InputGroup.Prepend}
                    variant="outline-secondary"
                    title={
                      this.state.sendSelectedOption ? (
                        <span
                          style={{
                            display: "flex",
                            justifyContent: "start",
                            alignItems: "center",
                          }}
                        >
                          <img
                            src={this.state.youSendCoinImage}
                            className="mr-2"
                            width={35}
                            height={35}
                          />
                          <p style={{ height: "20px", marginLeft: "5px" }}>
                            {this.state.yousend}
                          </p>
                        </span>
                      ) : (
                        <span
                          style={{
                            display: "flex",
                            justifyContent: "start",
                            alignItems: "center",
                          }}
                        >
                          <img
                            src={placeaHolderIcon}
                            width={35}
                            height={35}
                            className="mr-2"
                          />
                        </span>
                      )
                    }
                    id="input-group-dropdown-1"
                  >
                    <Dropdown.Divider />
                    {this.state.assets.map((option) => (
                      <Dropdown.Item
                        style={{ paddingLeft: "0.5rem" }}
                        key={option.id}
                        value={option.id}
                        onClick={() =>
                          this.handleYouSend({ target: { value: option.id } })
                        }
                        disabled={this.state.youget === option.id ? true : null}
                        selected={
                          this.state.yousend === option.id ? true : null
                        }
                      >
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <img
                            src={`${process.env.REACT_APP_COIN_IMAGE_URL}${option.id}.png`}
                            width={35}
                            height={35}
                          />
                          <p style={{ padding: "0px" }}>{option.name}</p>
                        </div>
                      </Dropdown.Item>
                    ))}
                  </DropdownButton>
                </div>
                <div
                  className="col-12 plain-text"
                  style={{
                    color: this.state.minerror ? "red" : "initial",
                  }}
                >
                  {this.state.minerrormsg}
                </div>
              </div>
            </div>
            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  paddingBlock: "10px",
                }}
              >
                <i
                  className="ion ion-ios-lock"
                  style={{ marginBlock: "5px" }}
                />
                <div
                  className="row align-items-center"
                  style={{ width: "100%", paddingLeft: "1rem" }}
                >
                  <div className="pl-5 col-lg-10 col-10">
                    <span>
                      <span className="plain-text">
                        {this.state.realPrice === "" ? (
                          ""
                        ) : (
                          <span>
                            <span style={{ fontSize: "12px" }}>
                              {this.state.realPriceView}
                            </span>
                          </span>
                        )}
                      </span>
                    </span>
                  </div>
                </div>
                <div
                  style={{ backgroundColor: "white", cursor: "pointer" }}
                  className="swipe-btn"
                  onClick={this.handleSwipe}
                >
                  <i className="ion ion-md-sync" />
                </div>
              </div>
            </div>
            <div className="swap-conv-contr">
              <div className="swap-conv-wrapper">
                <p className="swap-conv-label">You get</p>
                <div className="conv-input-wrapper">
                  <input
                    className="swap-conv-prim-input"
                    value={this.state.realPriceView}
                    readOnly
                  />
                  <DropdownButton
                    as={InputGroup.Prepend}
                    variant="outline-secondary"
                    title={
                      this.state.getSelectedOption ? (
                        <span
                          style={{
                            display: "flex",
                            justifyContent: "start",
                            alignItems: "center",
                          }}
                        >
                          <img
                            src={this.state.youGetCoinImage}
                            className="mr-2"
                            width={35}
                            height={35}
                          />
                          <p style={{ height: "20px", marginLeft: "5px" }}>
                            {this.state.youget}
                          </p>
                        </span>
                      ) : (
                        <span
                          style={{
                            display: "flex",
                            justifyContent: "start",
                            alignItems: "center",
                          }}
                        >
                          <img
                            src={placeaHolderIcon}
                            width={35}
                            height={35}
                            className="mr-2"
                          />
                        </span>
                      )
                    }
                    id="input-group-dropdown-1"
                  >
                    <Dropdown.Divider />
                    {this.state.assets.map((option) => (
                      <Dropdown.Item
                        style={{ paddingLeft: "0.5rem" }}
                        key={option.id}
                        value={option.id}
                        onClick={() =>
                          this.handleYouGet({ target: { value: option.id } })
                        }
                        selected={this.state.youget === option.id ? true : null}
                        disabled={
                          this.state.yousend === option.id ? true : null
                        }
                      >
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <img
                            src={`${process.env.REACT_APP_COIN_IMAGE_URL}${option.id}.png`}
                            width={35}
                            height={35}
                          />
                          <p style={{ padding: "0px" }}>{option.name}</p>
                        </div>
                      </Dropdown.Item>
                    ))}
                  </DropdownButton>
                </div>
                <div
                  className="col-12 plain-text"
                  style={{
                    color: this.state.maxerror ? "red" : "initial",
                  }}
                >
                  {this.state.maxerrormsg}
                </div>
              </div>
            </div>
            {this.state.isSwap && (
              <div className="conv-coin-address-contr">
                <div className="mb-3">
                  <label htmlFor="" className="form-label">
                    Please enter your wallet address
                  </label>
                  <input
                    type="text"
                    className={
                      this.state.walletAddressError
                        ? "form-control input-error"
                        : "form-control "
                    }
                    name="wallet_address"
                    id="wallet_address"
                    aria-describedby="helpId"
                    placeholder=""
                    onChange={this.handleWalletAddress}
                    value={this.state.walletAddress}
                  />
                  {this.state.walletAddressError ? (
                    <small
                      id="helpId"
                      className="form-text text-muted input-error-msg plain-text"
                    >
                      {this.state.walletAddressErrorMsg}
                    </small>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            )}
            <div className="swap-btn-contr">
              <button
                className="swap-btn"
                onClick={this.placeConfirm}
                disabled={this.state.errors}
              >
                {this.state.isSwap ? "Confirm" : "Swap"}
              </button>
            </div>
          </div>
        </section>
      )
    ) : (
      <>
        {this.state.isLoader ? (
          <div className="coin-loader-wrap" style={{ position: "absolute" }}>
            <div className="coin-loader" style={{ zIndex: "999999" }}>
              <span className="coin-loader-img">
                {/* <img src={'https://swaptrade-images.s3.us-east-2.amazonaws.com/editor+large.gif'} /> */}
                <img src={process.env.REACT_APP_LOADERS} />
              </span>
            </div>
          </div>
        ) : (
          ""
        )}

        {this.state.redirect && <Redirect to={`/send/${this.state.orderId}`} />}

        {this.state.showConfirm ? (
          <section>
            <div className="container-fluid">
              <div className="row" style={{ marginTop: "2%" }}>
                <div
                  className="col-sm-10 col-md-1 menu_option"
                  style={{ marginBottom: "2%" }}
                >
                  <Nav
                    variant="pills"
                    className="swap-nav"
                    style={{ boxShadow: "none" }}
                  >
                    <Nav.Item
                      style={{ marginTop: "20px", marginBottom: "20px" }}
                    >
                      <Nav.Link>Choose</Nav.Link>
                    </Nav.Item>
                    <Nav.Item
                      style={{ marginTop: "20px", marginBottom: "20px" }}
                    >
                      <Nav.Link>Send</Nav.Link>
                    </Nav.Item>
                    <Nav.Item
                      style={{ marginTop: "20px", marginBottom: "20px" }}
                    >
                      <Nav.Link>Receive</Nav.Link>
                    </Nav.Item>
                  </Nav>
                </div>
                <div className="col-md-1 side_menu" style={{ marginTop: "2%" }}>
                  <ol className="text-center tree-structure">
                    <li>
                      <span
                        className="num"
                        style={{ background: "#428bca", color: "#fff" }}
                      >
                        1
                      </span>
                      <ol></ol>
                    </li>
                    <li>
                      <span className="num">2</span>
                      <ol></ol>
                    </li>
                    <li>
                      <span className="num">3</span>
                      <ol></ol>
                    </li>
                  </ol>
                </div>
                <div className="col-sm-2 col-md-2 side_menu2">
                  <ol className="text-center tree-structure2">
                    <li>
                      <span
                        className="num"
                        style={{ background: "#428bca", color: "#fff" }}
                      >
                        1
                      </span>
                      <ol></ol>
                    </li>
                    <li>
                      <span className="num">2</span>
                      <ol></ol>
                    </li>
                    <li>
                      <span className="num">3</span>
                      <ol></ol>
                    </li>
                  </ol>
                  <ol className="text-center tree-structure">
                    <li>
                      <span
                        className="num"
                        style={{ background: "#428bca", color: "#fff" }}
                      >
                        1
                      </span>
                      <ol></ol>
                    </li>
                    <li>
                      <span className="num">2</span>
                      <ol></ol>
                    </li>
                    <li>
                      <span className="num">3</span>
                      <ol></ol>
                    </li>
                  </ol>
                </div>
                <div className="col-sm-10 col-md-10">
                  <div className="sendBoxArea">
                    <div className="row">
                      <div className="col-sm-12 col-md-6 sendBox">
                        <div className="col-md-12">
                          <p style={{ textAlign: "center" }}>
                            <img src={this.state.youSendCoinImage} />
                          </p>
                        </div>
                        <div className="col-md-12">
                          <p
                            className="plain-text"
                            style={{ textAlign: "center" }}
                          >
                            Send
                          </p>
                        </div>
                        <div className="col-md-12">
                          <p
                            className="plain-text"
                            style={{ textAlign: "center" }}
                          >
                            {this.state.yousendamount} {this.state.yousend}
                          </p>
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6 sendBox">
                        <div className="col-md-12">
                          <p
                            className="plain-text"
                            style={{ textAlign: "center" }}
                          >
                            <img src={this.state.youGetCoinImage} />
                          </p>
                        </div>
                        <div className="col-md-12">
                          <p
                            className="plain-text"
                            style={{ textAlign: "center" }}
                          >
                            Receive
                          </p>
                        </div>
                        <div className="col-md-12">
                          <p
                            className="plain-text"
                            style={{ textAlign: "center" }}
                          >
                            {this.state.yougetamount} {this.state.youget}
                          </p>
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-12 sendBox">
                        <div className="col-md-12">
                          <p
                            className="plain-text"
                            style={{
                              textAlign: "center",
                              color: "orangered",
                              fontSize: "0.9em",
                            }}
                          >
                            This isn't the final amount you'll receive, it's the
                            market rate
                          </p>
                        </div>
                        <div className="col-md-12">
                          <p
                            className="plain-text"
                            style={{
                              textAlign: "center",
                              color: "orangered",
                              fontSize: "0.9em",
                            }}
                          >
                            Fees will be deducted after you create the order
                          </p>
                        </div>
                        <div className="col-md-12">
                          <p
                            className="plain-text"
                            style={{ textAlign: "center" }}
                          >
                            Network Fee: {this.state.networkFees}{" "}
                            {this.state.youget}
                          </p>
                        </div>
                        <div className="col-md-12">
                          <p
                            className="plain-text"
                            style={{ textAlign: "center" }}
                          >
                            Your {this.state.youget} Address:
                          </p>
                        </div>
                        <div className="col-md-12">
                          <p
                            className="plain-text"
                            style={{
                              textAlign: "center",
                              wordBreak: "break-all",
                            }}
                          >
                            {this.state.walletAddress}
                          </p>
                        </div>
                        <div className="col-md-12">
                          <p
                            className="plain-text"
                            style={{ textAlign: "center" }}
                          >
                            Estimated Arrival: 10-15 Minutes
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="sendBox">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          name="iagree"
                          id="iagree"
                          onClick={this.handleIAgree}
                          value="checkedValue"
                          checked={!!this.state.iAgree}
                        />
                        <label
                          className="form-check-label plain-text"
                          htmlFor="cb-1"
                        >
                          I acknowledge that I have read and agree to the{" "}
                          <a
                            href="https://www.swaptrade.io/terms-and-conditions"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ color: "blue" }}
                          >
                            Terms & Conditions
                          </a>{" "}
                          and{" "}
                          <a
                            href="https://www.swaptrade.io/privacy-policy"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ color: "blue" }}
                          >
                            Privacy Policy.
                          </a>
                        </label>
                        {this.state.errorOrderMsg !== "" ? (
                          <label
                            style={{
                              textAlign: "center",
                              color: "red",
                              fontSize: "0.9em",
                            }}
                          >
                            {this.state.errorOrderMsg}
                          </label>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div className="sendBox sendBoxBtn">
                      <ul className="d-flex">
                        <li style={{ width: "50%" }}>
                          <button
                            style={{ width: "100%" }}
                            type="button"
                            onClick={this.placeOrder}
                          >
                            CREATE ORDER
                          </button>
                        </li>
                        <li style={{ width: "50%" }}>
                          <button
                            style={{ width: "100%" }}
                            type="button"
                            onClick={this.backConfirm}
                          >
                            BACK
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        ) : (
          <section className="caseStudiesSec">
            <div className="container-fluid">
              <div
                className="row"
                style={{ marginTop: "2%", marginRight: "0%", marginLeft: "0%" }}
              >
                <div
                  className="col-sm-10 col-md-1 menu_option"
                  style={{ marginBottom: "2%" }}
                >
                  <Nav
                    variant="pills"
                    className="swap-nav"
                    style={{ boxShadow: "none" }}
                  >
                    <Nav.Item
                      style={{ marginTop: "20px", marginBottom: "20px" }}
                    >
                      <Nav.Link>Choose</Nav.Link>
                    </Nav.Item>
                    <Nav.Item
                      style={{ marginTop: "20px", marginBottom: "20px" }}
                    >
                      <Nav.Link>Send</Nav.Link>
                    </Nav.Item>
                    <Nav.Item
                      style={{ marginTop: "20px", marginBottom: "20px" }}
                    >
                      <Nav.Link>Receive</Nav.Link>
                    </Nav.Item>
                  </Nav>
                </div>
                <div className="col-md-1 side_menu" style={{ marginTop: "2%" }}>
                  <ol className="text-center tree-structure">
                    <li>
                      <span
                        className="num"
                        style={{ background: "#428bca", color: "#fff" }}
                      >
                        1
                      </span>
                      <ol></ol>
                    </li>
                    <li>
                      <span className="num">2</span>
                      <ol></ol>
                    </li>
                    <li>
                      <span className="num">3</span>
                      <ol></ol>
                    </li>
                  </ol>
                </div>
                <div className="col-sm-2 col-md-2 side_menu2">
                  <ol className="text-center tree-structure2">
                    <li>
                      <span
                        className="num"
                        style={{ background: "#428bca", color: "#fff" }}
                      >
                        1
                      </span>
                    </li>
                    <li>
                      <span className="num">2</span>
                    </li>
                    <li>
                      <span className="num">3</span>
                    </li>
                  </ol>
                  <ol className="text-center tree-structure">
                    <li>
                      <span
                        className="num"
                        style={{ background: "#428bca", color: "#fff" }}
                      >
                        1
                      </span>
                      <ol></ol>
                    </li>
                    <li>
                      <span className="num">2</span>
                      <ol></ol>
                    </li>
                    <li>
                      <span className="num">3</span>
                      <ol></ol>
                    </li>
                  </ol>
                </div>
                <div
                  className="col-sm-10 col-md-10"
                  style={{
                    border: "#e9e7e7 2px solid",
                    boxShadow: "rgb(0 0 0 / 10%) 0 15px 15px -5px",
                    borderRadius: "10px",
                    padding: "2%",
                  }}
                >
                  <div className="row">
                    <div className="col-12 mb-4 d-flex justify-content-between align-items-center">
                      <div className="rateTabs">
                        <ul className="d-flex">
                          <li>
                            <button
                              type="button"
                              className={
                                this.state.activeTab === "dynamic"
                                  ? "active"
                                  : ""
                              }
                              onClick={() => this.handleTabSwitch("dynamic")}
                            >
                              Dynamic Rate
                            </button>
                          </li>
                          <li>
                            {/* <button
                              type="button"
                              className={
                                this.state.activeTab === "fixed" ? "active" : ""
                              }
                              onClick={() => this.handleTabSwitch("fixed")}
                            >
                              Fixed Rate
                            </button> */}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="row align-items-center">
                    <div className="col-1">
                      <img
                        src={this.state.youSendCoinImage}
                        alt=""
                        style={{ height: "auto", width: "auto" }}
                      />
                    </div>
                    <div className="col-11">
                      <div className="row align-items-end">
                        <div className="col-6">
                          <div className="mb-3">
                            <label htmlFor="" className="form-label">
                              You send
                            </label>
                            <select
                              className="form-select"
                              name="yousend"
                              id="yousend"
                              onChange={this.handleYouSend}
                            >
                              {this.state.assets.map((element) => (
                                <option
                                  value={element.id}
                                  key={element.number}
                                  selected={
                                    this.state.yousend === element.id
                                      ? true
                                      : null
                                  }
                                  disabled={
                                    this.state.youget === element.id
                                      ? true
                                      : null
                                  }
                                >
                                  {element.id} {element.name}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="mb-3">
                            <label htmlFor="" className="form-label" />
                            <input
                              type="number"
                              className="form-control "
                              name="yousendamount"
                              id="yousendamount"
                              onChange={this.handlePrice}
                              value={this.state.yousendamount}
                              aria-describedby="helpId"
                              placeholder="0"
                            />
                          </div>
                        </div>
                        <div
                          className="col-12 plain-text"
                          style={{
                            color: this.state.minerror ? "red" : "initial",
                          }}
                        >
                          {this.state.minerrormsg}
                        </div>
                      </div>
                    </div>
                  </div>
                  {this.state.isShowYouSendAsset ? (
                    <>
                      {this.state.showSubAssetYouSend ? (
                        <div className="row align-items-center">
                          <div className="col-1" />
                          <div className="col-11">
                            <div className="row align-items-end">
                              <div className="col-9">
                                <div className="mb-3">
                                  <label htmlFor="" className="form-label">
                                    Network
                                  </label>
                                  <select
                                    className="form-select"
                                    name="youSendSubCoin"
                                    id="youSendSubCoin"
                                    onChange={this.handleSubCoinSend}
                                  >
                                    {this.state.youSendSubAssets.map(
                                      (element) => (
                                        <option
                                          key={element.id}
                                          value={JSON.stringify(element)}
                                          selected={
                                            element.fbCode ===
                                            this.state.youSendSubCoin
                                          }
                                        >
                                          {element.name}
                                        </option>
                                      )
                                    )}
                                  </select>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="row align-items-center">
                          <div className="col-1" />
                          <div className="col-11">
                            <div className="row align-items-end">
                              <div className="col-9 plain-text">
                                Network: {this.state.youSendSubCoinName}{" "}
                                <span
                                  role="presentation"
                                  onClick={this.handleShowSendAssetDropDown}
                                  style={{
                                    cursor: "pointer",
                                    textDecoration: "underline",
                                  }}
                                >
                                  Click to change
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    // <div className="row align-items-center"></div>
                    ""
                  )}

                  <div className="row align-items-center">
                    <div className="pl-5 col-lg-10 col-10">
                      <span>
                        <span className="plain-text">
                          {this.state.realPrice === "" ? (
                            ""
                          ) : (
                            <span>
                              <span>{this.state.realPriceView}</span>
                            </span>
                          )}
                        </span>
                      </span>
                    </div>
                  </div>

                  <div className="row align-items-center">
                    <div className="col-1">
                      <img
                        src={this.state.youGetCoinImage}
                        alt=""
                        style={{ height: "auto", width: "auto" }}
                      />
                    </div>
                    <div className="col-11">
                      <div className="row align-items-end">
                        <div className="col-6">
                          <div className="mb-3">
                            <label htmlFor="" className="form-label">
                              You get approximately
                            </label>
                            <select
                              className="form-select"
                              name="youget"
                              id="youget"
                              onChange={this.handleYouGet}
                            >
                              {this.state.assets.map((element) => (
                                <option
                                  value={element.id}
                                  key={element.number}
                                  selected={
                                    this.state.youget === element.id
                                      ? true
                                      : null
                                  }
                                  disabled={
                                    this.state.yousend === element.id
                                      ? true
                                      : null
                                  }
                                >
                                  {element.id} {element.name}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="mb-3">
                            <label htmlFor="" className="form-label" />
                            <input
                              type="number"
                              className="form-control "
                              name="yougetamount"
                              id="yougetamount"
                              aria-describedby="helpId"
                              placeholder="0"
                              value={this.state.yougetamount}
                              readOnly
                            />
                          </div>
                        </div>
                        <div
                          className="col-12 plain-text"
                          style={{
                            color: this.state.maxerror ? "red" : "initial",
                          }}
                        >
                          {this.state.maxerrormsg}
                        </div>
                      </div>
                    </div>
                  </div>
                  {this.state.isShowYouGetAsset ? (
                    <>
                      {this.state.showSubAssetYouGet ? (
                        <div className="row align-items-center">
                          <div className="col-1" />
                          <div className="col-11">
                            <div className="row align-items-end">
                              <div className="col-9">
                                <div className="mb-3">
                                  <label htmlFor="" className="form-label">
                                    Network
                                  </label>
                                  <select
                                    className="form-select"
                                    name="youSendSubCoin"
                                    id="youSendSubCoin"
                                    onChange={this.handleSubCoinGet}
                                  >
                                    {this.state.youGetSubAssets.map(
                                      (element) => (
                                        <option
                                          key={element.id}
                                          value={JSON.stringify(element)}
                                          selected={
                                            element.fbCode ===
                                            this.state.youGetSubCoin
                                          }
                                        >
                                          {element.name}
                                        </option>
                                      )
                                    )}
                                  </select>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="row align-items-center">
                          <div className="col-1" />
                          <div className="col-11">
                            <div className="row align-items-end">
                              <div className="col-9 plain-text">
                                Network: {this.state.youGetSubCoinName}{" "}
                                <span
                                  role="presentation"
                                  onClick={this.handleShowGetAssetDropDown}
                                  style={{
                                    cursor: "pointer",
                                    textDecoration: "underline",
                                  }}
                                >
                                  Click to change
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    // <div className="row align-items-center"></div>
                    ""
                  )}
                  <div className="row">
                    <div className="col-12">
                      <div className="mb-3">
                        <label htmlFor="" className="form-label">
                          Please enter your wallet address
                        </label>
                        <input
                          type="text"
                          className={
                            this.state.walletAddressError
                              ? "form-control input-error"
                              : "form-control "
                          }
                          name="wallet_address"
                          id="wallet_address"
                          aria-describedby="helpId"
                          placeholder="Enter wallet address"
                          onChange={this.handleWalletAddress}
                          value={this.state.walletAddress}
                        />
                        {this.state.walletAddressError ? (
                          <small
                            id="helpId"
                            className="form-text text-muted input-error-msg plain-text"
                          >
                            {this.state.walletAddressErrorMsg}
                          </small>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>

                  {/* Wallet Refunds */}

                  <div className="row">
                    <div className="col-12">
                      <div className="mb-3">
                        <label htmlFor="refund_address" className="form-label">
                          Please enter your refund wallet address
                        </label>
                        <input
                          type="text"
                          className={
                            this.state.refundAddressError
                              ? "form-control input-error"
                              : "form-control "
                          }
                          name="refund_address"
                          id="refund_address"
                          aria-describedby="helpId"
                          placeholder="Enter refund wallet address"
                          onChange={this.handleRefundAddress}
                          value={this.state.refundAddress}
                        />
                        {this.state.refundAddressError ? (
                          <small
                            id="helpId"
                            className="form-text text-muted input-error-msg plain-text"
                          >
                            {this.state.refundAddressErrorMsg}
                          </small>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12 d-flex justify-content-center">
                      <div className="mb-3  w-50">
                        <button
                          type="button"
                          className="btn btn-info w-100"
                          onClick={this.placeConfirm}
                          disabled={this.state.errors}
                        >
                          Confirm
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}
      </>
    );
  }
}
export default withParams(Swap);
