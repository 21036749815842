import React from "react";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <>
      <div className="footer">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
              <div className="copyright">
                <p>
                  <Link
                    to={"privacy-policy"}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Privacy Policy
                  </Link>{" "}
                  |{" "}
                  <Link
                    to={"terms-and-conditions"}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Site Terms
                  </Link>{" "}
                  | 2024 <b>Swaptrade</b> | All Rights Reserved
                </p>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
              {/* <div className="footer-social">
                <ul>
                  <li><Link to={{pathname:'https://facebook.com/Swaptrade-106940475250005/',}}><i className="fa fa-facebook"></i></Link></li>
                  <li><Link to={'#'}><i className="fa fa-twitter"></i></Link></li>
                  <li><Link to={'#'}><i className="fa fa-linkedin"></i></Link></li>
                  <li><Link to={'#'}><i className="fa fa-youtube"></i></Link></li>
                </ul>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
