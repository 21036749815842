/* eslint-disable no-useless-constructor */
import React, { Component } from "react";
import HistoryOrder from "../components/HistoryOrder";
import MarketHistory from "../components/MarketHistory";
import MarketNews from "../components/MarketNews";
import MarketPairs from "../components/MarketPairs";
import MarketTrade from "../components/MarketTrade";
import OrderBook from "../components/OrderBook";
import TradingChart from "../components/TradingChart";
import TradingChartDark from "../components/TradingChartDark";
import TodayStats from "../components/TodayStats";
import { ThemeConsumer } from "../context/ThemeContext";

export default class exchange extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    console.log(this.props);
    const isPwa = window.matchMedia("(display-mode: standalone)");

    return isPwa.matches ? (
      <>
        <div className="container-fluid  no-fluid">
          <div className="row sm-gutters">
            <div style={{ margin: "0px", padding: "0px" }}>
              <MarketPairs search={this.props.search} />
            </div>
            {/* <div className="col-sm-12 col-md-6">
              <ThemeConsumer>
                {({ data }) => {
                  return data.theme === "light" ? (
                    <TradingChart />
                  ) : (
                    <TradingChartDark />
                  );
                }}
              </ThemeConsumer>
              <MarketTrade />
            </div> */}
            {/* <div className="col-md-3">
              <OrderBook />
            </div>
            <div className="col-md-3">
              <MarketNews />
            </div>
            <div className="col-md-9">
              <HistoryOrder />
            </div> */}
          </div>
        </div>
      </>
    ) : (
      <>
        <div className="container-fluid mtb15 no-fluid">
          <div className="row sm-gutters">
            <div className="col-12">
              <TodayStats />
            </div>
            <div className="col-sm-12 col-md-3">
              <MarketPairs />
            </div>
            <div className="col-sm-12 col-md-6">
              <ThemeConsumer>
                {({ data }) => {
                  return data.theme === "light" ? (
                    <TradingChart />
                  ) : (
                    <TradingChartDark />
                  );
                }}
              </ThemeConsumer>
              <MarketTrade />
            </div>
            <div className="col-md-3">
              <OrderBook />
              <MarketHistory />
            </div>
            <div className="col-md-3">
              <MarketNews />
            </div>
            <div className="col-md-9">
              <HistoryOrder />
            </div>
          </div>
        </div>
      </>
    );
  }
}
