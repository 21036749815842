/* eslint-disable linebreak-style */
/* eslint-disable import/prefer-default-export */
import axios from "axios";
// import { getCookie } from "../helpers/cookieHelper";
// import Cookies from 'js-cookie';
const create = axios.create;

const API = create({
  baseURL: process.env.REACT_APP_AUTH_URL,
  withCredentials: true,
  headers: { "Content-Type": "application/json" },
});

const AuthAPI = create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: true,
  headers: { "Content-Type": "application/json" },
});

// const TradeAuthAPI = create({
//   baseURL: process.env.REACT_APP_TRADE_PLATFORM,
//   withCredentials: true,
//   headers: { "Content-Type": "application/json" },
// });

const authApi = {};

authApi.register = async (data) => {
  try {
    const res = await API.post("registerNew", data);
    return res;
  } catch (error) {
    return error;
  }
};

authApi.login = async (data) => {
  try {
    const res = await API.post("loginNew", data);
    return res;
  } catch (error) {
    return error;
  }
};
authApi.forgotPassword = async (data) => {
  try {
    const res = await API.post("forgotPassword", data);
    return res;
  } catch (error) {
    return error;
  }
};
authApi.verifyOTP = async (data) => {
  try {
    const res = await API.post("resetForgotPassword", data);
    return res;
  } catch (error) {
    return error;
  }
};
// authApi.otpvalidation = async (data) => {
//   try {
//     const res = await API.post('forgotPassword',data);
//     return res;
//   } catch (error) {
//     return error;
//   }
// };

authApi.forcePasswordChange = async (data) => {
  try {
    const res = await API.post("forcePasswordChange", data);
    return res;
  } catch (error) {
    return error;
  }
};

authApi.mfaSetup = async (data) => {
  try {
    const res = await API.post("mfaSetup", data);
    return res;
  } catch (error) {
    return error;
  }
};

authApi.verifymfafornewuser = async (data) => {
  try {
    const res = await API.post("mfaVerificationForNewUser", data);
    return res;
  } catch (error) {
    return error;
  }
};

authApi.getmfaverification = async (data) => {
  try {
    const res = await API.post("mfaVerification", data);
    return res;
  } catch (error) {
    return error;
  }
};

authApi.signOutToken = async (data) => {
  try {
    const res = await API.post("signOut", data);
    return res;
  } catch (error) {
    return error;
  }
};

authApi.generateQrCode = async (data) => {
  try {
    const res = await AuthAPI.post("/auth/generateQrCode", data);
    return res;
  } catch (error) {
    return error;
  }
};

// authApi.signOutCookie = async () => {
//   try {
//     const res = await TradeAuthAPI.post(
//       "/identity/api/v2/identity/users/me/signout"
//     );
//     return res;
//   } catch (error) {
//     return error;
//   }
// };

authApi.signOut = async () => {
  try {
    // const data = {
    //   authorisationToken: getCookie("token"),
    // };
    // const cookieSignoutRes = await authApi.signOutCookie();
    // if (cookieSignoutRes.status === 200 || cookieSignoutRes.status === 401 || cookieSignoutRes.message === "Network Error") {
    const tokenSignoutRes = await authApi.signOutToken();
    if (tokenSignoutRes.status === 200) {
      // Cookies.remove("token");
      // Cookies.remove("reftoken");
      // Cookies.remove(".AspNetCore.IdentityScheme");
      // Cookies.remove("signin");
      window.location.href = "/signin";
    }

    return;
  } catch (error) {
    return error;
  }
};

export { authApi };
