/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { dashboardApi } from "../services/DashboardService";

function Dashboard() {
  const [active, setActive] = useState("Yesterday");
  const [orderData, setOrderData] = useState([]);
  const [commissionData, setCommissionData] = useState(0);
  let [startDate, setStartDate] = useState("");
  let [endDate, setEndDate] = useState("");
  const [searchObj, setSearchObj] = useState({ user: "", secret: "" });
  const [userSearched, setUserSearched] = useState(false);
  const [notification, setNotification] = useState({
    isOpen: false,
    type: "",
    message: "",
  });
  useEffect(() => {
    console.log(userSearched);
    if (userSearched) {
      handleSearch();
    } // Trigger handleSearch on component mount
  }, [active]);
  useEffect(() => {
    if (notification.isOpen) {
      setTimeout(() => {
        setNotification({
          ...notification,
          isOpen: false,
        });
      }, 2000);
    }
  }, [notification.isOpen]);

  const getOrders = async () => {
    try {
      const orderDetail = await dashboardApi.getOrderData({
        // "userName": userName,
        // "secret": secret,
        userName: searchObj.user,
        secret: searchObj.secret,
        startDate: startDate,
        endDate: endDate,
      });
      console.log("orderDetail", orderDetail);
      if (orderDetail.status === 200 && orderDetail.data.status === 200) {
        setOrderData(orderDetail?.data?.data);
        console.log(orderDetail?.data?.data);
        // setNotification({
        //   message: orderDetail?.data?.msg,
        //   isOpen: true,
        //   type: "success",
        // });
        setTimeout(() => {
          setUserSearched(true);
        }, 1000);
      } else throw orderDetail;
    } catch (error) {
      setNotification({
        message: error?.data.errors[0].msg,
        isOpen: true,
        type: "error",
      });
    }
    const orderDetail = await dashboardApi.getOrderData({
      // "userName": userName,
      // "secret": secret,
      userName: searchObj.user,
      secret: searchObj.secret,
      startDate: startDate,
      endDate: endDate,
    });
    console.log("orderDetail", orderDetail);
    if (orderDetail.status === 200 && orderDetail.data.status === 200) {
      setOrderData(orderDetail?.data?.data);
      console.log(orderDetail?.data?.data);
    } else if (orderDetail.status === 400) {
      setOrderData(null);
    }
  };
  const getCommission = async () => {
    const commissionDetails = await dashboardApi.getCommissionData({
      // "userName": userName,
      // "secret": secret,
      userName: searchObj.user,
      secret: searchObj.secret,
      startDate: startDate,
      endDate: endDate,
    });
    console.log("commissionDetails", commissionDetails);
    if (
      commissionDetails.status === 200 &&
      commissionDetails.data.status === 200
    ) {
      setCommissionData(commissionDetails?.data?.data);
      console.log(commissionDetails?.data?.data);
    } else if (commissionDetails.status === 400) {
      // Handle the scenario where the token is wrong
      setNotification({
        message: commissionDetails?.data.msg,
        isOpen: true,
        type: "error",
      });
      setCommissionData(0);
    }
  };
  const handleSearch = async () => {
    // let startDate = '';
    // let endDate = '';

    console.log("active", active);
    // Set start and end dates based on the active button
    switch (active) {
      case "Yesterday":
        const yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);
        startDate = yesterday.toISOString().split("T")[0];
        endDate = new Date().toISOString().split("T")[0];
        break;
      case "This Week":
        const startOfWeek = new Date(
          new Date().setDate(new Date().getDate() - new Date().getDay())
        );
        const endOfWeek = new Date(
          new Date().setDate(new Date().getDate() + (7 - new Date().getDay()))
        );
        startDate = startOfWeek.toISOString().split("T")[0];
        endDate = endOfWeek.toISOString().split("T")[0];
        break;
      case "This Month":
        startDate = new Date(new Date().setDate(1)).toISOString().split("T")[0];
        endDate = new Date(new Date().setMonth(new Date().getMonth() + 1, 1))
          .toISOString()
          .split("T")[0];
        break;
      case "Last Month":
        let today = new Date();
        today.setMonth(today.getMonth() - 1);
        today.setDate(1);
        startDate = today.toISOString().split("T")[0];
        let lastMonth = new Date();
        lastMonth.setDate(1);
        endDate = lastMonth.toISOString().split("T")[0];
        break;
      case "All":
        // You can set your own logic for all data
        break;
      default:
        break;
    }
    setStartDate(startDate);
    setEndDate(endDate);
    console.log("startDate", startDate);
    console.log("endDate", endDate);      
    await getOrders();
    await getCommission();
  };

  useEffect(() => {
    if (userSearched) {
      getOrders();
      getCommission();
    }
  }, [userSearched]);

  const handleChange = (e) => {
    setSearchObj({ ...searchObj, [e.target.name]: e.target.value });
  };

  // function copyToClipboard(text) {
  //   // Create a textarea element
  //   var textarea = document.createElement("textarea");
  //   // Set the text content to the provided text
  //   textarea.value = text;
  //   // Append the textarea to the DOM
  //   document.body.appendChild(textarea);
  //   // Select the text within the textarea
  //   textarea.select();
  //   // Copy the selected text to the clipboard
  //   document.execCommand("copy");
  //   // Remove the textarea from the DOM
  //   document.body.removeChild(textarea);
  // }
  return (
    <>
      <div
        className="notification-contr"
        style={{
          backgroundColor:
            notification.type === "success"
              ? "green"
              : notification.type === "error"
              ? "red"
              : "#05a7f2",
          left: "calc(50% - 15rem)",
          top: notification.isOpen ? "10px" : "0px",
          transition: "all 0.2s ease-in-out",
          opacity: notification.isOpen ? "1" : "0",
          overflow: "hidden",
          width: "fit-content",
          minWidth: "30rem",
        }}
      >
        <p
          role="button"
          onClick={() => {
            setNotification({
              ...notification,
              isOpen: false,
            });
          }}
        >
          x
        </p>
        <p style={{ width: "100%", paddingInline: "10px", textAlign: "left" }}>
          {notification.message}
        </p>
      </div>
      {userSearched ? (
        <div className="container-fluid dashboard">
          <h1>
            <i class="fa fa-dashboard"></i> Partner Dashboard
          </h1>
          <div className="filter-saction mb-3">
            <div className="filter-saction-tabs desktop-version">
              <Button
                className={`btn-secondary tab-btn${
                  active === "Yesterday" ? "-active" : ""
                }`}
                onClick={() => {
                  setActive("Yesterday");
                }}
              >
                <h6>Yesterday</h6>
              </Button>
              <Button
                className={`btn-secondary tab-btn${
                  active === "This Week" ? "-active" : ""
                }`}
                onClick={() => {
                  setActive("This Week");
                }}
              >
                <h6>This Week</h6>
              </Button>
              <Button
                className={`btn-secondary tab-btn${
                  active === "This Month" ? "-active" : ""
                }`}
                onClick={() => {
                  setActive("This Month");
                }}
              >
                <h6>This Month</h6>
              </Button>
              <Button
                className={`btn-secondary tab-btn${
                  active === "Last Month" ? "-active" : ""
                }`}
                onClick={() => {
                  setActive("Last Month");
                }}
              >
                <h6>Last Month</h6>
              </Button>

              {/* <Button className={`btn-secondary tab-btn${active === "All" ? '-active' : ''}`} onClick={() => setAcvtive('All')}><h6>All</h6></Button> */}
            </div>
            <select
              className="form-select filter-select mobile-version"
              name="yousend"
              id="yousend"
              onChange={(e) => setActive(e.target.value)}
            >
              <option
                value="Yesterday"
                key="Yesterday"
                selected={active === "Yesterday"}
              >
                Yesterday
              </option>
              <option
                value="This Week"
                key="This Week"
                selected={active === "This Week"}
              >
                This Week
              </option>
              <option
                value="This Month"
                key="This Month"
                selected={active === "This Month"}
              >
                This Month
              </option>
              <option
                value="Last Month"
                key="Last Month"
                selected={active === "Last Month"}
              >
                Last Month
              </option>
            </select>
          </div>
          <div className="summary-card">
            <h6>Your Earnings</h6>
            <h1>
              {commissionData !== null
                ? `${commissionData} USDT`
                : "Loading..."}
            </h1>
          </div>
          <br />
          <h1>
            <i class="fa fa-dollar"></i> Orders
          </h1>
          <div className="order-card">
            {orderData && orderData.length > 0 && (
              <table className="order-table">
                <thead>
                  <th>Order ID</th>
                  <th>Coin Send</th>
                  <th>Amount Send</th>
                  <th>Coin Received </th>
                  <th>Amount Received</th>
                  <th>Created On</th>
                  <th>Commission</th>
                </thead>
                <tbody>
                  {orderData.length ? (
                    orderData.map((item) => {
                      function copyToClipboard() {
                        var textarea = document.createElement("textarea");
                        textarea.value = item?.order_id;
                        document.body.appendChild(textarea);
                        textarea.select();
                        document.execCommand("copy");
                        document.body.removeChild(textarea);
                      }

                      return (
                        <>
                          <tr>
                            <td>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  paddingInline: "2px",
                                }}
                              >
                                <p className=""> {item?.order_id}</p>
                                <button onClick={copyToClipboard}>
                                  <i className=" ion ion-ios-copy" />
                                </button>
                              </div>
                            </td>
                            <td>{item?.coin_send}</td>
                            <td>{Number(item?.amount_send).toFixed(2)}</td>
                            <td>{item?.coin_receive}</td>
                            <td>{Number(item?.amount_receive).toFixed(2)}</td>
                            <td>{item?.created_at}</td>
                            <td>{Number(item?.commission).toFixed(2)}</td>
                          </tr>
                        </>
                      );
                    })
                  ) : (
                    <></>
                  )}
                </tbody>
              </table>
            )}
            {!orderData || (orderData.length === 0 && <p>No orders found.</p>)}
          </div>
        </div>
      ) : (
        <div className="login-page">
          <div className="login">
            <h2 className="login-title">Partner Dashboard</h2>
            <p className="notice">
              Enter partner credentials to get order details
            </p>
            <div className="input-email">
              <i className="fa fa-envelope icon" />
              <input
                className="input-1"
                type="text"
                value={searchObj.user}
                name="user"
                placeholder="Enter Partner Name"
                autoComplete={false}
                onChange={handleChange}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    const nextInput =
                      e.target.parentNode.nextSibling.children[1];
                    nextInput.focus();
                  }
                }}
              />
            </div>
            <div className="input-password">
              <i className="fa fa-lock icon" />
              <input
                className="input-1"
                type="password"
                value={searchObj.secret}
                name="secret"
                placeholder="Enter Secret Value"
                onChange={handleChange}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {  
                    handleSearch();
                  }
                }}
                autoComplete={false}
              />
            </div>

            <button type="button" className="logSubmit" onClick={handleSearch}>
              <i className="fa fa-door-open " /> Search
            </button>
          </div>

          <div className="background" />
        </div>
      )}
    </>
  );
}

export default Dashboard;
