/* eslint-disable no-useless-escape */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from "react";
import { Link, Redirect, useHistory } from "react-router-dom";
import { getCookie } from "../helpers/cookieHelper";
import { authApi } from "../services/AuthService";
import Cookies from 'js-cookie';

export default function reset() {
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [confirmpassword, setConfirmPassword] = useState("");
  const [confirmpasswordError, setConfirmPasswordError] = useState("");
  const [error, setError] = useState("");
  const [isLoader, setIsLoader] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [redirectValue, setRedirectValue] = useState("");

  useEffect(() => {
    if (getCookie("token")) {
      setRedirect(true);
      setRedirectValue("/");
    }
  },[]);

  const handleEmail = async (e) => {
    await setEmail(e.target.value);
    await setEmailError("");
  };

  const handlePassword = async (e) => {
    await setPassword(e.target.value);
    await setPasswordError("");
  };

  const handleConfirmPassword = async (e) => {
    if (password === null || password === "") {
      await setPasswordError("Please enter your password!");
    } else {
      if (e.target.value !== "") {
        await setConfirmPassword(e.target.value);
        if (password !== e.target.value) {
          await setConfirmPasswordError("Password does not match!");
        } else {
          await setConfirmPasswordError("");
        }
      } else {
        await setConfirmPassword("");
      }
    }
  };

  const handleEnter = async (e) => {
    if (e.key === "Enter") {
      handleSubmit();
    }
  };

  const handleSubmit = async () => {
    // alert();
    await setIsLoader(true);
    const regex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    const passwordRegx =
      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{12,}$/;
    if (email === "" || email === null) {
      await setIsLoader(false);
      await setEmailError("Email is required");
    } else if (!email || regex.test(email) === false) {
      await setIsLoader(false);
      await setEmailError("Email is not valid");
    } else if (password === "") {
      await setIsLoader(false);
      await setPasswordError("Password is required!");
    } else if (confirmpassword === "") {
      await setIsLoader(false);
      await setConfirmPasswordError("Confirm Password please");
    } else if (password !== confirmpassword) {
      await setIsLoader(false);
      await setConfirmPasswordError("Password does not match");
    } else if (password.length < 12) {
      await setIsLoader(false);
      await setError("Password should contain 12 characters minimum");
    } else if (!passwordRegx.test(password)) {
      await setIsLoader(false);
      await setError(
        "Password should contain one uppercase, lowercase, number and symbol"
      );
    } else {
      const data = {
        email,
        password,
        session: Cookies.get("NEW_PASSWORD_REQUIRED"),
      };
      const getForcePasswordChange = await authApi.forcePasswordChange(data);
      // console.log('getForcePasswordChange = ', getForcePasswordChange);
      if (getForcePasswordChange.status === 200) {
        
        // if (getForcePasswordChange.status === 200 && getForcePasswordChange.data) {
          if (getForcePasswordChange.status === 200 && getForcePasswordChange.data.ChallengeName === "MFA_SETUP") {
            Cookies.remove('NEW_PASSWORD_REQUIRED');
            Cookies.set('MFA_SETUP', getForcePasswordChange.data.Session, { secure: true, sameSite: 'strict' })

            const mfaSetupData = {
              email,
              session: Cookies.get("MFA_SETUP"),
            };
            const getMfaSetup = await authApi.mfaSetup(mfaSetupData);
            if (getMfaSetup.status === 200) {
              if (getMfaSetup.data) {
                Cookies.remove('MFA_SETUP');
                Cookies.set('EMAIL', getMfaSetup.data.email, { secure: true, sameSite: 'strict' });
                Cookies.set('MFA_FOR_NEW_USER', getMfaSetup.data.session, { secure: true, sameSite: 'strict' });
                Cookies.set('CODE', getMfaSetup.data.code, { secure: true, sameSite: 'strict' });
                Cookies.set('QrCode', getMfaSetup.data.data, { secure: true, sameSite: 'strict' });
                await setIsLoader(false);
                history.replace({
                  pathname: "/mfa-verify-new-user",
                });
              }
              if (
                getMfaSetup.data.status === 500 &&
                getMfaSetup.data.success === false
              ) {
                setError(getMfaSetup.data.errors[0].msg);
              }
            }
          }
        // } else {
        //   await setIsLoader(false);
        //   setError("Something wrong !");
        // }
        if (getForcePasswordChange.data.status === 400 && getForcePasswordChange.data.success === false) {
          if (getForcePasswordChange.data.errors[0].msg === 'Invalid session for the user, session is expired.') {
            await Cookies.remove('NEW_PASSWORD_REQUIRED');
            history.replace({
              pathname: "/signin",
              state: {
                message: 'Session is expired, Please signin again!',
              },
            });
          }
        }
      }
    }
  };

  return (
    <>
      {isLoader ? (
        <div className="coin-loader-wrap" style={{ zIndex: "999999" }}>
          <div className="coin-loader">
            <span className="coin-loader-img">
              <img src={process.env.REACT_APP_LOADERS} alt="loader" />
            </span>
          </div>
        </div>
      ) : (
        ""
      )}
      {redirect && <Redirect to={redirectValue} />}
      <div className="login-page">
        <div className="login">
          <h2 className="login-title">Reset Your Password</h2>
          {error !== "" ? <p className="errorMsg">{error}</p> : ""}
          <div className="input-email">
            <i className="fa fa-envelope icon" />
            <input
              className="input-1"
              type="email"
              value={email}
              name="email"
              placeholder="Enter your e-mail"
              onChange={handleEmail}
              onKeyPress={handleEnter}
            />
            {emailError !== "" ? <p className="errorMsg">{emailError}</p> : ""}
          </div>
          <div className="input-password">
            <i className="fa fa-lock icon" />
            <input
              className="input-1"
              type="password"
              value={password}
              name="password"
              placeholder="Enter your password"
              onChange={handlePassword}
              onKeyPress={handleEnter}
            />
            {passwordError !== "" ? (
              <p className="errorMsg">{passwordError}</p>
            ) : (
              ""
            )}
          </div>
          <div className="input-password">
            <i className="fa fa-lock icon" />
            <input
              className="input-1"
              type="password"
              value={confirmpassword}
              name="confirmpassword"
              placeholder="Confirm your password"
              onChange={handleConfirmPassword}
              onKeyPress={handleEnter}
            />
            {confirmpasswordError !== "" ? (
              <p className="errorMsg">{confirmpasswordError}</p>
            ) : (
              ""
            )}
          </div>

          <button type="button" className="logSubmit" onClick={handleSubmit}>
            <i className="fa fa-door-open " /> Reset
          </button>
          <p>
            Remember Password? <Link to="/signup"> Sign in here</Link>
          </p>
        </div>
        <div className="background" />
      </div>
    </>
  );
}
