/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react";
import { Tabs, Tab } from "react-bootstrap";
import { getInstruments, getTicker } from "../actions/market";
import MarketCarousel from "../components/MarketCarousel";
export default function MarketsList() {
  const [instruments, setInstruments] = useState([]);
  const [tokens, setTokens] = useState(["all", "btc", "usdt"]);
  const [tradeList, setTradeList] = useState([]);
  const [starList, setStarList] = useState([]); // Favorite pairs
  const [isLoader, setIsLoader] = useState(true);
  const [tickerUsdt, setTickerUsdt] = useState([]);
  const isDataLoadedRef = useRef(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await getInstruments();
        setInstruments(res);
      } catch (error) {
        console.error("Error fetching instruments:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (instruments.length && !isDataLoadedRef.current) {
      loadData();
      isDataLoadedRef.current = true;
    }
  }, [instruments]);

  const loadData = async () => {
    try {
      const tickers = await getTicker();
      const updatedTrades = tokens.map((token, tindex) => {
        let tokenTrades = [];
        instruments.forEach((instrument) => {
          if (token === "all" || instrument.includes(`_${token}`)) {
            const trade = tickers.find((t) => t.instrument === instrument);
            if (trade) {
              trade.price = trade.close;
              trade.origin = trade.open;
              trade.change = trade.price - trade.origin;
              trade.percentage = (trade.change * 100) / trade.origin;
              tokenTrades.push(trade);
              if (instrument.includes("usdt") && token === "all") setTickerUsdt((prev) => [...prev, trade]);
            }
          }
        });
        return tokenTrades;
      });
      setTradeList((prevTradeList) => {
        return prevTradeList.length ? prevTradeList.map((list, i) => updatedTrades[i] || list) : updatedTrades;
      });
      setIsLoader(false);
    } catch (error) {
      console.error("Error loading data:", error);
    }
    };

  useEffect(() => {
    const interval = setInterval(() => {
      if (!isLoader) loadData();
    }, 20000);

    return () => clearInterval(interval);
  }, [isLoader]);

  function getBest() {
    const sortedList = [...tickerUsdt].sort((a, b) => b.percentage - a.percentage).slice(0, 8);
    setTickerUsdt(sortedList);
  }

  function getWorst() {
    const sortedList = [...tickerUsdt].sort((a, b) => a.percentage - b.percentage).slice(0, 8);
    setTickerUsdt(sortedList);
  }

  return (
    <>
      {isLoader ? (
        <div className="coin-loader-wrap">
          <div className="coin-loader">
            <span className="coin-loader-img">
              <img src={process.env.REACT_APP_LOADERS} />
            </span>
          </div>
        </div>
      ) : null}
      <div className="markets pb70">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <MarketCarousel getBest={getBest} getWorst={getWorst} ticker={tickerUsdt} />
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="markets-pair-list">
                <Tabs defaultActiveKey="btc">
                  <Tab eventKey="favorites" title="★ Favorites">
                    <div className="table-responsive">
                      <table className="table star-active">
                        <thead>
                          <tr>
                            <th>Pairs</th>
                            <th>Last Price</th>
                            <th>Change (24H)</th>
                            <th>High (24H)</th>
                            <th>Low (24h)</th>
                            <th>Volume (24h)</th>
                          </tr>
                        </thead>
                        <tbody>
                          {starList?.map((star, index) => {
                            let trade = tradeList[star.tokenIndex][star.tradeIndex];
                            return (
                              <tr key={index}>
                                <td>
                                  <i className="icon ion-md-star"></i>{" "}
                                  {trade.instrument.toUpperCase().replace("_", "/")}
                                </td>
                                <td>{trade.price}</td>
                                <td className={trade.change < 0 ? "red" : "green"}>
                                  {trade.percentage.toFixed(2)}%
                                </td>
                                <td>{trade.high}</td>
                                <td>{trade.low}</td>
                                <td>{trade.volume.toFixed(4)}</td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </Tab>
                  {tokens.map((token, tindex) => (
                    <Tab eventKey={token} title={token.toUpperCase()} key={tindex}>
                      <div className="table-responsive">
                        <table className="table table-hover">
                          <thead>
                            <tr>
                              <th>Pairs</th>
                              <th>Last Price</th>
                              <th>Change (24H)</th>
                              <th>High (24H)</th>
                              <th>Low (24h)</th>
                              <th>Volume (24h)</th>
                            </tr>
                          </thead>
                          <tbody>
                            {tradeList[tindex]?.map((trade, index) => (
                              <tr key={index}>
                                <td>
                                  <i
                                    className={`icon ion-md-star ${
                                      starList.some(
                                        (star) => star.tokenIndex === tindex && star.tradeIndex === index
                                      )
                                        ? "active"
                                        : ""
                                    }`}
                                    onClick={() => {
                                      const isFavorite = starList.some(
                                        (star) => star.tokenIndex === tindex && star.tradeIndex === index
                                      );
                                      setStarList(
                                        isFavorite
                                          ? starList.filter(
                                              (star) =>
                                                !(star.tokenIndex === tindex && star.tradeIndex === index)
                                            )
                                          : [...starList, { tokenIndex: tindex, tradeIndex: index }]
                                      );
                                    }}
                                  ></i>{" "}
                                  {trade.instrument.toUpperCase().replace("_", "/")}
                                </td>
                                <td>{trade.price}</td>
                                <td className={trade.change < 0 ? "red" : "green"}>
                                  {trade.percentage.toFixed(2)}%
                                </td>
                                <td>{trade.high}</td>
                                <td>{trade.low}</td>
                                <td>{trade.volume.toFixed(4)}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </Tab>
                  ))}
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
