import React from "react";
import Header from "./home/Header";
import { useRouteMatch, useLocation } from "react-router-dom";
import Bottom from "./home/Bottom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "../assets/css/ionicons.min.css";
import "../assets/css/line-awesome/css/line-awesome.min.css";
import "../assets/css/font-awesome/css/font-awesome.min.css";
import "../assets/css/material-design-iconic-font/css/materialdesignicons.min.css";
export default function Layout({ children, onSearch }) {
  const matchHomepage = useRouteMatch("/");
  const isPwa = window.matchMedia("(display-mode: standalone)");

  const homePage = matchHomepage && matchHomepage.isExact;
  const queryParams = new URLSearchParams(useLocation().search);
  const handleSearch = (e) => {
    onSearch(e.target.value);
  };

  if (queryParams && queryParams.get("ref")) {
    sessionStorage.setItem("ref", queryParams.get("ref"));
  }
  // const matchLogin = useRouteMatch('/login')
  // const loginPage = matchLogin && matchLogin.isExact

  // const matchSignup = useRouteMatch('/signup')
  // const signupPage = matchSignup && matchSignup.isExact

  // if (matchLogin || signupPage) {
  //   return (
  //     <>
  //       {children}
  //     </>
  // )}

  return isPwa.matches ? (
    <>
      {!homePage && <Header onSearch={handleSearch} />}
      <div
        style={{
          height: "92vh",
          overflowY: "scroll",
          overflowX: "hidden",
        }}
      >
        {" "}
        {React.cloneElement(children)}
      </div>
      <Bottom />
    </>
  ) : (
    <>
      {" "}
      {!homePage && <Header />}
      {children}
    </>
  );
}
