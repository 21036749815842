import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { orderApi, xmrTransAPI, xmrbalAPI } from "../../services/OrderService";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";

import { adminAuthApi } from "../../services/PendingOrderService";
import AccordionItem from "react-bootstrap/esm/AccordionItem";
import AccordionHeader from "react-bootstrap/esm/AccordionHeader";
import AccordionBody from "react-bootstrap/esm/AccordionBody";
class PendingOrders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pendingOrders: [],
      xmrBal: [],

      xmrTrans: [],
      loader: false,
      isError: false,
      coied: "",
      notification: { isOpen: false, type: "", message: "" },
      webAuth: () => {
        // eslint-disable-next-line no-undef
        const webAuth = SimpleWebAuthnBrowser;
        return webAuth;
      },
    };
  }

  async componentDidMount() {
    // await this.getPendingOrders();
    // await this.getXmrBal();
    // await this.getXmrTrans();

    await this.fetchAllData()
  }

  async componentDidUpdate() {
    if (this.state.notification.isOpen) {
      setTimeout(() => {
        this.setState({
          ...this.state,
          notification: {
            ...this.state.notification,
            isOpen: false,
            message: "",
            color: "",
          },
        });
      }, 2000);
    }
  }

  showNotification = (message, type = "success") => {
    this.setState({
      notification: {
        ...this.state.notification,
        isOpen: true,
        message: message,
        type,
      },
    });
  };

  verifyReg = async () => {
    try {
      this.setState({ ...this.state, loader: true });
      const adminAuthRes = await adminAuthApi.getAdminRegOpt();
      if (adminAuthRes.status !== 200) throw adminAuthRes;
      const adminRegDetails = await adminAuthRes.data.data;

      try {
        const regDetails = await this.state
          .webAuth()
          .startRegistration(await adminRegDetails);
        const verfiedData = await adminAuthApi.verify(
          { ...regDetails },
          adminRegDetails.challenge
        );
        this.showNotification(verfiedData.data.msg, "success");

        this.setState({ ...this.state, loader: true });
      } catch (error) {
        console.log(error);
      }
    } catch (error) {
      console.log("simpleWebAuthn ERROR: ", error);
      this.showNotification(error.data.msg, "error");
    }
  };

  // Single function Calling APIs at in Concurrent API Calls

  fetchAllData = async () => {
    this.setState({ loader: true });

    try {
      // Fetch pending orders
      const pendingOrderResponse = orderApi.getPendingOrders({});
      pendingOrderResponse
        .then(response => {
          if (response.status === 200) {
            this.setState({ pendingOrders: response.data?.data });
          } else {
            this.setState({ pendingOrders: [] });
          }
        })
        .catch(error => {
          console.error("Error fetching pending orders:", error);
          this.setState({ pendingOrders: [] });
        });

      // Fetch XMR balance
      const xmrBalRes = xmrbalAPI.getXmrBalance();
      xmrBalRes
        .then(response => {
          if (response.status === 200) {
            this.setState({ xmrBal: [response.data?.data] });
          } else {
            this.setState({ xmrBal: [] });
          }
        })
        .catch(error => {
          console.error("Error fetching XMR balance:", error);
          this.setState({ xmrBal: [] });
        });

      // Fetch XMR transactions
      const xmrTransRes = xmrTransAPI.getXmrTrans();
      xmrTransRes
        .then(response => {
          if (response.status === 200) {
            this.setState({ xmrTrans: response.data?.data?.data });
          } else {
            this.setState({ xmrTrans: [] });
          }
        })
        .catch(error => {
          console.error("Error fetching XMR transactions:", error);
          this.setState({ xmrTrans: [] });
        });

      // Set loader to false after all API calls are attempted
      Promise.all([pendingOrderResponse, xmrBalRes, xmrTransRes])
        .then(() => {
          this.setState({ loader: false });
        })
        .catch(() => {
          this.setState({ loader: false });
        });

    } catch (error) {
      console.error("Error in fetchAllData:", error);
      this.setState({
        pendingOrders: [],
        xmrBal: [],
        xmrTrans: [],
        loader: false
      });
    }
  };

  // ::: OLD CODE FOR CALLING APIs (PendingOrders, XMRBal, XMRTrans) Saprately calling which will take time ::: 


  // Pending Orders

  // getPendingOrders = async () => {
  //   this.setState({ ...this.state, loader: true });

  //   try {
  //     const pendingOrderResponse = await orderApi.getPendingOrders({});
  //     console.log("pendingOrderResponse", pendingOrderResponse.data?.data[0]);
  //     if (pendingOrderResponse.status === 200) {
  //       await this.setState({ pendingOrders: pendingOrderResponse.data?.data });
  //       this.setState({ loader: false });
  //     }
  //   } catch (error) {
  //     await this.setState({ pendingOrders: [] });
  //     this.setState({ ...this.state, loader: true });
  //   }
  // };
  // XMR Balance API

  // getXmrBal = async () => {
  //   this.setState({ loader: true }); // Set loader to true before API call

  //   try {
  //     const xmrBalRes = await xmrbalAPI.getXmrBalance();
  //     console.log("xmrBalRes", xmrBalRes.data?.data);
  //     if (xmrBalRes.status === 200) {
  //       // Set both xmrBal and loader state correctly
  //       await this.setState({ xmrBal: [xmrBalRes.data?.data], loader: false });
  //     } else {
  //       // Set loader to false if status is not 200
  //       await this.setState({ loader: false });
  //     }
  //   } catch (error) {
  //     console.error(error); // Added console error for better debugging
  //     // Set xmrBal to an empty array and loader to false in case of error
  //     this.setState({ xmrBal: [], loader: false });
  //   }
  // };

  // XMR Trans API
  // getXmrTrans = async () => {
  //   this.setState({ ...this.state, loader: true });

  //   try {
  //     const xmrTransRes = await xmrTransAPI.getXmrTrans();
  //     console.log("xmrTransRes.data", xmrTransRes.data);
  //     console.log("xmrTransRes", xmrTransRes.data?.data?.data);
  //     if (xmrTransRes.status === 200) {
  //       await this.setState({ xmrTrans: xmrTransRes.data?.data?.data });

  //       this.setState({ loader: false });
  //     }
  //   } catch (error) {
  //     await this.setState({ xmrTrans: [] });
  //     this.setState({ ...this.state, loader: true });
  //   }
  // };

  handleCopyToClipboard = (content) => {
    navigator.clipboard.writeText(content);
    this.setState({ ...this.state, copied: "Copied!" });
    setTimeout(() => {
      this.setState({ ...this.state, copied: "" });
    }, 1500);
  };

  handleOrderApprove = async (order_id) => {
    this.setState({ loader: true });
    try {
      const resForApprove = await orderApi.getApprovePendingOrders();

      const authDetails = await this.state
        .webAuth()
        .startAuthentication(resForApprove.data.data);

      if (order_id) {
        authDetails.orderId = order_id;
      }

      const approvedData = await orderApi.approvePendingOrders(authDetails);
      this.showNotification(approvedData.data.msg, "success");
    } catch (error) {
      this.showNotification(error.data.msg, "error");
      this.setState({ loader: false, isError: true });
    }
  };

  handleTransactionClick = (transactionId) => {
    this.props.history.push(`/transaction/${transactionId}`);
  };

  render() {
    const { notification } = this.state;

    return (
      <>
        <div
          className="notification-contr"
          style={{
            backgroundColor:
              notification.type === "success"
                ? "green"
                : notification.type === "error"
                  ? "red"
                  : "#05a7f2",
            left: "calc( 50% - 10rem )",
            top: notification.isOpen ? "10px" : "0px",
            visibility: notification.isOpen ? "visible" : "hidden",
            transition: "all 0.2s ease-in-out",
            width: "fit-content",
          }}
        >
          <p
            role="button"
            onClick={() => {
              this.setState({
                ...this.state,
                notification: {
                  ...this.state.notification,
                  isOpen: false,
                  messgae: "",
                  color: "",
                },
              });
            }}
          >
            x
          </p>
          <p style={{ width: "fit-content" }}>
            {this.state.notification.message}
          </p>
        </div>

        {/* XMR Orders */}

        <div role="button" className="verfy-btn" onClick={this.verifyReg}>
          verify
        </div>

        <div className="container-fluid admin-pending-order">
          <Accordion defaultActiveKey={0}>
            <AccordionItem eventKey="0">
              <AccordionHeader className="admin-pendng-order-title-wrapper">
                <h1>
                  Pending Orders
                </h1>
              </AccordionHeader>
              <div className="order-card">
                <AccordionBody>
                  {this.state.pendingOrders &&
                    this.state.pendingOrders.length > 0 && (
                      <table className="pending-order-table">
                        <thead>
                          <th>Order ID</th>
                          <th>Coin Send</th>
                          <th>Network</th>
                          <th>User Send ($USD)</th>
                          <th>User Receive ($USD)</th>
                          <th>Coin Receive </th>
                          <th>Network</th>
                          {/* <th>IURA</th> */}
                          <th>Recipient</th>
                          <th>Server Address</th>
                          <th>Trans ID</th>
                          <th>Action</th>
                        </thead>
                        <tbody>
                          {this.state.pendingOrders.length ? (
                            this.state.pendingOrders.map((item, index) => (
                              <tr key={index}>
                                <OverlayTrigger
                                  placement="top"
                                  overlay={
                                    <Tooltip id={`tooltip-copy-${index}`}>
                                      {this.state.copied || item.order_id}
                                    </Tooltip>
                                  }
                                >
                                  <td
                                    onClick={() =>
                                      this.handleCopyToClipboard(item.order_id)
                                    }
                                  >
                                    {item.order_id
                                      ? `${item.order_id.substr(0, 4)} ... ${item.order_id.substr(item.order_id.length - 4)}`
                                      : "N/A"}
                                  </td>
                                </OverlayTrigger>
                                <td>{item?.coin_send}</td>
                                <td>{item?.send_network_name}</td>
                                <td>{`${Number(item?.amount_send).toFixed(4)} (${Number(item?.price_send).toFixed(2)})`}</td>
                                <td>{`${Number(item?.amount_receive).toFixed(4)} (${Number(item?.price_receive).toFixed(4)})`}</td>
                                <td>{item?.coin_receive}</td>
                                <td>{item?.receive_network_name}</td>
                                <OverlayTrigger
                                  placement="top"
                                  overlay={
                                    <Tooltip id={`tooltip-copy-${index}`}>{this.state.copied || item.recipient}</Tooltip>
                                  }
                                >
                                  <td
                                    onClick={() =>
                                      this.handleCopyToClipboard(item.recipient)
                                    }
                                  >
                                    {item.recipient
                                      ? `${item.recipient.substr(0, 4)} ... ${item.recipient.substr(item.recipient.length - 4)}`
                                      : "N/A"}
                                  </td>
                                </OverlayTrigger>
                                <OverlayTrigger
                                  placement="top"
                                  overlay={
                                    <Tooltip id={`tooltip-copy-${index}`}>
                                      {this.state.copied || item.server_address}
                                    </Tooltip>
                                  }
                                >
                                  <td
                                    onClick={() =>
                                      this.handleCopyToClipboard(item.server_address)
                                    }
                                  >
                                    {item.server_address
                                      ? `${item.server_address.substr(0, 4)} ... ${item.server_address.substr(item.server_address.length - 4)}`
                                      : "N/A"}
                                  </td>
                                </OverlayTrigger>
                                <OverlayTrigger
                                  placement="top"
                                  overlay={
                                    <Tooltip id={`tooltip-copy-${index}`}>
                                      {this.state.copied || item.transId}
                                    </Tooltip>
                                  }
                                >
                                  <td
                                    onClick={() =>
                                      this.handleCopyToClipboard(item.transId)
                                    }
                                  >
                                    {item.transId
                                      ? `${item.transId.substr(0, 4)} ... ${item.transId.substr(item.transId.length - 4)}`
                                      : "N/A"}
                                  </td>
                                </OverlayTrigger>
                                {item.approveRequired && (
                                  <Button
                                    size="sm"
                                    className="approve-btn"
                                    onClick={async () =>
                                      await this.handleOrderApprove(item.order_id)
                                    }
                                  >
                                    Approve
                                  </Button>
                                )}
                              </tr>
                            ))
                          ) : (
                            <></>
                          )}
                        </tbody>
                      </table>
                    )}
                  {!this.state.pendingOrders ||
                    (this.state.pendingOrders.length === 0 && (
                      <p>No orders found</p>
                    ))}
                </AccordionBody>
              </div>
            </AccordionItem>
          </Accordion>
        </div>

        {/* XMR Balance  */}

        <div className="container-fluid admin-pending-order">
          <Accordion defaultActiveKey={0}>
            <AccordionItem eventKey="0">
              <AccordionHeader className="admin-pendng-order-title-wrapper">
                <h1>
                  XMR Balance
                </h1>
              </AccordionHeader>

              <div className="order-card">
                <AccordionBody>
                  {this.state.loader && <p>Loading...</p>}{" "}
                  {/*   Show loading message when loader is true */}
                  {this.state.xmrBal &&
                    this.state.xmrBal.length > 0 && ( // Check if xmrBal has data
                      <table className="pending-order-table">
                        <thead>
                          <tr>
                            <th>Total Balance</th>
                            <th>Total Balance $(USD)</th>
                            <th>Unlocked Balance</th>
                            <th>Unlocked Balance $(USD)</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.xmrBal.map((item, index) => (
                            <tr key={index}>
                              <td>{item.TotalBalance}</td>
                              <td>{item.TotalBalanceUSD}</td>
                              <td>{item.UnlockedBalance}</td>
                              <td>{item.UnlockedUSD}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    )}
                  {
                    // !this.state.xmrBal ||  (this.state.xmrBal.length === 0 && <p>No balance found</p>)}
                    !this.state.loader && this.state.xmrBal.length === 0 && (
                      <p>No balance found</p>
                    )
                  }{" "}
                  {/*Show message if no data and not loading*/}
                </AccordionBody>
              </div>
            </AccordionItem>
          </Accordion>
        </div>
        {/* XMR Transactions */}

        <div className="container-fluid admin-pending-order">
          <Accordion defaultActiveKey={0}>
            <AccordionItem eventKey="0">
              <AccordionHeader className="admin-pendng-order-title-wrapper">
                <h1>
                  XMR Transactions
                </h1>
              </AccordionHeader>
              <div className="order-card">
                <AccordionBody>
                  {this.state.xmrTrans && this.state.xmrTrans.length > 0 && (
                    <table className="pending-order-table">
                      <thead>
                        <th>#</th>
                        <th>Time </th>
                        <th>Amount</th>
                        <th>Direction</th>
                        <th>Conformations</th>
                      </thead>
                      <tbody>
                        {this.state.xmrTrans.length ? (
                          this.state.xmrTrans.map((item, index) => (
                            <>
                              <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{item.timestamp}</td>
                                <td>{item.amount}</td>
                                <td className={item.direction === 'incoming' ? 'greenyellow-text' : 'red-text'}>{item.direction}</td>
                                <td>{item.confirmations}</td>
                              </tr>
                            </>
                          ))
                        ) : (
                          <></>
                        )}
                      </tbody>
                    </table>
                  )}
                  {!this.state.xmrTrans ||
                    (this.state.xmrTrans.length === 0 && (
                      <p>Loading...</p>
                    ))}
                </AccordionBody>
              </div>
            </AccordionItem>
          </Accordion>
        </div>
      </>
    );
  }
}

export default withRouter(PendingOrders);
