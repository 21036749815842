import { Col, Container, Row } from 'react-bootstrap';
// import FeatherIcon from 'feather-icons-react';

// images
// import amazon from '../../../assets/images/brands/amazon.svg';
// import google from '../../../assets/images/brands/google.svg';
// import paypal from '../../../assets/images/brands/paypal.svg';
// import spotify from '../../../assets/images/brands/spotify.svg';
// import shopify from '../../../assets/images/brands/shopify.svg';
import Security from "../../../assets/images/securityimg.png";

// const brands = [amazon, google, paypal, spotify, shopify];

const ClientsReview = () => {
    return (
        <section className="pt-8 pt-sm-10 pb-lg-5 pb-4">
            <Container>
                <Row>

                    <Col lg={6}>
                        <div data-aos="fade-up" data-aos-duration="600">
                            <img src={Security} alt="" />
                        </div>
                    </Col>
                    <Col lg={6}>
                        <div data-aos="fade-up" data-aos-duration="600">
                            <h1 className="display-5 fw-medium mb-2 clrwhite">World Class Security </h1>
                            <p className="fw-medium fs-16 clrwhite">Swaptrade considers industry best practices the minimum standard. Swaptrade will not aspire to simply follow what others say is the best practice, instead Swaptrade will seek security innovation through uncommon thinking.</p>
                            <p className="fw-medium fs-16 clrwhite">Providing world class security isn’t enough however if Swaptrade, its leadership and its staff, do not adhere to the principles of transparency. The operations, actions and communications must be delivered with honesty and integrity, consistently. Only then can we build the required trust within the crypto community.</p>
                        </div>
                    </Col>

                </Row>
            </Container>
        </section>
    );
};

export default ClientsReview;