import { useEffect } from "react";

const Contact = () => {
  useEffect(() => {
    window.location.href = "https://help.swaptrade.io/contact";
  }, []);

  return null;
};

export default Contact;
