import { Col, Container, Row } from "react-bootstrap";
import { ParallaxBanner, ParallaxProvider } from "react-scroll-parallax";

// images
import coworking from "../../../assets/images/earth.jpg";

const Hero9 = () => {
  return (
    <section className="position-relative hero-9">
      <div className="hero-top">
        <Container>
          <Row className="py-7">
            <Col>
              <h1 className="hero-title fw-bold clrwhite">
                We are on a mission to{" "}
                <span className="">
                  revolutionize
                </span>{" "}
                the crypto exchange
              </h1>
              <p className="mt-3 fs-17 clrwhite">
                We believe in user choice. If a user doesn’t feel safe leaving
                their funds on our custodial exchange, we will never force them
                as they can place an order on our non-custodial swap platform.
              </p>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="hero-bottom">
        <ParallaxProvider>
          <ParallaxBanner
            layers={[
              {
                image: coworking,
                speed: -55,
                style: { backgroundSize: "auto" }
              }
            ]}
            className="hero-image"
          ></ParallaxBanner>
        </ParallaxProvider>
      </div>
    </section>
  );
};

export default Hero9;
