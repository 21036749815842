/* eslint-disable no-useless-escape */
import React, { useState } from "react";
import OtpInput from "react-otp-input";
import { authApi } from "../services/AuthService";
import { useHistory } from "react-router-dom";

export default function ForgotPassword() {
  let history = useHistory();
  const [email, set_email] = useState("");
  const [isLoader, setIsLoader] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [otp_flag, setotp_flag] = useState(false);
  const [OTP, setOTP] = useState("");
  const [OTPError, setOTPError] = useState("");
  const [password, setPassword] = useState("");
  const [confPassword, setConfPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [confpasswordError, setConfPasswordError] = useState("");
  const [error, setError] = useState("");

  const isPwa = window.matchMedia("(display-mode: standalone)");
  const handleSubmit = async () => {
    await setIsLoader(true);
    const regex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    var data = {};
    if (email === "" || email === null) {
      await setIsLoader(false);
      await setEmailError("Email is required!");
    } else if (!email || regex.test(email) === false) {
      await setIsLoader(false);
      await setEmailError("Email is not valid!");
    } else {
      data = {
        email: email,
      };
      const getLogin = await authApi.forgotPassword(data);
      if (getLogin.status === 200) {
        await setIsLoader(false);
        setotp_flag(true);
      }
    }
  };

  const handleOTP = async () => {
    setOTPError("");
    setPasswordError("");
    setConfPasswordError("");
    setError("");
    if (OTP === "" || OTP === null) {
      setOTPError("Please enter the otp !");
    } else if (password === "" || password === null) {
      setPasswordError("Please enter the new password !");
    } else if (confPassword === "" || confPassword === null) {
      setConfPasswordError("Please confirm password!");
    } else if (confPassword !== password) {
      setConfPasswordError("Password does not match!");
    } else {
      const data = {
        email: email,
        code: OTP,
        password: password,
      };
      const verifyOTP = await authApi.verifyOTP(data);
      if (verifyOTP.status === 200) {
        if (verifyOTP.data.status === 500 && verifyOTP.data.success === false) {
          setError(
            "Invalid OTP or Password! Password must be 12 characters with Upper + Lower case letters, Numbers and Symbols."
          );
        } else {
          history.push("/signin");
        }
      }
    }
  };

  const handleConfPassword = async (e) => {
    setPasswordError("");
    setConfPasswordError("");
    const confirmPassword = e.target.value;
    setConfPassword(confirmPassword);
    if (password === "" || password === null) {
      setPasswordError("Please enter the new password !");
    } else if (confirmPassword !== password) {
      setConfPasswordError("Password does not match!");
    } else {
      setConfPasswordError("");
    }
  };

  return isPwa.matches ? (
    <>
      {isLoader ? (
        <div className="coin-loader-wrap" style={{ zIndex: "999999" }}>
          <div className="coin-loader">
            <span className="coin-loader-img">
              <img src={process.env.REACT_APP_LOADERS} alt="loader" />
            </span>
          </div>
        </div>
      ) : (
        ""
      )}
      <div className="forgot-pass-main-container">
        <div className="forgot-pass-sub-container-pwa">
          <h2>Forgotten Your Password ?</h2>
          {otp_flag ? (
            <div>
              <p className="forgot-pass-subheading-pwa">
                If your user exists, we'll mail you a 6 digit code to reset your
                password
              </p>
            </div>
          ) : (
            <div>
              <p className="forgot-pass-subheading-pwa">
                We'll mail you a OTP to reset your password
              </p>
            </div>
          )}

          <div className="forgot-pass-body-container-pwa">
            {!otp_flag ? (
              <div className="input-email-pwa">
                <i className="fa fa-envelope icon" />
                <input
                  className="input-1"
                  type="email"
                  name="email"
                  placeholder=" Enter your e-mail"
                  onChange={(e) => set_email(e.target.value)}
                />
                {emailError !== "" ? (
                  <p className="errorMsg">{emailError}</p>
                ) : (
                  ""
                )}
              </div>
            ) : (
              <>
                {error !== "" ? <p className="errorMsg">{error}</p> : ""}
                <div className="otp-box">
                  <OtpInput
                    value={OTP}
                    onChange={setOTP}
                    numInputs={6}
                    separator={<span>-</span>}
                    className="otp-container"
                  />
                  {OTPError !== "" ? (
                    <p className="errorMsg">{OTPError}</p>
                  ) : (
                    ""
                  )}
                </div>
                <div className="input-email">
                  <i className="fa fa-lock icon" />
                  <input
                    className="input-1"
                    type="password"
                    name="password"
                    placeholder="Enter your New password"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  {passwordError !== "" ? (
                    <p className="errorMsg">{passwordError}</p>
                  ) : (
                    ""
                  )}
                </div>
                <div className="input-email">
                  <i className="fa fa-lock icon" />
                  <input
                    className="input-1"
                    type="password"
                    name="confpassword"
                    placeholder="Please confirm password!"
                    value={confPassword}
                    onChange={handleConfPassword}
                    // onChange={(e) => setConfPassword(e.target.value)}
                  />
                  {confpasswordError !== "" ? (
                    <p className="errorMsg">{confpasswordError}</p>
                  ) : (
                    ""
                  )}
                </div>
              </>
            )}
            {!otp_flag ? (
              <>
                {" "}
                <button className="sendotp-pwa" onClick={handleSubmit}>
                  Send me a reset password OTP
                </button>
              </>
            ) : (
              <>
                {" "}
                <button className="sendotp" onClick={handleOTP}>
                  Verify OTP
                </button>
              </>
            )}
          </div>
        </div>

        <div className="background" />
      </div>
    </>
  ) : (
    <>
      {isLoader ? (
        <div className="coin-loader-wrap" style={{ zIndex: "999999" }}>
          <div className="coin-loader">
            <span className="coin-loader-img">
              <img src={process.env.REACT_APP_LOADERS} alt="loader" />
            </span>
          </div>
        </div>
      ) : (
        ""
      )}
      <div className="forgot-pass-main-container">
        <div className="forgot-pass-sub-container">
          <h2>Forgotten Your Password ?</h2>
          {otp_flag ? (
            <div>
              <p className="forgot-pass-subheading">
                If your user exists, we'll mail you a 6 digit code to reset your
                password
              </p>
            </div>
          ) : (
            <div>
              <p className="forgot-pass-subheading">
                We'll mail you a OTP to reset your password
              </p>
            </div>
          )}

          <div className="forgot-pass-body-container">
            {!otp_flag ? (
              <div className="input-email">
                <i className="fa fa-envelope icon" />
                <input
                  className="input-1"
                  type="email"
                  name="email"
                  placeholder=" Enter your e-mail"
                  onChange={(e) => set_email(e.target.value)}
                />
                {emailError !== "" ? (
                  <p className="errorMsg">{emailError}</p>
                ) : (
                  ""
                )}
              </div>
            ) : (
              <>
                {error !== "" ? <p className="errorMsg">{error}</p> : ""}
                <div className="otp-box">
                  <OtpInput
                    value={OTP}
                    onChange={setOTP}
                    numInputs={6}
                    separator={<span>-</span>}
                    className="otp-container"
                  />
                  {OTPError !== "" ? (
                    <p className="errorMsg">{OTPError}</p>
                  ) : (
                    ""
                  )}
                </div>
                <div className="input-email">
                  <i className="fa fa-lock icon" />
                  <input
                    className="input-1"
                    type="password"
                    name="password"
                    placeholder="Enter your New password"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  {passwordError !== "" ? (
                    <p className="errorMsg">{passwordError}</p>
                  ) : (
                    ""
                  )}
                </div>
                <div className="input-email">
                  <i className="fa fa-lock icon" />
                  <input
                    className="input-1"
                    type="password"
                    name="confpassword"
                    placeholder="Please confirm password!"
                    value={confPassword}
                    onChange={handleConfPassword}
                    // onChange={(e) => setConfPassword(e.target.value)}
                  />
                  {confpasswordError !== "" ? (
                    <p className="errorMsg">{confpasswordError}</p>
                  ) : (
                    ""
                  )}
                </div>
              </>
            )}
            {!otp_flag ? (
              <>
                {" "}
                <button className="sendotp" onClick={handleSubmit}>
                  Send me a reset password OTP
                </button>
              </>
            ) : (
              <>
                {" "}
                <button className="sendotp" onClick={handleOTP}>
                  Verify OTP
                </button>
              </>
            )}
          </div>
        </div>

        <div className="background" />
      </div>
    </>
  );
}
