import { Col, Container, Row } from 'react-bootstrap';

const About = () => {
    return (
        <section className="position-relative pt-8 pb-4">
            <Container>
                <Row data-aos="fade-up">
                    <Col lg={4} className="aboutUs">
                        <span className="border border-top w-25 border-soft-primary d-block"></span>
                        <h1 className="display-5 fw-semibold mt-4 clrwhite">Our Mission <span>User Choice</span></h1>
                    </Col>
                    <Col lg={6}>
                        <p className="mb-4 clrwhite">
                            At Swaptrade, our focus is on user choice. We want to make crypto accessible and easy-to-use for everyone. The mix of a non-custodial swap platform and a custodial trading platform allows you to choose whether to retain custody of your funds, or allow us to secure it - you will never be forced to do either.
                        </p>
                    </Col>
                </Row>
            </Container>
        </section>
    );
};

export default About;
