/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";
import { Tabs, Tab } from "react-bootstrap";
import { useState } from "react";

import {
  getInstruments,
  getTicker,
} from "../actions/market";

import TradingViewWidget, { Themes } from "react-tradingview-widget";
import { ThemeConsumer } from "../context/ThemeContext";
export default function MarketPairs(props) {
  const isPwa = window.matchMedia("(display-mode: standalone)");

  const [activeTab, setActiveTab] = useState("all");
  const [activeDetails, setActiveDetails] = useState("");
  const [isOpenDetail, setIsOpenDetail] = useState(false);
  const [instruments, setInstruments] = useState([]);
  const [tokens, setTokens] = useState(["all", "btc", "usdt"]);
  const [tradeList, setTradeList] = useState([]);
  const [starList, setStarList] = useState([]);
  const [isLoader, setIsLoader] = useState(true);
  const [tickers, setTickers] = useState([]);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
      console.log("remove aos");
      await getInstruments()
        .then((res) => {
          setInstruments(res);
        })
        .catch(() => {});
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (instruments?.length && !isDataLoaded) {
      loadData();
      setIsDataLoaded(true);
    }
  }, [instruments]);

  async function loadData() {
    let trades = [];
    let tickers = await getTicker();
    setTickers(tickers);
    for (let tIndex = 0; tIndex < tokens.length; tIndex++) {
      const token = tokens[tIndex];
      let trade = [];
      let usdt_price = [];
      let filtered_instruments = instruments.filter((instrument) =>
        instrument.includes("_" + token)
      );
      for (let iIndex = 0; iIndex < filtered_instruments.length; iIndex++) {
        const instrument = filtered_instruments[iIndex];
        tickers.forEach((t) => {
          if (instrument === t.instrument) {
            t.price = t.close;
            t.origin = t.open;
            t.change = t.price - t.origin;
            t.percentage = (t.change * 100) / t.origin;
            trade.push(t);
            if (instrument?.includes("usdt")) {
              usdt_price.push(t);
            }
            if (instrument === "btc_usdt") {
              console.log("price=" + t.price);
            }
          }
        });
      }
      trades.push(trade);
    }
    setIsLoader(false);
    for (const trade of trades) {
      trades[0].push(...trade);
    }
    setTradeList(trades);
  }

  useEffect(() => {
    const timer = setInterval(() => {
      if (!isLoader) {
        loadData();
      }
    }, 20000);

    return () => {
      clearInterval(timer);
    };
  }, []);
  return isPwa.matches ? (
    <>
      {
        <>
          <div
            className="trade-details-section"
            style={{
              position: "fixed",
              top: "0px",
              left: "0px",
              height: "100vh",
              width: "100%",
              zIndex: "8000000",
              scale: isOpenDetail ? "1" : "0",
              opacity: isOpenDetail ? "1" : "0",
              transition: "all 0.1s ease-in-out",
            }}
          >
            <div
              style={{
                display: "flex",
                height: "50px",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              {" "}
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "50px",
                  padding: "10px",
                }}
                onClick={(e) => {
                  setIsOpenDetail(false);
                }}
              >
                <i
                  className=" ion ion-ios-arrow-back"
                  style={{ fontSize: "30px" }}
                />
              </div>{" "}
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "10px",
                }}
              >
                <p
                  className="plain-text"
                  style={{ fontSize: "24px", fontWeight: "600" }}
                >
                  {activeDetails.split("_")[0].toUpperCase() +
                    "/" +
                    activeDetails
                      .split("_")
                      [activeDetails.split("_").length - 1].toUpperCase()}
                </p>
              </div>{" "}
              <button
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "10px",
                  backgroundColor: "transparent",
                  width: "50px",
                  border: "none",
                }}
                onClick={() => {
                  if (starList.includes(activeDetails)) {
                    const starIndex = starList.findIndex(
                      (val) => val === activeDetails
                    );
                    starList.splice(starIndex, 1);
                    setStarList([...starList]);
                  } else {
                    setStarList([...starList, activeDetails]);
                  }
                }}
              >
                <i
                  className="icon ion-md-star"
                  style={{
                    fontSize: "30px",
                    color: starList.includes(activeDetails) ? "blue" : "white",
                  }}
                ></i>
              </button>
            </div>
            <div style={{ height: "50vh" }}>
              <ThemeConsumer>
                {({ data, update }) => {
                  return (
                    activeDetails.length !== 0 && (
                      <TradingViewWidget
                        symbol={`${activeDetails
                          .split("_")[0]
                          .toUpperCase()}${activeDetails
                          .split("_")
                          [activeDetails.split("_").length - 1].toUpperCase()}`}
                        theme={data?.theme === "dark" ? Themes.DARK : null}
                        locale="en"
                        autosize
                      />
                    )
                  );
                }}
              </ThemeConsumer>
              <div className="market-data-tabs">
                <Tabs
                  className="nav nav-underline-pwa"
                  style={{
                    flexWrap: "nowrap",
                    position: "sticky",
                    top: "-1px",
                    zIndex: "1",
                  }}
                  defaultActiveKey="order-book"
                >
                  <Tab
                    eventKey="order-book"
                    style={{
                      flexDirection: "row",
                      height: "29vh",
                    }}
                    className={`${
                      activeTab === "order-book" ? "tab-active" : ""
                    }`}
                    title="Order Book"
                  >
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Price(BTC)</th>
                          <th>Amount(ETH)</th>
                          <th>Total(ETH)</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className="red-bg-80">
                          <td className="red">0.022572</td>
                          <td>1.253415</td>
                          <td>15.27648</td>
                        </tr>
                        <tr className="red-bg-60">
                          <td className="red">0.020371</td>
                          <td>1.205415</td>
                          <td>15.25648</td>
                        </tr>
                        <tr className="red-bg-40">
                          <td className="red">0.023572</td>
                          <td>1.645415</td>
                          <td>15.23648</td>
                        </tr>
                        <tr className="red-bg-20">
                          <td className="red">0.032378</td>
                          <td>1.206715</td>
                          <td>15.25348</td>
                        </tr>
                        <tr className="red-bg-10">
                          <td className="red">0.022573</td>
                          <td>1.262415</td>
                          <td>15.19648</td>
                        </tr>
                        <tr className="red-bg-8">
                          <td className="red">0.154377</td>
                          <td>1.225415</td>
                          <td>15.35648</td>
                        </tr>
                        <tr className="red-bg-5">
                          <td className="red">0.120373</td>
                          <td>1.285415</td>
                          <td>15.25648</td>
                        </tr>
                        <tr className="red-bg">
                          <td className="red">0.028576</td>
                          <td>1.291415</td>
                          <td>15.26448</td>
                        </tr>
                      </tbody>
                      <tbody className="ob-heading">
                        <tr>
                          <td>
                            <span>Last Price</span>
                            0.020367
                          </td>
                          <td>
                            <span>USD</span>
                            148.65
                          </td>
                          <td className="red">
                            <span>Change</span>
                            -0.51%
                          </td>
                        </tr>
                      </tbody>
                      <tbody>
                        <tr className="green-bg">
                          <td className="green">0.158373</td>
                          <td>1.209515</td>
                          <td>15.23248</td>
                        </tr>
                        <tr className="green-bg-5">
                          <td className="green">0.020851</td>
                          <td>1.206245</td>
                          <td>15.25458</td>
                        </tr>
                        <tr className="green-bg-8">
                          <td className="green">0.025375</td>
                          <td>1.205715</td>
                          <td>15.65648</td>
                        </tr>
                        <tr className="green-bg-10">
                          <td className="green">0.020252</td>
                          <td>1.205495</td>
                          <td>15.24548</td>
                        </tr>
                        <tr className="green-bg-20">
                          <td className="green">0.020373</td>
                          <td>1.205415</td>
                          <td>15.25648</td>
                        </tr>
                        <tr className="green-bg-40">
                          <td className="green">0.020156</td>
                          <td>1.207515</td>
                          <td>15.28948</td>
                        </tr>
                        <tr className="green-bg-60">
                          <td className="green">0.540375</td>
                          <td>1.205915</td>
                          <td>15.25748</td>
                        </tr>
                        <tr className="green-bg-80">
                          <td className="green">0.020372</td>
                          <td>1.205415</td>
                          <td>15.25648</td>
                        </tr>
                      </tbody>
                    </table>
                  </Tab>
                  <Tab
                    eventKey="market-trades"
                    title="Market Trades"
                    style={{
                      flexDirection: "row",
                      height: "29vh",
                      position: "",
                    }}
                    className={`${
                      activeTab === "market-trades" ? "tab-active" : ""
                    }`}
                  ></Tab>
                  <Tab
                    eventKey="margin-data"
                    title="Margin Data"
                    style={{
                      flexDirection: "row",
                      height: "29vh",
                      position: "",
                    }}
                    className={`${
                      activeTab === "margin-data" ? "tab-active" : ""
                    }`}
                  ></Tab>
                  <Tab
                    eventKey="info"
                    title="Info"
                    style={{
                      flexDirection: "row",
                      height: "29vh",
                      position: "",
                    }}
                    className={`${activeTab === "info" ? "tab-active" : ""}`}
                  ></Tab>
                </Tabs>
              </div>
              {/* <MarketTrade/> */}
              <div
                style={{
                  width: "200vh",
                  maxWidth: "29vh",
                  backgroundColor: "grey",
                }}
              ></div>
              <div
                className="place-order-contr"
                style={{
                  position: "fixed",
                  bottom: "0px",
                  padding: "10px",
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                  backgroundColor: "#05080d",
                }}
              >
                <button
                  className="sell"
                  style={{
                    padding: "5px 50px",
                    fontSize: "15px",
                    fontWeight: "600",
                    backgroundColor: "red",
                    color: "white",
                    border: "none",
                    borderRadius: "50px",
                    marginInline: "10px",
                  }}
                >
                  SELL
                </button>
                <button
                  className="buy"
                  style={{
                    padding: "5px 50px",
                    fontSize: "15px",
                    fontWeight: "600",
                    backgroundColor: "green",
                    color: "white",
                    border: "none",
                    borderRadius: "50px",
                    marginInline: "10px",
                  }}
                >
                  BUY
                </button>
              </div>
            </div>
          </div>
        </>
      }
      <div
        className="market-pairs-pwa"
        style={{ margin: "0px", padding: "0px" }}
      >
        <Tabs
          defaultActiveKey="All"
          className="nav nav-underline-pwa"
          activeKey={activeTab}
          onSelect={(k) => setActiveTab(k)}
          style={{
            margin: "0px",
            padding: "0px",
            justifyContent: "center",
            marginTop: "0px",
            backgroundColor: "#f9f9f9",
          }}
        >
          <Tab
            eventKey={"all"}
            title={"ALL"}
            className={`${activeTab === "all" ? "tab-active" : ""}`}
            onClick={(e) => {
              e.preventDefault();
              setActiveTab("all");
            }}
          >
            <table className="table star-active">
              <thead className="table-head-pwa">
                <tr>
                  <th
                    style={{
                      width: "135px",
                      padding: "18px",
                    }}
                  >
                    Pairs
                  </th>
                  <th
                    style={{
                      width: "100px",
                      padding: "18px",
                    }}
                  >
                    Price
                  </th>
                  <th
                    style={{
                      width: "100px",
                      padding: "18px",
                    }}
                  >
                    24h Change
                  </th>
                </tr>
              </thead>
              <tbody>
                {tickers.map((coin) => {
                  const primaryCoin = coin.instrument
                    .split("_")[0]
                    .toUpperCase();
                  const secondaryCoin = coin.instrument
                    .split("_")[1]
                    .toUpperCase();

                  const handleAddStarlist = () => {
                    const includeInstar = starList.includes(coin.instrument);

                    if (!includeInstar) {
                      setStarList([...starList, coin.instrument]);
                    } else {
                      const valIndOFstarList = starList.findIndex(
                        (val) => val === coin.instrument
                      );

                      starList.splice(valIndOFstarList, 1);
                      setStarList([...starList]);
                    }
                  };

                  return (
                    <tr
                      onClick={(e) => {
                        e.stopPropagation();

                        setActiveDetails((prev) => (prev = coin.instrument));
                        setIsOpenDetail(true);
                      }}
                    >
                      <td>
                        <button
                          style={{
                            backgroundColor: "transparent",
                            border: "none",
                            padding: "5px",
                          }}
                          onClick={(e) => {
                            e.stopPropagation();

                            handleAddStarlist();
                          }}
                        >
                          <i
                            className="icon ion-md-star"
                            style={{
                              fontSize: "15px",
                              color: !starList.includes(coin.instrument)
                                ? "white"
                                : "blue",
                            }}
                          ></i>
                        </button>
                        <span style={{ fontWeight: "700" }}>{primaryCoin}</span>
                        <span style={{ color: "#6e6e6e" }}>
                          /{secondaryCoin}
                        </span>
                      </td>
                      <td>
                        <p
                          className={
                            Number(coin.percentage).toFixed(2) >= 0
                              ? "green"
                              : "red"
                          }
                          style={{ fontWeight: "600" }}
                        >
                          {Number(coin.high)}
                        </p>
                        <p>${coin.price}</p>
                      </td>
                      <td>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "5rem",
                            height: "2.5rem",
                            borderRadius: "0.3rem",
                            color: "white",
                            backgroundColor:
                              Number(coin.percentage).toFixed(2) >= 0
                                ? "green"
                                : "red",
                          }}
                        >
                          {Number(coin.percentage).toFixed(2)}%
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </Tab>
          {tradeList.slice(1, tradeList.length).map((trade) => {
            const title =
              trade[0]?.instrument?.split("_")[
                trade[0]?.instrument?.split("_").length - 1
              ];
            return (
              <Tab
                eventKey={title}
                title={String(title).toUpperCase()}
                className={`${activeTab === "all" ? "tab-active" : ""}`}
                onClick={(e) => {
                  e.preventDefault();

                  setActiveTab(title);
                }}
              >
                <table className="table star-active">
                  <thead className="table-head-pwa">
                    <tr>
                      <th
                        style={{
                          width: "120px",
                          padding: "18px",
                        }}
                      >
                        Pairs
                      </th>
                      <th
                        style={{
                          width: "100px",
                          padding: "18px",
                        }}
                      >
                        Price
                      </th>
                      <th
                        style={{
                          width: "100px",
                          padding: "18px",
                        }}
                      >
                        24h Change
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {trade.map((coin) => {
                      const primaryCoin = coin.instrument
                        .split("_")[0]
                        .toUpperCase();
                      const secondaryCoin = coin.instrument
                        .split("_")[1]
                        .toUpperCase();

                      const handleAddStarlist = () => {
                        const includeInstar = starList.includes(
                          coin.instrument
                        );
                        if (!includeInstar) {
                          setStarList([...starList, coin.instrument]);
                        } else {
                          const valIndOFstarList = starList.findIndex(
                            (val) => val === coin.instrument
                          );
                          starList.splice(valIndOFstarList, 1);
                          setStarList([...starList]);
                        }
                      };
                      return (
                        <tr
                          onClick={(e) => {
                            e.stopPropagation();

                            setActiveDetails(
                              (prev) => (prev = coin.instrument)
                            );
                            setIsOpenDetail(true);
                          }}
                        >
                          <td>
                            <button
                              style={{
                                backgroundColor: "transparent",
                                border: "none",
                                padding: "5px",
                                pointerEvents: "revert-layer",
                              }}
                              onClick={(e) => {
                                e.stopPropagation();
                                handleAddStarlist();
                              }}
                            >
                              <i
                                className="icon ion-md-star"
                                style={{
                                  fontSize: "15px",
                                  color: starList.includes(coin.instrument)
                                    ? "blue"
                                    : "white",
                                }}
                              ></i>
                            </button>
                            <span style={{ fontWeight: "700" }}>
                              {primaryCoin}
                            </span>
                            <span style={{ color: "#6e6e6e" }}>
                              /{secondaryCoin}
                            </span>
                          </td>
                          <td>
                            <p
                              className={
                                Number(coin.percentage).toFixed(2) >= 0
                                  ? "green"
                                  : "red"
                              }
                              style={{ fontWeight: "600" }}
                            >
                              {Number(coin.high)}
                            </p>
                            <p>${coin.price}</p>
                          </td>
                          <td>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                width: "5rem",
                                height: "2.5rem",
                                borderRadius: "0.3rem",
                                color: "white",
                                backgroundColor:
                                  Number(coin.percentage).toFixed(2) >= 0
                                    ? "green"
                                    : "red",
                              }}
                            >
                              {Number(coin.percentage).toFixed(2)}%
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </Tab>
            );
          })}
          <Tab
            eventKey={"favorites"}
            title={"FAVORITES"}
            className={`${activeTab === "all" ? "tab-active" : ""}`}
            onClick={(e) => {
              e.preventDefault();

              setActiveTab("favorites");
            }}
          >
            <table className="table star-active">
              <thead className="table-head-pwa">
                <tr>
                  <th
                    style={{
                      width: "130px",
                      padding: "18px",
                    }}
                  >
                    Pairs
                  </th>
                  <th
                    style={{
                      width: "100px",
                      padding: "18px",
                    }}
                  >
                    Price
                  </th>
                  <th
                    style={{
                      width: "100px",
                      padding: "18px",
                    }}
                  >
                    24h Change
                  </th>
                </tr>
              </thead>
              <tbody>
                {starList.map((star) => {
                  const coin = tickers.filter(
                    (val) => val.instrument === star
                  )[0];
                  const primaryCoin = coin.instrument
                    .split("_")[0]
                    .toUpperCase();
                  const secondaryCoin = coin.instrument
                    .split("_")[1]
                    .toUpperCase();

                  const includeInstar = starList?.includes(coin?.instrument);

                  const handleAddStarlist = () => {
                    if (!includeInstar) {
                      setStarList([...starList, coin.instrument]);
                    } else {
                      const valIndOFstarList = starList.findIndex(
                        (val) => val === coin.instrument
                      );
                      starList.splice(valIndOFstarList, 1);
                      setStarList([...starList]);
                    }
                  };

                  return (
                    <tr
                      onClick={(e) => {
                        e.stopPropagation();

                        setActiveDetails((prev) => (prev = coin.instrument));
                        setIsOpenDetail(true);
                      }}
                    >
                      <td>
                        <button
                          style={{
                            backgroundColor: "transparent",
                            border: "none",
                            padding: "5px",
                          }}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleAddStarlist();
                          }}
                        >
                          {includeInstar ? (
                            <i
                              className="icon ion-md-star"
                              style={{ fontSize: "15px" }}
                            ></i>
                          ) : (
                            <i className="icon ion-md-star"></i>
                          )}
                        </button>
                        <span style={{ fontWeight: "700" }}>{primaryCoin}</span>
                        <span style={{ color: "#6e6e6e" }}>
                          /{secondaryCoin}
                        </span>
                      </td>
                      <td>
                        <p
                          className={
                            Number(coin.percentage).toFixed(2) >= 0
                              ? "green"
                              : "red"
                          }
                          style={{ fontWeight: "600" }}
                        >
                          {Number(coin.high)}
                        </p>
                        <p>${coin.price}</p>
                      </td>
                      <td>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "5rem",
                            height: "2.5rem",
                            borderRadius: "0.3rem",
                            color: "white",
                            backgroundColor:
                              Number(coin.percentage).toFixed(2) >= 0
                                ? "green"
                                : "red",
                          }}
                        >
                          {Number(coin.percentage).toFixed(2)}%
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </Tab>
        </Tabs>
      </div>
    </>
  ) : (
    <>
      <div className="market-pairs">
        <div className="input-group">
          <div className="input-group-prepend">
            <span className="input-group-text" id="inputGroup-sizing-sm">
              <i className="icon ion-md-search"></i>
            </span>
          </div>
          <input
            type="text"
            className="form-control"
            placeholder="Search"
            aria-describedby="inputGroup-sizing-sm"
          />
        </div>
        <Tabs defaultActiveKey="btc">
          <Tab eventKey="star" title="★">
            <table className="table star-active">
              <thead className="table-head-pwa">
                <tr>
                  <th>Pairs</th>
                  <th>Last Price</th>
                  <th>Change</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <i className="icon ion-md-star"></i> ETH/BTC
                  </td>
                  <td>0.00020255</td>
                  <td className="red">-2.58%</td>
                </tr>
                <tr>
                  <td>
                    <i className="icon ion-md-star"></i> KCS/BTC
                  </td>
                  <td>0.00013192</td>
                  <td className="green">+5.6%</td>
                </tr>
                <tr>
                  <td>
                    <i className="icon ion-md-star"></i> XRP/BTC
                  </td>
                  <td>0.00002996</td>
                  <td className="red">-1.55%</td>
                </tr>
                <tr>
                  <td>
                    <i className="icon ion-md-star"></i> VET/BTC
                  </td>
                  <td>0.00000103</td>
                  <td className="green">+1.8%</td>
                </tr>
              </tbody>
            </table>
          </Tab>
          <Tab eventKey="btc" title="BTC">
            <table className="table">
              <thead className="table-head-pwa">
                <tr>
                  <th>Pairs</th>
                  <th>Last Price</th>
                  <th>Change</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <i className="icon ion-md-star"></i> ETH/BTC
                  </td>
                  <td>0.00020255</td>
                  <td className="red">-2.58%</td>
                </tr>
                <tr>
                  <td>
                    <i className="icon ion-md-star"></i> KCS/BTC
                  </td>
                  <td>0.00013192</td>
                  <td className="green">+5.6%</td>
                </tr>
                <tr>
                  <td>
                    <i className="icon ion-md-star"></i> XRP/BTC
                  </td>
                  <td>0.00002996</td>
                  <td className="red">-1.55%</td>
                </tr>
                <tr>
                  <td>
                    <i className="icon ion-md-star"></i> VET/BTC
                  </td>
                  <td>0.00000103</td>
                  <td className="green">+1.8%</td>
                </tr>
                <tr>
                  <td>
                    <i className="icon ion-md-star"></i> EOS/BTC
                  </td>
                  <td>0.00000103</td>
                  <td className="red">-2.05%</td>
                </tr>
                <tr>
                  <td>
                    <i className="icon ion-md-star"></i> BTT/BTC
                  </td>
                  <td>0.00002303</td>
                  <td className="red">-1.05%</td>
                </tr>
                <tr>
                  <td>
                    <i className="icon ion-md-star"></i> LTC/BTC
                  </td>
                  <td>0.03520103</td>
                  <td className="green">+1.5%</td>
                </tr>
                <tr>
                  <td>
                    <i className="icon ion-md-star"></i> TRX/BTC
                  </td>
                  <td>0.00330103</td>
                  <td className="red">-3.05%</td>
                </tr>
                <tr>
                  <td>
                    <i className="icon ion-md-star"></i> BSV/BTC
                  </td>
                  <td>0.00300103</td>
                  <td className="green">+2.05%</td>
                </tr>
                <tr>
                  <td>
                    <i className="icon ion-md-star"></i> COTI/BTC
                  </td>
                  <td>0.003500103</td>
                  <td className="green">+2.85%</td>
                </tr>
                <tr>
                  <td>
                    <i className="icon ion-md-star"></i> XYT/BTC
                  </td>
                  <td>0.00003103</td>
                  <td className="green">+3.55%</td>
                </tr>
                <tr>
                  <td>
                    <i className="icon ion-md-star"></i> BNB/BTC
                  </td>
                  <td>0.003500103</td>
                  <td className="red">-2.05%</td>
                </tr>
                <tr>
                  <td>
                    <i className="icon ion-md-star"></i> XMR/BTC
                  </td>
                  <td>0.003500103</td>
                  <td className="red">-1.05%</td>
                </tr>
                <tr>
                  <td>
                    <i className="icon ion-md-star"></i> TRY/BTC
                  </td>
                  <td>0.00000123</td>
                  <td className="red">-2.05%</td>
                </tr>
                <tr>
                  <td>
                    <i className="icon ion-md-star"></i> ADA/BTC
                  </td>
                  <td>0.00050103</td>
                  <td className="green">+5.05%</td>
                </tr>
                <tr>
                  <td>
                    <i className="icon ion-md-star"></i> NEO/BTC
                  </td>
                  <td>0.00340103</td>
                  <td className="red">-1.05%</td>
                </tr>
                <tr>
                  <td>
                    <i className="icon ion-md-star"></i> XLM/BTC
                  </td>
                  <td>0.00035103</td>
                  <td className="green">+5.05%</td>
                </tr>
                <tr>
                  <td>
                    <i className="icon ion-md-star"></i> ENQ/BTC
                  </td>
                  <td>0.00354103</td>
                  <td className="green">+2.02%</td>
                </tr>
                <tr>
                  <td>
                    <i className="icon ion-md-star"></i> AVA/BTC
                  </td>
                  <td>0.02535103</td>
                  <td className="green">+3.05%</td>
                </tr>
                <tr>
                  <td>
                    <i className="icon ion-md-star"></i> AMB/BTC
                  </td>
                  <td>0.05335103</td>
                  <td className="green">+1.0%</td>
                </tr>
                <tr>
                  <td>
                    <i className="icon ion-md-star"></i> MAP/BTC
                  </td>
                  <td>0.00234103</td>
                  <td className="red">-2.05%</td>
                </tr>
                <tr>
                  <td>
                    <i className="icon ion-md-star"></i> GO/BTC
                  </td>
                  <td>0.00354103</td>
                  <td className="red">-6.50%</td>
                </tr>
                <tr>
                  <td>
                    <i className="icon ion-md-star"></i> KICK/BTC
                  </td>
                  <td>0.02053103</td>
                  <td className="red">-6.05%</td>
                </tr>
                <tr>
                  <td>
                    <i className="icon ion-md-star"></i> DBC/BTC
                  </td>
                  <td>0.02535103</td>
                  <td className="green">+7.05%</td>
                </tr>
                <tr>
                  <td>
                    <i className="icon ion-md-star"></i> GGC/BTC
                  </td>
                  <td>0.00353103</td>
                  <td className="red">-4.05%</td>
                </tr>
              </tbody>
            </table>
          </Tab>
          <Tab eventKey="eth" title="ETH">
            <table className="table">
              <thead className="table-head-pwa">
                <tr>
                  <th>Pairs</th>
                  <th>Last Price</th>
                  <th>Change</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <i className="icon ion-md-star"></i> BTC/ETH
                  </td>
                  <td>0.00020255</td>
                  <td className="green">+1.58%</td>
                </tr>
                <tr>
                  <td>
                    <i className="icon ion-md-star"></i> KCS/ETH
                  </td>
                  <td>0.00013192</td>
                  <td className="red">-0.6%</td>
                </tr>
                <tr>
                  <td>
                    <i className="icon ion-md-star"></i> XRP/ETH
                  </td>
                  <td>0.00002996</td>
                  <td className="red">-0.55%</td>
                </tr>
                <tr>
                  <td>
                    <i className="icon ion-md-star"></i> VET/ETH
                  </td>
                  <td>0.00000103</td>
                  <td className="green">+1.8%</td>
                </tr>
                <tr>
                  <td>
                    <i className="icon ion-md-star"></i> EOS/ETH
                  </td>
                  <td>0.00000103</td>
                  <td className="red">-2.05%</td>
                </tr>
                <tr>
                  <td>
                    <i className="icon ion-md-star"></i> BTT/ETH
                  </td>
                  <td>0.00002303</td>
                  <td className="red">-1.05%</td>
                </tr>
                <tr>
                  <td>
                    <i className="icon ion-md-star"></i> LTC/ETH
                  </td>
                  <td>0.03520103</td>
                  <td className="green">+1.5%</td>
                </tr>
                <tr>
                  <td>
                    <i className="icon ion-md-star"></i> TRX/ETH
                  </td>
                  <td>0.00330103</td>
                  <td className="red">-3.05%</td>
                </tr>
                <tr>
                  <td>
                    <i className="icon ion-md-star"></i> BSV/ETH
                  </td>
                  <td>0.00300103</td>
                  <td className="green">+2.05%</td>
                </tr>
                <tr>
                  <td>
                    <i className="icon ion-md-star"></i> COTI/ETH
                  </td>
                  <td>0.003500103</td>
                  <td className="green">+2.85%</td>
                </tr>
                <tr>
                  <td>
                    <i className="icon ion-md-star"></i> XYT/ETH
                  </td>
                  <td>0.00003103</td>
                  <td className="green">+3.55%</td>
                </tr>
                <tr>
                  <td>
                    <i className="icon ion-md-star"></i> BNB/ETH
                  </td>
                  <td>0.003500103</td>
                  <td className="red">-2.05%</td>
                </tr>
                <tr>
                  <td>
                    <i className="icon ion-md-star"></i> XMR/ETH
                  </td>
                  <td>0.003500103</td>
                  <td className="red">-1.05%</td>
                </tr>
                <tr>
                  <td>
                    <i className="icon ion-md-star"></i> TRY/ETH
                  </td>
                  <td>0.00000123</td>
                  <td className="red">-2.05%</td>
                </tr>
                <tr>
                  <td>
                    <i className="icon ion-md-star"></i> ADA/ETH
                  </td>
                  <td>0.00050103</td>
                  <td className="green">+5.05%</td>
                </tr>
                <tr>
                  <td>
                    <i className="icon ion-md-star"></i> NEO/ETH
                  </td>
                  <td>0.00340103</td>
                  <td className="red">-1.05%</td>
                </tr>
                <tr>
                  <td>
                    <i className="icon ion-md-star"></i> XLM/ETH
                  </td>
                  <td>0.00035103</td>
                  <td className="green">+5.05%</td>
                </tr>
                <tr>
                  <td>
                    <i className="icon ion-md-star"></i> ENQ/ETH
                  </td>
                  <td>0.00354103</td>
                  <td className="green">+2.02%</td>
                </tr>
                <tr>
                  <td>
                    <i className="icon ion-md-star"></i> AVA/ETH
                  </td>
                  <td>0.02535103</td>
                  <td className="green">+3.05%</td>
                </tr>
                <tr>
                  <td>
                    <i className="icon ion-md-star"></i> AMB/ETH
                  </td>
                  <td>0.05335103</td>
                  <td className="green">+1.0%</td>
                </tr>
                <tr>
                  <td>
                    <i className="icon ion-md-star"></i> MAP/ETH
                  </td>
                  <td>0.00234103</td>
                  <td className="red">-2.05%</td>
                </tr>
                <tr>
                  <td>
                    <i className="icon ion-md-star"></i> GO/ETH
                  </td>
                  <td>0.00354103</td>
                  <td className="red">-6.50%</td>
                </tr>
                <tr>
                  <td>
                    <i className="icon ion-md-star"></i> KICK/ETH
                  </td>
                  <td>0.02053103</td>
                  <td className="red">-6.05%</td>
                </tr>
                <tr>
                  <td>
                    <i className="icon ion-md-star"></i> DBC/ETH
                  </td>
                  <td>0.02535103</td>
                  <td className="green">+7.05%</td>
                </tr>
                <tr>
                  <td>
                    <i className="icon ion-md-star"></i> GGC/ETH
                  </td>
                  <td>0.00353103</td>
                  <td className="red">-4.05%</td>
                </tr>
              </tbody>
            </table>
          </Tab>
          <Tab eventKey="neo" title="NEO">
            <table className="table">
              <thead className="table-head-pwa">
                <tr>
                  <th>Pairs</th>
                  <th>Last Price</th>
                  <th>Change</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <i className="icon ion-md-star"></i> ETH/NEO
                  </td>
                  <td>0.00350255</td>
                  <td className="red">-6.58%</td>
                </tr>
                <tr>
                  <td>
                    <i className="icon ion-md-star"></i> KCS/NEO
                  </td>
                  <td>0.00013192</td>
                  <td className="green">+0.6%</td>
                </tr>
                <tr>
                  <td>
                    <i className="icon ion-md-star"></i> XRP/NEO
                  </td>
                  <td>0.00002996</td>
                  <td className="red">-0.55%</td>
                </tr>
                <tr>
                  <td>
                    <i className="icon ion-md-star"></i> VET/NEO
                  </td>
                  <td>0.00000103</td>
                  <td className="green">+1.8%</td>
                </tr>
                <tr>
                  <td>
                    <i className="icon ion-md-star"></i> EOS/NEO
                  </td>
                  <td>0.00000103</td>
                  <td className="red">-2.05%</td>
                </tr>
                <tr>
                  <td>
                    <i className="icon ion-md-star"></i> BTT/NEO
                  </td>
                  <td>0.00002303</td>
                  <td className="red">-1.05%</td>
                </tr>
                <tr>
                  <td>
                    <i className="icon ion-md-star"></i> LTC/NEO
                  </td>
                  <td>0.03520103</td>
                  <td className="green">+1.5%</td>
                </tr>
                <tr>
                  <td>
                    <i className="icon ion-md-star"></i> TRX/NEO
                  </td>
                  <td>0.00330103</td>
                  <td className="red">-3.05%</td>
                </tr>
                <tr>
                  <td>
                    <i className="icon ion-md-star"></i> BSV/NEO
                  </td>
                  <td>0.00300103</td>
                  <td className="green">+2.05%</td>
                </tr>
                <tr>
                  <td>
                    <i className="icon ion-md-star"></i> COTI/NEO
                  </td>
                  <td>0.003500103</td>
                  <td className="green">+2.85%</td>
                </tr>
                <tr>
                  <td>
                    <i className="icon ion-md-star"></i> XYT/NEO
                  </td>
                  <td>0.00003103</td>
                  <td className="green">+3.55%</td>
                </tr>
                <tr>
                  <td>
                    <i className="icon ion-md-star"></i> BNB/NEO
                  </td>
                  <td>0.003500103</td>
                  <td className="red">-2.05%</td>
                </tr>
                <tr>
                  <td>
                    <i className="icon ion-md-star"></i> XMR/NEO
                  </td>
                  <td>0.003500103</td>
                  <td className="red">-1.05%</td>
                </tr>
                <tr>
                  <td>
                    <i className="icon ion-md-star"></i> TRY/NEO
                  </td>
                  <td>0.00000123</td>
                  <td className="red">-2.05%</td>
                </tr>
                <tr>
                  <td>
                    <i className="icon ion-md-star"></i> ADA/NEO
                  </td>
                  <td>0.00050103</td>
                  <td className="green">+5.05%</td>
                </tr>
                <tr>
                  <td>
                    <i className="icon ion-md-star"></i> NEO/NEO
                  </td>
                  <td>0.00340103</td>
                  <td className="red">-1.05%</td>
                </tr>
                <tr>
                  <td>
                    <i className="icon ion-md-star"></i> XLM/NEO
                  </td>
                  <td>0.00035103</td>
                  <td className="green">+5.05%</td>
                </tr>
                <tr>
                  <td>
                    <i className="icon ion-md-star"></i> ENQ/NEO
                  </td>
                  <td>0.00354103</td>
                  <td className="green">+2.02%</td>
                </tr>
                <tr>
                  <td>
                    <i className="icon ion-md-star"></i> AVA/NEO
                  </td>
                  <td>0.02535103</td>
                  <td className="green">+3.05%</td>
                </tr>
                <tr>
                  <td>
                    <i className="icon ion-md-star"></i> AMB/NEO
                  </td>
                  <td>0.05335103</td>
                  <td className="green">+1.0%</td>
                </tr>
                <tr>
                  <td>
                    <i className="icon ion-md-star"></i> MAP/NEO
                  </td>
                  <td>0.00234103</td>
                  <td className="red">-2.05%</td>
                </tr>
                <tr>
                  <td>
                    <i className="icon ion-md-star"></i> GO/NEO
                  </td>
                  <td>0.00354103</td>
                  <td className="red">-6.50%</td>
                </tr>
                <tr>
                  <td>
                    <i className="icon ion-md-star"></i> KICK/NEO
                  </td>
                  <td>0.02053103</td>
                  <td className="red">-6.05%</td>
                </tr>
                <tr>
                  <td>
                    <i className="icon ion-md-star"></i> DBC/NEO
                  </td>
                  <td>0.02535103</td>
                  <td className="green">+7.05%</td>
                </tr>
                <tr>
                  <td>
                    <i className="icon ion-md-star"></i> GGC/NEO
                  </td>
                  <td>0.00353103</td>
                  <td className="red">-4.05%</td>
                </tr>
              </tbody>
            </table>
          </Tab>
          <Tab eventKey="usdt" title="USDT">
            <table className="table">
              <thead className="table-head-pwa">
                <tr>
                  <th>Pairs</th>
                  <th>Last Price</th>
                  <th>Change</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <i className="icon ion-md-star"></i> ETH/USDT
                  </td>
                  <td>0.00350255</td>
                  <td className="red">-2.58%</td>
                </tr>
                <tr>
                  <td>
                    <i className="icon ion-md-star"></i> KCS/USDT
                  </td>
                  <td>0.00013192</td>
                  <td className="green">+6.6%</td>
                </tr>
                <tr>
                  <td>
                    <i className="icon ion-md-star"></i> XRP/USDT
                  </td>
                  <td>0.00002996</td>
                  <td className="red">-0.55%</td>
                </tr>
                <tr>
                  <td>
                    <i className="icon ion-md-star"></i> VET/USDT
                  </td>
                  <td>0.00000103</td>
                  <td className="green">+1.8%</td>
                </tr>
                <tr>
                  <td>
                    <i className="icon ion-md-star"></i> EOS/USDT
                  </td>
                  <td>0.00000103</td>
                  <td className="red">-2.05%</td>
                </tr>
                <tr>
                  <td>
                    <i className="icon ion-md-star"></i> BTT/USDT
                  </td>
                  <td>0.00002303</td>
                  <td className="red">-1.05%</td>
                </tr>
                <tr>
                  <td>
                    <i className="icon ion-md-star"></i> LTC/USDT
                  </td>
                  <td>0.03520103</td>
                  <td className="green">+1.5%</td>
                </tr>
                <tr>
                  <td>
                    <i className="icon ion-md-star"></i> TRX/USDT
                  </td>
                  <td>0.00330103</td>
                  <td className="red">-3.05%</td>
                </tr>
                <tr>
                  <td>
                    <i className="icon ion-md-star"></i> BSV/USDT
                  </td>
                  <td>0.00300103</td>
                  <td className="green">+2.05%</td>
                </tr>
                <tr>
                  <td>
                    <i className="icon ion-md-star"></i> COTI/USDT
                  </td>
                  <td>0.003500103</td>
                  <td className="green">+2.85%</td>
                </tr>
                <tr>
                  <td>
                    <i className="icon ion-md-star"></i> XYT/USDT
                  </td>
                  <td>0.00003103</td>
                  <td className="green">+3.55%</td>
                </tr>
                <tr>
                  <td>
                    <i className="icon ion-md-star"></i> BNB/USDT
                  </td>
                  <td>0.003500103</td>
                  <td className="red">-2.05%</td>
                </tr>
                <tr>
                  <td>
                    <i className="icon ion-md-star"></i> XMR/USDT
                  </td>
                  <td>0.003500103</td>
                  <td className="red">-1.05%</td>
                </tr>
                <tr>
                  <td>
                    <i className="icon ion-md-star"></i> TRY/USDT
                  </td>
                  <td>0.00000123</td>
                  <td className="red">-2.05%</td>
                </tr>
                <tr>
                  <td>
                    <i className="icon ion-md-star"></i> ADA/USDT
                  </td>
                  <td>0.00050103</td>
                  <td className="green">+5.05%</td>
                </tr>
                <tr>
                  <td>
                    <i className="icon ion-md-star"></i> USDT/USDT
                  </td>
                  <td>0.00340103</td>
                  <td className="red">-1.05%</td>
                </tr>
                <tr>
                  <td>
                    <i className="icon ion-md-star"></i> XLM/USDT
                  </td>
                  <td>0.00035103</td>
                  <td className="green">+5.05%</td>
                </tr>
                <tr>
                  <td>
                    <i className="icon ion-md-star"></i> ENQ/USDT
                  </td>
                  <td>0.00354103</td>
                  <td className="green">+2.02%</td>
                </tr>
                <tr>
                  <td>
                    <i className="icon ion-md-star"></i> AVA/USDT
                  </td>
                  <td>0.02535103</td>
                  <td className="green">+3.05%</td>
                </tr>
                <tr>
                  <td>
                    <i className="icon ion-md-star"></i> AMB/USDT
                  </td>
                  <td>0.05335103</td>
                  <td className="green">+1.0%</td>
                </tr>
                <tr>
                  <td>
                    <i className="icon ion-md-star"></i> MAP/USDT
                  </td>
                  <td>0.00234103</td>
                  <td className="red">-2.05%</td>
                </tr>
                <tr>
                  <td>
                    <i className="icon ion-md-star"></i> GO/USDT
                  </td>
                  <td>0.00354103</td>
                  <td className="red">-6.50%</td>
                </tr>
                <tr>
                  <td>
                    <i className="icon ion-md-star"></i> KICK/USDT
                  </td>
                  <td>0.02053103</td>
                  <td className="red">-6.05%</td>
                </tr>
                <tr>
                  <td>
                    <i className="icon ion-md-star"></i> DBC/USDT
                  </td>
                  <td>0.02535103</td>
                  <td className="green">+7.05%</td>
                </tr>
                <tr>
                  <td>
                    <i className="icon ion-md-star"></i> GGC/USDT
                  </td>
                  <td>0.00353103</td>
                  <td className="red">-4.05%</td>
                </tr>
              </tbody>
            </table>
          </Tab>
          <Tab eventKey="dai" title="DAI">
            <table className="table">
              <thead className="table-head-pwa">
                <tr>
                  <th>Pairs</th>
                  <th>Last Price</th>
                  <th>Change</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <i className="icon ion-md-star"></i> ETH/DAI
                  </td>
                  <td>0.05320255</td>
                  <td className="green">+6.58%</td>
                </tr>
                <tr>
                  <td>
                    <i className="icon ion-md-star"></i> KCS/DAI
                  </td>
                  <td>0.00013192</td>
                  <td className="green">+0.6%</td>
                </tr>
                <tr>
                  <td>
                    <i className="icon ion-md-star"></i> XRP/DAI
                  </td>
                  <td>0.00002996</td>
                  <td className="red">-0.55%</td>
                </tr>
                <tr>
                  <td>
                    <i className="icon ion-md-star"></i> VET/DAI
                  </td>
                  <td>0.00000103</td>
                  <td className="green">+1.8%</td>
                </tr>
                <tr>
                  <td>
                    <i className="icon ion-md-star"></i> EOS/DAI
                  </td>
                  <td>0.00000103</td>
                  <td className="red">-2.05%</td>
                </tr>
                <tr>
                  <td>
                    <i className="icon ion-md-star"></i> BTT/DAI
                  </td>
                  <td>0.00002303</td>
                  <td className="red">-1.05%</td>
                </tr>
                <tr>
                  <td>
                    <i className="icon ion-md-star"></i> LTC/DAI
                  </td>
                  <td>0.03520103</td>
                  <td className="green">+1.5%</td>
                </tr>
                <tr>
                  <td>
                    <i className="icon ion-md-star"></i> TRX/DAI
                  </td>
                  <td>0.00330103</td>
                  <td className="red">-3.05%</td>
                </tr>
                <tr>
                  <td>
                    <i className="icon ion-md-star"></i> BSV/DAI
                  </td>
                  <td>0.00300103</td>
                  <td className="green">+2.05%</td>
                </tr>
                <tr>
                  <td>
                    <i className="icon ion-md-star"></i> COTI/DAI
                  </td>
                  <td>0.003500103</td>
                  <td className="green">+2.85%</td>
                </tr>
                <tr>
                  <td>
                    <i className="icon ion-md-star"></i> XYT/DAI
                  </td>
                  <td>0.00003103</td>
                  <td className="green">+3.55%</td>
                </tr>
                <tr>
                  <td>
                    <i className="icon ion-md-star"></i> BNB/DAI
                  </td>
                  <td>0.003500103</td>
                  <td className="red">-2.05%</td>
                </tr>
                <tr>
                  <td>
                    <i className="icon ion-md-star"></i> XMR/DAI
                  </td>
                  <td>0.003500103</td>
                  <td className="red">-1.05%</td>
                </tr>
                <tr>
                  <td>
                    <i className="icon ion-md-star"></i> TRY/DAI
                  </td>
                  <td>0.00000123</td>
                  <td className="red">-2.05%</td>
                </tr>
                <tr>
                  <td>
                    <i className="icon ion-md-star"></i> ADA/DAI
                  </td>
                  <td>0.00050103</td>
                  <td className="green">+5.05%</td>
                </tr>
                <tr>
                  <td>
                    <i className="icon ion-md-star"></i> DAI/DAI
                  </td>
                  <td>0.00340103</td>
                  <td className="red">-1.05%</td>
                </tr>
                <tr>
                  <td>
                    <i className="icon ion-md-star"></i> XLM/DAI
                  </td>
                  <td>0.00035103</td>
                  <td className="green">+5.05%</td>
                </tr>
                <tr>
                  <td>
                    <i className="icon ion-md-star"></i> ENQ/DAI
                  </td>
                  <td>0.00354103</td>
                  <td className="green">+2.02%</td>
                </tr>
                <tr>
                  <td>
                    <i className="icon ion-md-star"></i> AVA/DAI
                  </td>
                  <td>0.02535103</td>
                  <td className="green">+3.05%</td>
                </tr>
                <tr>
                  <td>
                    <i className="icon ion-md-star"></i> AMB/DAI
                  </td>
                  <td>0.05335103</td>
                  <td className="green">+1.0%</td>
                </tr>
                <tr>
                  <td>
                    <i className="icon ion-md-star"></i> MAP/DAI
                  </td>
                  <td>0.00234103</td>
                  <td className="red">-2.05%</td>
                </tr>
                <tr>
                  <td>
                    <i className="icon ion-md-star"></i> GO/DAI
                  </td>
                  <td>0.00354103</td>
                  <td className="red">-6.50%</td>
                </tr>
                <tr>
                  <td>
                    <i className="icon ion-md-star"></i> KICK/DAI
                  </td>
                  <td>0.02053103</td>
                  <td className="red">-6.05%</td>
                </tr>
                <tr>
                  <td>
                    <i className="icon ion-md-star"></i> DBC/DAI
                  </td>
                  <td>0.02535103</td>
                  <td className="green">+7.05%</td>
                </tr>
                <tr>
                  <td>
                    <i className="icon ion-md-star"></i> GGC/DAI
                  </td>
                  <td>0.00353103</td>
                  <td className="red">-4.05%</td>
                </tr>
              </tbody>
            </table>
          </Tab>
        </Tabs>
      </div>
    </>
  );
}
