export const getCookie = (name: string): string | null => {
  const cookiesString = document.cookie.split('; ');
  console.log("cookiesString",cookiesString)
  const cookie = cookiesString.find(c => c.split('=')[0] === name);
  return cookie === undefined
    ? null
    : cookie
        .split('=')
        .splice(1)
        .join('=');
};
