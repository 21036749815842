import { Badge, Col, Container, Row } from 'react-bootstrap';

// images
import amazon from '../../../assets/images/brands/aws.png';

const Client = () => {
    return (
        <section className="py-5 mb-lg-6 position-relative" data-aos="fade-up">
            <Container>
                <Row>
                    <Col className="text-center">
                        {/* <Badge pill className="badge-soft-info px-2 py-1">
                            Investor
                        </Badge> */}
                        <h1 className="display-5 fw-medium clrwhite">We are backed by</h1>
                    </Col>
                </Row>
                <Row className="text-center">
                    <Col className='amazonImage text-center'>
                        <img src={amazon} alt="amazon" height="40" />
                    </Col>
                </Row>
            </Container>
        </section>
    );
};

export default Client;
